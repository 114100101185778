import jQuery from 'jquery';

let $ = jQuery;

function OnRun($rootScope, $http, $location, $window, $cookies, AppSettings) {
    'ngInject';

    $rootScope.urlPrefix = AppSettings.urlPrefix;
    $rootScope.urlAPIPrefix = AppSettings.urlAPIPrefix;
    $rootScope.urlAppServerPrefix = AppSettings.urlAppServerPrefix;
    $rootScope.urlLogin = AppSettings.urlLogin;
    $rootScope.sessionTimeout = 300; // 5 minutes

    // change page title based on state
    $rootScope.$on('$stateChangeSuccess', (event, toState) => {
        $("html, body").animate({ scrollTop: 0 }, 200);

        $rootScope.pageTitle = '';

        if (toState.title) {
            $rootScope.pageTitle += toState.title;
            $rootScope.pageTitle += ' \u2014 ';
        }

        $rootScope.pageTitle += AppSettings.appTitle;
    });

    $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams, options) => {
        // event.preventDefault();
        // transitionTo() promise will be rejected with
        // a 'transition prevented' error
        if (!toState.resolve) { toState.resolve = {} }
        toState.resolve.pauseStateChange = [
            '$q',
            function($q) {
                let defer = $q.defer();
                    $http({
                        method: 'GET',
                        url: $rootScope.urlAPIPrefix + '/session/sessioninfo',
                        withCredentials: true
                    }).then(function successCallback(response) {
                        if (response.status === 200) {
                            $rootScope.USER = response.data;
                            $cookies.put('c_token', response.data.TOKEN);
                            $rootScope.sessionTimeout = 300;
                            defer.resolve();
                        }
                        if (response.status === 401 ||
                            response.status === 403 ||
                            response.status === 500) {
                            $window.location.href = $rootScope.urlLogin;
                        }
                    }, function errorCallback(response) {
                        console.log(response.data.MESSAGE);
                        defer.reject();
                        if ($window.location.pathname !== '/login') {
                            console.log($window.location.pathname);
                            console.log($rootScope.urlLogin);
                            $window.location.href = $rootScope.urlLogin;
                        }
                    });
                return defer.promise;
            }
        ]
    });

    (function ($) {

        $(function () {

            function init() {
                $('#toggle-responsive-menu').click(function () {
                    if ($('header nav').is(':visible')) {
                        $('header nav').slideUp('fast');
                    }
                    else {
                        $('header nav').slideDown('fast');
                    }
                    $(this).find('span').toggleClass('on');

                    // Don't trigger default action
                    event.preventDefault();
                });

                /* Property search filters */
                var $filterMenu = $('#property-search .filter-menu');
                var $filterOptions = $('#property-search .filter-options');

                function showSearchFilters() {
                    $filterOptions.slideDown();
                    $filterMenu.addClass('on');
                }

                function hideSearchFilters() {
                    $filterOptions.slideUp();
                    $filterMenu.removeClass('on');
                }

                /* Show/hide filter options by clicking the filter menu */

                $filterMenu.off("click.prop-search").on('click.prop-search', 'a, h3', function (event) {
                    var $filterForm = $('#property-search .filter-options');
                    if ($filterOptions.is(':hidden')) {
                        showSearchFilters();
                    } else {
                        hideSearchFilters();
                    }
                    event.preventDefault();
                });

                /* Hide filter options by clicking away from filter area */

                $(document).click(function (event) {
                    if (!$(event.target).closest('#property-search .filter-options, #property-search .filter-menu').length) {
                        if ($filterOptions.is(':visible')) {
                            hideSearchFilters();
                        }
                    }
                });

                /* Hide filter options by clicking the close button in the filter area */

                $('#property-search .filter-options .close').click(function (event) {
                    hideSearchFilters();
                    event.preventDefault();
                });

                /* Add commas to number field */

                $('input.number').keyup(function (event) {
                    if (event.which >= 37 && event.which <= 40) {
                        event.preventDefault();
                    }
                    var $this = $(this);
                    var num = $this.val().replace(/(\s)/g, '');
                    $this.val(num.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 "));
                });


                // Propery spotlight slider

                var $spotlightList = $('.spotlight ul');

                if ($spotlightList.length && $spotlightList.children().length > 3) {
                    var slider = $spotlightList.bxSlider();
                    var spotlightDisplay = 'large'; // default value
                    var pagerFlag = false;

                    // Set display to small if "small" class is present.
                    if ($spotlightList.closest('.spotlight').hasClass('small')) {
                        spotlightDisplay = 'small';
                    }

                    var slideQty = slider.getSlideCount();
                    if (slideQty > 3) {
                        pagerFlag = true;
                    }

                    // Bind to the resize event of the window object
                    $(window).on('load resize', function () {
                        var windowWidth = $(window).width();
                        if (windowWidth > 740) {
                            if (spotlightDisplay == 'large') {
                                slider.reloadSlider({
                                    adaptiveHeight: true,
                                    pager: pagerFlag,
                                    minSlides: 3,
                                    maxSlides: 3,
                                    slideWidth: 270,
                                    slideMargin: 65,
                                    nextSelector: '.spotlight-next',
                                    prevSelector: '.spotlight-prev'
                                });
                            }
                            else {
                                slider.reloadSlider({
                                    adaptiveHeight: true,
                                    pager: pagerFlag,
                                    minSlides: 3,
                                    maxSlides: 3,
                                    slideWidth: 310,
                                    slideMargin: 25,
                                    nextSelector: '.spotlight-next',
                                    prevSelector: '.spotlight-prev'
                                });
                            }
                        } else {
                            slider.reloadSlider({
                                pager: pagerFlag,
                                minSlides: 3,
                                maxSlides: 3,
                                mode: 'vertical',
                                slideWidth: 280,
                                slideMargin: 10,
                                nextSelector: '.spotlight-next',
                                prevSelector: '.spotlight-prev'
                            });
                        }
                    });
                }


            }

            init();
            window.initRbiUi = init;
        });

        // Accordion nav

        $('.nav a[href*=\\#]').click(function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
        });

        // Mobile sidebar nav

        $('aside.left-rail nav h2').click(function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
            $(this).siblings('ul').slideToggle();
        });


        /*
         Assign classes dynamically
         */
        $('aside.left-rail').closest('main').addClass('has-left-rail');


        /* COLLSAPSIBLE CONTAINERS =================================================== */

        /* Assign "close" CSS class to menu levels hidden on load. */

        // Generic (show 2 levels)
        $('.generic.toc ul.collapse ul.collapse > li').addClass('close');

        // FAQs (show 1 level)
        $('.faqs.toc ul.collapse > li').addClass('close');

        // Left navigation  (show 2 levels)
        $('.left-rail nav ul.collapse > li').removeClass('close');
        $('.left-rail nav ul.collapse ul.collapse > li').addClass('close');

        // Landing page table of contents  (show 3 levels)
        $('article .tabbed.toc ul.collapse > li').removeClass('close');
        $('article .tabbed.toc ul.collapse ul.collapse ul.collapse > li').addClass('close');

        /* Assign "open" CSS class to menu levels shown on load. */
        $('ul.collapse:visible > li:not(.close)').addClass('open');

        /* Hide content levels that were assigned a close class. */
        $('ul.collapse > li.close > *:not(:first-child)').hide();

        /* Set show/hide triggers. */
        $('ul.collapse').on('click', '> li.on > a', function (e) {
            if ($(this).attr("href") == "#") {
                e.preventDefault();
            }
            $(this).parent().toggleClass('open close');
            if ($(this).parent().hasClass('open')) {
                $(this).siblings().slideDown();
                e.preventDefault(); //this will ALWAYS allow the slide down before navigating a link that has children.
            }
            else {
                $(this).siblings().slideUp();
            }
        });


        /* TABBED CONTENT =================================================== */

        // Highlight first tab
        if ($(".tabbed ul.tabs > li.active").length == 0) {
            $('.tabbed ul.tabs > li:first-child').addClass('active');
        }

        // Hide all but the first set of tab content
        $('.tabbed .tab-content > div:not(:first-child)').hide();

        $('.tabbed .tabs').on('click', 'a.tab', function (e) {
            e.preventDefault();
            // Get active index
            selectedIndex = $(this).closest('li').index();
            // Clear tab highlighting
            $(this).closest('.tabs').find('li').removeClass('active');
            // Highlight active tab
            $(this).closest('li').addClass('active');
            // Hide tab content
            $(this).closest('.tabbed').find('.tab-content > div').hide();
            // Show tab content of active index
            $(this).closest('.tabbed').find('.tab-content > div:nth-child(' + (selectedIndex + 1) + ')').show();
        });
        $(".sort-by select").change(function () {
            $(this).closest("form").submit();
        });
        $(".tabbed .tabs li.active a.tab").click();
    })(jQuery);

    /* ONCE WINDOW LOADS =================================================== */

    window.onload = function () {

        runScript();

        function runScript() {

            /* Script that does something and depends on jQuery being there. */
            if (window.jQuery) {
                /* Resolve jQuery confilct with Prototype.js */
                var $j = jQuery.noConflict();

                var url = window.location.href;

                /* Transform image buttons into text buttons.  */
                var $imageButtons = $j('#wrap img[src$="_button.png"]');

                if ($imageButtons.length > 0) {
                    $imageButtons.each(function () {
                        $j(this).parent('a').addClass('button js-element');
                        $j(this).after('<span>' + $j(this).attr("alt") + '</span>');
                        if ($j(this).parent('a').parent().is('p[align="center"]')) {
                            $j(this).closest('a').unwrap();
                        }
                        $j(this).remove();
                    });
                }

                /* Put tutorials into bob-floating columns.  */

                $j('.video-tutorials').wrapAll('<div class="video-tutorials-wrapper" />');
                var $videoTutorialWrappers = $j('.video-tutorials-wrapper');
                $videoTutorialWrappers.each(function () {
                    if ($j(this).find('.video-tutorials').length > 1) {
                        $j(this).addClass('multiple');
                    }
                });

                /* Supply new template for left navigation.  */

                var $leftNav = $j('#left-nav');

                if ($j('#wrap').length > 0) {
                    $j('body').addClass('generic legacy');
                }

                if (($leftNav.length > 0) && ($j('#wrap').length > 0)) {
                    $j('#wrap').wrap('<main class="has-left-rail" />').wrap('<article />');
                    $leftNav.prependTo('main');
                    $leftNav.wrap('<aside class="left-rail" />').wrap('<nav role="navigation" aria-label="Secondary" />');
                }

                /* Remove div wrapper from #agent-profile-internal .alert.alert-success and move alert to top of content area. */

                $j('article #wrap .alert.alert-success').unwrap().prependTo('#wrap');

                /* Move .edit element into next sibling, which should be a header. */

                $j('#agent-profile-internal .edit, #agent-profile .edit').each(function () {
                    $j(this).appendTo($j(this).next());
                });

            } else {
                // wait 50 milliseconds and try again.
                window.setTimeout(runScript, 50);
            }
        } // end function
    };
}

export default OnRun;
