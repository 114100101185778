function PostAuctionClientReviewController($scope, $interpolate, $resource, $state, $stateParams, ColumnDefinitionService, NgTableParams, ItemidForLoanNumService) {
    'ngInject'

    $('main').css({transition: 'max-width 1s ease-in-out'})
    $('main').css({'max-width': '100%'})

    const ctrl = this;
    const uri = '/clientportal/api/';

    let parameter = $stateParams.parameter;
    ctrl.parameter = parameter;
    if (moment(parameter, moment.ISO_8601, true).isValid()){
        ctrl.titleparam = moment(parameter, moment.ISO_8601, true).format('MM-DD-YYYY');
    }else{
        ctrl.titleparam = ctrl.parameter;
    }
    if(ctrl.titleparam === '__all__'){
        ctrl.titleparam = 'All';
    }

    ctrl.data = [];
    ctrl.showfullerror = false;
    ctrl.showerror = false;
    ctrl.showsuccess = false;
    $scope.names = ["Accept", "Reject", "Counter","None"];

    let res = $resource(uri + 'RPT_PostAuctionClientReviewDrillThrough/:id/?format=json&nopage=1', {id:'@id'}, {
        'update': { method:'PATCH' }
    });
   // let res = $resource(uri + 'RPT_PendingRemovalConfirmationDrillThrough/?format=json&nopage=1', {id:'@id','AgentId':$stateParams.AgentId}, {
   //      'update': { method:'PATCH' }
   //  });

    /*let res = $resource(uri + 'RPT_PendingRemovalConfirmationDrillThrough/:id/?format=json&nopage=1', {
        'update': { method:'PATCH' }
    });
*/

    ctrl.tableParams = new NgTableParams({
        count: 15
    }, {
        filterDelay: 0
    });


    res.query({id: parameter}).$promise.then(data => {
        Load_Data(data);
    });


    ctrl.save = function save(row) {
        ctrl.showerror = false;
        ctrl.showsuccess = false;

        row.id = row.HighBidID;
        //console.log("row.id", row.id)
        row.$update(function(data){
            ctrl.showsuccess = true;
            window.location.reload(true);
            res.query({id: row.id}).$promise.then(data => {
                Load_Data(data);
            });
        }, function(err){
            ctrl.showerror = true;
            if("data" in err && "detail" in err["data"]){
                ctrl.error = err["data"]["detail"];
            }else{
                ctrl.error = err["statusText"];
            }

            ctrl.fullError = err;
        });

        //Save/patch the data
        //On successful return
        //  reload data
        //On fail
        //  notify user

        // alert("Work In Progress");
    };

    //-------------------------------------
    function Load_Data(data) {
        ItemidForLoanNumService.get(data).then((data) => {

            let dates = [
                'PostAuctionEndDate'
            ];

            angular.forEach(dates, function(name){
                angular.forEach(data, function(row){
                    let asset_update = $resource(uri + 'GetAssetHistory/:id/', {id: row.ITEM_ID}, {'update': { method:'GET' }});
                    asset_update.query({id: row.ITEM_ID}).$promise.then(data => {
                        try{
                            if(row[name] === null) return;
                            let arr = row[name].split('-');
                            let date = new Date();
                            date.setYear(parseInt(arr[0]));
                            date.setMonth(parseInt(arr[1]-1)); //Because JS has zero based months :/
                            date.setDate(parseInt(arr[2]));
                            // let parsed = Date.parse(data[name]);
                            // data[name] = new Date(parsed);
                            row[name] = date;
                        }catch(e){
                            console.log(e)
                        }
                    });
                })
            });

            ctrl.data = data;
            ctrl.tableParams.settings({
                dataset: ctrl.data
            });

            ctrl.data_loaded = true;

            ctrl.data['show_otherTextBox']=false;

            angular.forEach(ctrl.data, (data) => {
                angular.forEach(data, (value, key) => {
                    var dt=new Date(value);
                    if ( (value !==null) && dt !== "Invalid Date" && !isNaN(dt) ) {
                        var isZDate = value.toString().indexOf("00Z") >=0;

                        if (isZDate) {
                            var dtn = new Date(value);
                            data[key] = new Date(dtn.getFullYear(), dtn.getMonth(),dtn.getDate());
                        }
                    }
                })
            });
        });
    }

    ctrl.propertyDetailsCB = function(row){
        console.log(row);
        $state.go('PropertyDetails', {'id':row['id']});
    };
}

export default {
    name: 'PostAuctionClientReviewController',
    fn: PostAuctionClientReviewController
};
