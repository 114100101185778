function TooltipDirective() {
    'ngInject';

    return {
        restrict: 'A',
        link: function(scope, element, attrs){
            angular.element(element).hover(function(){
                // on mouseenter
                angular.element(element).tooltip('show');
            }, function(){
                // on mouseleave
                angular.element(element).tooltip('hide');
            });
        }
    };
}

export default {
    name: 'tooltip',
    fn: TooltipDirective
};
