function FormatDateFilter() {

    return function(date) {
        var dt=new Date(date);
        console.log("Date is");
        console.log(date);
        if (date !==null && dt !== "Invalid Date" && !isNaN(dt) ) {
            return (dt.getMonth()+1) + "/" + dt.getDate() + "/" + dt.getFullYear();
        }
        return "";
    };

}

export default {
    name: 'formatDate',
    fn: FormatDateFilter
};



