//Column definitions for drillthroughs
function ColumnDefinitionService($http, $interpolate, $rootScope) {
    'ngInject';

    const service = {
        'RPT_ReviewForHUDExtension': [
            {
                field: "AssetNumber",
                title: "LoanNumber",
                filter: {LoanNumber: "text"},
                sortable: "LoanNumber"
            },
            {
                field: "ScheduledSaleDate",
                title: "Scheduled Sale Date",
                filter: {ScheduledSaleDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ScheduledSaleDate"
            },
            {
                field: "deedrecordeddate",
                title: "Deed Recorded Date",
                filter: {deedrecordeddate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "deedrecordeddate"
            },
            {
                field: "Days Since",
                title: "Days Since",
                filter: {'Days Since': "text"},
                sortable: "'Days Since'"
            },
            {
                field: "Address",
                title: "Address",
                filter: {Address: "text"},
                sortable: "Address"
            },
            {
                field: "City",
                title: "City",
                filter: {City: "text"},
                sortable: "City"
            },
            {
                field: "State",
                title: "State",
                filter: {State: "text"},
                sortable: "State"
            },
            {
                field: "Zip",
                title: "Zip",
                filter: {Zip: "text"},
                sortable: "Zip"
            },
            {
                field: "CWCOTHoldStatus",
                title: "HoldStatus",
                filter: {CWCOTHoldStatus: "text"},
                sortable: "CWCOTHoldStatus"
            },
            {
                field: "AuctionType",
                title: "AuctionType",
                filter: {AuctionType: "text"},
                sortable: "AuctionType"
            },
            {
                field: "ConveyanceExtensionRequested",
                title: "Conveyance Extension Requested Date",
                filter: {ConveyanceExtensionRequested: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ConveyanceExtensionRequested"
            },
            {
                field: "ConveyanceExtensionApproved",
                title: "Conveyance Extension Approved Date",
                filter: {ConveyanceExtensionApproved: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ConveyanceExtensionApproved"
            },
            {
                field: "ConveyanceExtensionDenied",
                title: "Conveyance Extension Denied Date",
                filter: {ConveyanceExtensionDenied: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ConveyanceExtensionDenied"
            }
        ],
        'RPT_OfferReceivedNoClosingSet': [
            {
                field: "LoanNumber",
                title: "LoanNumber",
                filter: {LoanNumber: "text"},
                sortable: "LoanNumber"
            },
            {
                field: "deedrecordeddate",
                title: "Deed Recorded Date",
                filter: {deedrecordeddate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "deedrecordeddate"
            },
            {
                field: "DaysSince",
                title: "Days Since",
                filter: {'DaysSince': "text"},
                sortable: "DaysSince"
            },
            {
                field: "Address",
                title: "Address",
                filter: {Address: "text"},
                sortable: "Address"
            },
            {
                field: "City",
                title: "City",
                filter: {City: "text"},
                sortable: "City"
            },
            {
                field: "State",
                title: "State",
                filter: {State: "text"},
                sortable: "State"
            },
            {
                field: "Zip",
                title: "Zip",
                filter: {Zip: "text"},
                sortable: "Zip"
            },
            {
                field: "CWCOTHoldStatus",
                title: "HoldStatus",
                filter: {CWCOTHoldStatus: "text"},
                sortable: "CWCOTHoldStatus"
            },
            {
                field: "AuctionType",
                title: "AuctionType",
                filter: {AuctionType: "text"},
                sortable: "AuctionType"
            },
            {
                field: "ScheduledSaleDate",
                title: "Scheduled Sale Date",
                filter: {ScheduledSaleDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ScheduledSaleDate"
            },
            {
                field: "Auction Status",
                title: "Auction Status",
                filter: {'Auction Status': "text"},
                sortable: "'Auction Status'"
            },
            {
                field: "SoldOnlineDate",
                title: "Sold Online Date",
                filter: {SoldOnlineDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "SoldOnlineDate"
            },
            {
                field: "ScheduledClosingDate",
                title: "Scheduled Closing Date",
                filter: {ScheduledClosingDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ScheduledClosingDate"
            }
        ],
        'RPT_ProvidedReserveNotMatchingCalculatedDrillThrough': [
            {
                field: "CompanyName",
                title: "Seller",
                filter: {CompanyName: "text"},
                sortable: "CompanyName"
            },
            {
                field: "AssetNumber",
                title: "AssetNumber",
                filter: {AssetNumber: "text"},
                sortable: "AssetNumber"
            },
            {
                field: "ItemID",
                title: "ItemID",
                filter: {ItemID: "text"},
                sortable: "ItemID"
            },
            {
                field: "Address",
                title: "Address",
                filter: {Address: "text"},
                sortable: "Address"
            },
            {
                field: "City",
                title: "City",
                filter: {City: "text"},
                sortable: "City"
            },
            {
                field: "State",
                title: "State",
                filter: {State: "text"},
                sortable: "State"
            },
            {
                field: "Zip",
                title: "Zip",
                filter: {Zip: "text"},
                sortable: "Zip"
            },
             {
                field: "ProgramType",
                title: "ProgramType",
                filter: {PropertyStatus: "text"},
                sortable: "ProgramType"
            },
            {
                field: "CWCOTHoldStatus",
                title: "HoldStatus",
                filter: {CWCOTHoldStatus: "text"},
                sortable: "CWCOTHoldStatus"
            },
            {
                field: "AuctionType",
                title: "AuctionType",
                filter: {AuctionType: "text"},
                sortable: "AuctionType"
            },
            {
                field: "FCLSaleDate",
                title: "FCLSaleDate",
                filter: {FCLSaleDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "FCLSaleDate"
            },
            {
                field: "OpeningBidAmount",
                title: "OpeningBidAmount",
                filter: {'OpeningBidAmount': "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "OpeningBidAmount"
            },
            {
                field: "CalculatedReserve",
                title: "CalculatedReserve",
                filter: {'CalculatedReserve': "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "CalculatedReserve"
            },
            {
                field: "RBIContact",
                title: "RBI Contact",
                getValue: 'emailLinkValue',
                filter: {'RBIContact': "text"},
                sortable: "RBIContact"
            }
        ],
        'RPT_DeedRecorededException': [
            {
                field: "AssetNumber",
                title: "LoanNumber",
                filter: {LoanNumber: "text"},
                sortable: "LoanNumber"
            },
            {
                field: "DeedRecordedDate",
                title: "Deed Recorded Date",
                filter: {DeedRecordedDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "DeedRecordedDate"
            },
            {
                field: "status",
                title: "Status",
                filter: {status: "text"},
                sortable: "status"
            },
            {
                field: "Address",
                title: "Address",
                filter: {Address: "text"},
                sortable: "Address"
            },
            {
                field: "City",
                title: "City",
                filter: {City: "text"},
                sortable: "City"
            },
            {
                field: "State",
                title: "State",
                filter: {State: "text"},
                sortable: "State"
            },
            {
                field: "Zip",
                title: "Zip",
                filter: {Zip: "text"},
                sortable: "Zip"
            },
            {
                field: "CWCOTHoldStatus",
                title: "HoldStatus",
                filter: {CWCOTHoldStatus: "text"},
                sortable: "CWCOTHoldStatus"
            },
            {
                field: "AuctionType",
                title: "AuctionType",
                filter: {AuctionType: "text"},
                sortable: "AuctionType"
            }
        ],
        'RPT_MissingReserveOrValuationDrillThrough': [
            {
                field: "CompanyName",
                title: "Seller",
                filter: {CompanyName: "text"},
                sortable: "CompanyName"
            },
            {
                field: "AssetNumber",
                title: "AssetNumber",
                filter: {AssetNumber: "text"},
                sortable: "AssetNumber"
            },
            {
                field: "ItemID",
                title: "ItemID",
                filter: {ItemID: "text"},
                sortable: "ItemID"
            },
             {
                field: "Address",
                title: "Address",
                filter: {Address: "text"},
                sortable: "Address"
            },
            {
                field: "City",
                title: "City",
                filter: {City: "text"},
                sortable: "City"
            },
            {
                field: "State",
                title: "State",
                filter: {State: "text"},
                sortable: "State"
            },
            {
                field: "Zip",
                title: "Zip",
                filter: {Zip: "text"},
                sortable: "Zip"
            },
            {
                field: "Reserve",
                title: "Reserve",
                filter: {'Reserve': "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "Reserve"
            },
            {
                field: "Value",
                title: "OpinionOfValue",
                filter: {'Value': "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "Value"
            },
            {
                field: "FCLSaleDate",
                title: "FCLSaleDate",
                filter: {FCLSaleDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "FCLSaleDate"
            },
            {
                field: "PropertyStatus",
                title: "PropertyStatus",
                filter: {PropertyStatus: "text"},
                sortable: "PropertyStatus"
            },
             {
                field: "CWCOTHoldStatus",
                title: "HoldStatus",
                filter: {CWCOTHoldStatus: "text"},
                sortable: "CWCOTHoldStatus"
            },
            {
                field: "ProgramType",
                title: "ProgramType",
                filter: {ProgramType: "text"},
                sortable: "ProgramType"
            },
            {
                field: "SoldDate",
                title: "SoldDate",
                filter: {SoldDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "SoldDate"
            },
            {
                field: "RunNumber",
                title: "Cycle",
                filter: {RunNumber: "text"},
                sortable: "RunNumber"
            },
            {
                field: "RemovalWithdrawalReason",
                title: "RemovalReason",
                filter: {RemovalWithdrawalReason: "text"},
                sortable: "RemovalWithdrawalReason"
            },
            {
                field: "RBIContact",
                title: "RBI Contact",
                getValue: 'emailLinkValue',
                filter: {'RBIContact': "text"},
                sortable: "RBIContact"
            }
        ],
        'RPT_ForeclosureStatusDrillThrough': [
            {
                field: "CompanyName",
                title: "Seller",
                filter: {CompanyName: "text"},
                sortable: "CompanyName"
            },
            {
                field: "AssetNumber",
                title: "LoanNumber",
                filter: {LoanNumber: "text"},
                sortable: "LoanNumber"
            },
            {
                field: "Address",
                title: "Address",
                filter: {Address: "text"},
                sortable: "Address"
            },
            {
                field: "City",
                title: "City",
                filter: {City: "text"},
                sortable: "City"
            },
            {
                field: "State",
                title: "State",
                filter: {State: "text"},
                sortable: "State"
            },
            {
                field: "Zip",
                title: "Zip",
                filter: {Zip: "text"},
                sortable: "Zip"
            },
            {
                field: "ScheduledSaleDate",
                title: "Scheduled Sale Date",
                filter: {ScheduledSaleDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ScheduledSaleDate"
            },
            {
                field: "Auction Status",
                title: "Auction Status",
                filter: {'Auction Status': "text"},
                sortable: "'Auction Status'"
            },
             {
                field: "CWCOTHoldStatus",
                title: "HoldStatus",
                filter: {CWCOTHoldStatus: "text"},
                sortable: "CWCOTHoldStatus"
            },
            {
                field: "AuctionType",
                title: "AuctionType",
                filter: {AuctionType: "text"},
                sortable: "AuctionType"
            },
            {
                field: "ScheduledClosingDate",
                title: "Scheduled Closing Date",
                filter: {ScheduledClosingDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ScheduledClosingDate"
            },
            {
                field: "Reserve",
                title: "Reserve",
                filter: {Reserve: "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "Reserve"
            },
            {
                field: "PurchasePrice",
                title: "Purchase Price",
                filter: {PurchasePrice: "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "PurchasePrice"
            },
            {
                field: "ContractPrice",
                title: "Contract Price",
                filter: {ContractPrice: "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "ContractPrice"
            },
            {
                field: "DeedRecordedDate",
                title: "Deed Recorded Date",
                filter: {DeedRecordedDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "DeedRecordedDate"
            },
            {
                field: "NextChanceStartDate",
                title: "2nd Chance Start Date",
                filter: {NextChanceStartDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "NextChanceStartDate"
            },
            {
                field: "NextChanceEndDate",
                title: "2nd Chance End Date",
                filter: {NextChanceEndDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "NextChanceEndDate"
            },
            {
                field: "NextChanceCycleCount",
                title: "2nd Chance Cycle Count",
                filter: {'NextChanceCycleCount': "text"},
                sortable: "NextChanceCycleCount"
            },
            {
                field: "SoldOnlineDate",
                title: "Sold Online Date",
                filter: {SoldOnlineDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "SoldOnlineDate"
            },
            {
                field: "ContractClosingDate",
                title: "Contract Closing Date",
                filter: {ContractClosingDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ContractClosingDate"
            },
            {
                field: "ContractToTC",
                title: "Contract To TC",
                filter: {ContractToTC: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ContractToTC"
            },
            {
                field: "DeedApprovedBySeller",
                title: "Deed Approved",
                filter: {DeedApprovedBySeller: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "DeedApprovedBySeller"
            },
            {
                field: "HUDReviewed",
                title: "Hud Reviewed",
                filter: {HUDReviewed: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "HUDReviewed"
            },
            {
                field: "HUDApprovedBySeller",
                title: "Hud Approved",
                filter: {HUDApprovedBySeller: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "HUDApprovedBySeller"
            },
            {
                field: "FundedDate",
                title: "Funded Date",
                filter: {FundedDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "FundedDate"
            },
            {
                field: "SellerWiredDate",
                title: "Seller Wired Date",
                filter: {SellerWiredDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "SellerWiredDate"
            }
        ],
        'RPT_PostForeclosureStatusDrillThrough': [
            {
                field: "CompanyName",
                title: "Seller",
                filter: {CompanyName: "text"},
                sortable: "CompanyName"
            },
            {
                field: "AssetNumber",
                title: "AssetNumber",
                filter: {AssetNumber: "text"},
                sortable: "AssetNumber"
            },
            {
                field: "ItemID",
                title: "ItemID",
                filter: {ItemID: "text"},
                sortable: "ItemID"
            },
             {
                field: "Address",
                title: "Address",
                filter: {Address: "text"},
                sortable: "Address"
            },
            {
                field: "City",
                title: "City",
                filter: {City: "text"},
                sortable: "City"
            },
            {
                field: "State",
                title: "State",
                filter: {State: "text"},
                sortable: "State"
            },
            {
                field: "Zip",
                title: "Zip",
                filter: {Zip: "text"},
                sortable: "Zip"
            },
            {
                field: "ReservePriceAmt",
                title: "Reserve",
                filter: {'ReservePriceAmt': "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "ReservePriceAmt"
            },
            {
                field: "ContractPrice",
                title: "ContractPrice",
                filter: {'ContractPrice': "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "ContractPrice"
            },
            {
                field: "PropertyStatus",
                title: "PropertyStatus",
                filter: {PropertyStatus: "text"},
                sortable: "PropertyStatus"
            },
            {
                field: "ClosingStatus",
                title: "ClosingStatus",
                filter: {ClosingStatus: "text"},
                sortable: "ClosingStatus"
            },
             {
                field: "CWCOTHoldStatus",
                title: "HoldStatus",
                filter: {CWCOTHoldStatus: "text"},
                sortable: "CWCOTHoldStatus"
            },
            {
                field: "AuctionType",
                title: "AuctionType",
                filter: {AuctionType: "text"},
                sortable: "AuctionType"
            },
            {
                field: "SoldStatus",
                title: "SoldStatus",
                filter: {SoldStatus: "text"},
                sortable: "SoldStatus"
            },
             {
                field: "FCLSaleDate",
                title: "FCLSaleDate",
                filter: {FCLSaleDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "FCLSaleDate"
            },
            {
                field: "SoldDate",
                title: "SoldDate",
                filter: {SoldDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "SoldDate"
            },
            {
                field: "RunNumber",
                title: "Cycle",
                filter: {RunNumber: "text"},
                sortable: "RunNumber"
            },
            {
                field: "RemovalWithdrawalReason",
                title: "RemovalReason",
                filter: {RemovalWithdrawalReason: "text"},
                sortable: "RemovalWithdrawalReason"
            },
            {
                field: "ContactOnRBI",
                title: "RBI Contact",
                getValue: 'emailLinkValue',
                filter: {'ContactOnRBI': "text"},
                sortable: "ContactOnRBI"
            }
        ],
        'RPT_ActiveOnlineAuctionDrillThrough': [
            {
                field: "CompanyName",
                title: "Seller",
                filter: {CompanyName: "text"},
                sortable: "CompanyName"
            },
            {
                field: "AssetNumber",
                title: "LoanNumber",
                filter: {Loan_Number: "text"},
                sortable: "Loan_Number"
            },
            {
                field: "AT_Status",
                title: "Auction Status",
                filter: {'AT_Status': "text"},
                sortable: "AT_Status"
            },
            {
                field: "AT_AuctionType",
                title: "Auction Type",
                filter: {'AT_AuctionType': "text"},
                sortable: "AT_AuctionType"
            },
            {
                field: "XLR8_Status",
                title: "XLR8 Status",
                filter: {'XLR8_Status': "text"},
                sortable: "XLR8_Status"
            },
            {
                field: "AT_ScheduledSaleDate",
                title: "Scheduled Sale Date",
                filter: {AT_ScheduledSaleDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "AT_ScheduledSaleDate"
            },
            {
                field: "AUCTION_END_DATE",
                title: "Auction End Date",
                filter: {AUCTION_END_DATE: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "AUCTION_END_DATE"
            },
            {
                field: "CycleCount",
                title: "Cycle Count",
                filter: {CycleCount: "text"},
                sortable: "CycleCount"
            },
            {
                field: "ADDRESS1",
                title: "Address",
                filter: {ADDRESS1: "text"},
                sortable: "ADDRESS1"
            },
            {
                field: "City",
                title: "City",
                filter: {City: "text"},
                sortable: "City"
            },
            {
                field: "STATE",
                title: "State",
                filter: {STATE: "text"},
                sortable: "STATE"
            },
            {
                field: "POSTAL_CODE",
                title: "Zip",
                filter: {POSTAL_CODE: "text"},
                sortable: "POSTAL_CODE"
            },
            {
                field: "CWCOTHoldStatus",
                title: "HoldStatus",
                filter: {CWCOTHoldStatus: "text"},
                sortable: "CWCOTHoldStatus"
            },
            {
                field: "AuctionType",
                title: "AuctionType",
                filter: {AuctionType: "text"},
                sortable: "AuctionType"
            }
        ],
        'RPT_PropertyIssuesDrillThrough': [
            {
                field: "CompanyName",
                title: "Seller",
                filter: {CompanyName: "text"},
                sortable: "CompanyName"
            },
            {
                field: "AssetNumber",
                title: "AssetNumber",
                filter: {AssetNumber: "text"},
                sortable: "AssetNumber"
            },
            {
                field: "ItemID",
                title: "ItemID",
                filter: {ItemID: "text"},
                sortable: "ItemID"
            },
             {
                field: "Address",
                title: "Address",
                filter: {Address: "text"},
                sortable: "Address"
            },
            {
                field: "City",
                title: "City",
                filter: {City: "text"},
                sortable: "City"
            },
            {
                field: "State",
                title: "State",
                filter: {State: "text"},
                sortable: "State"
            },
            {
                field: "Zip",
                title: "Zip",
                filter: {Zip: "text"},
                sortable: "Zip"
            },
            {
                field: "ReservePriceAmt",
                title: "Reserve",
                filter: {'ReservePriceAmt': "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "ReservePriceAmt"
            },
            {
                field: "ContractPrice",
                title: "ContractPrice",
                filter: {'ContractPrice': "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "ContractPrice"
            },
            {
                field: "RemovalWithdrawalReason",
                title: "IssueDescription",
                filter: {RemovalWithdrawalReason: "text"},
                sortable: "RemovalWithdrawalReason"
            },
            {
                field: "PropertyStatus",
                title: "PropertyStatus",
                filter: {PropertyStatus: "text"},
                sortable: "PropertyStatus"
            },
            {
                field: "ClosingStatus",
                title: "ClosingStatus",
                filter: {ClosingStatus: "text"},
                sortable: "ClosingStatus"
            },
            {
                field: "CWCOTHoldStatus",
                title: "HoldStatus",
                filter: {CWCOTHoldStatus: "text"},
                sortable: "CWCOTHoldStatus"
            },
            {
                field: "AuctionType",
                title: "AuctionType",
                filter: {AuctionType: "text"},
                sortable: "AuctionType"
            },
             {
                field: "FCLSaleDate",
                title: "FCLSaleDate",
                filter: {FCLSaleDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "FCLSaleDate"
            },
            {
                field: "SoldDate",
                title: "SoldDate",
                filter: {SoldDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "SoldDate"
            },
            {
                field: "DueTime",
                title: "DueTime",
                filter: {DueTime: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "DueTime"
            },
            {
                field: "RunNumber",
                title: "Cycle",
                filter: {RunNumber: "text"},
                sortable: "RunNumber"
            },
            {
                field: "ContactOnRBI",
                title: "RBI Contact",
                getValue: 'emailLinkValue',
                filter: {'ContactOnRBI': "text"},
                sortable: "ContactOnRBI"
            }
        ],
        'RPT_ClosingDatePostponementDrillThrough': [
            {
                field: "CompanyName",
                title: "Seller",
                filter: {CompanyName: "text"},
                sortable: "CompanyName"
            },
            {
                field: "AssetNumber",
                title: "AssetNumber",
                filter: {AssetNumber: "text"},
                sortable: "AssetNumber"
            },
            {
                field: "Address",
                title: "Address",
                filter: {Address: "text"},
                sortable: "Address"
            },
            {
                field: "City",
                title: "City",
                filter: {City: "text"},
                sortable: "City"
            },
            {
                field: "State",
                title: "State",
                filter: {State: "text"},
                sortable: "State"
            },
            {
                field: "Zip",
                title: "Zip",
                filter: {Zip: "text"},
                sortable: "Zip"
            },
             {
                field: "CWCOTHoldStatus",
                title: "HoldStatus",
                filter: {CWCOTHoldStatus: "text"},
                sortable: "CWCOTHoldStatus"
            },
            {
                field: "AuctionType",
                title: "AuctionType",
                filter: {AuctionType: "text"},
                sortable: "AuctionType"
            },
            {
                field: "ScheduledSaleDate",
                title: "Scheduled Sale Date",
                filter: {ScheduledSaleDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ScheduledSaleDate"
            },
            {
                field: "OverallStatus",
                title: "OverallStatus",
                filter: {'OverallStatus': "text"},
                sortable: "'OverallStatus'"
            },
            {
                field: "ScheduledClosingDate",
                title: "Scheduled Closing Date",
                filter: {ScheduledClosingDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ScheduledClosingDate"
            },
            {
                field: "ClosingStatus",
                title: "ClosingStatus",
                filter: {'ClosingStatus': "text"},
                sortable: "'ClosingStatus'"
            },
            {
                field: "Reserve",
                title: "Reserve",
                filter: {Reserve: "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "Reserve"
            },
            {
                field: "PurchasePrice",
                title: "Purchase Price",
                filter: {PurchasePrice: "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "PurchasePrice"
            },
            {
                field: "ContractPrice",
                title: "Contract Price",
                filter: {ContractPrice: "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "ContractPrice"
            }
        ],
        'RPT_TitleIssuesInClosingDrillThrough': [
            {
                field: "CompanyName",
                title: "Seller",
                filter: {CompanyName: "text"},
                sortable: "CompanyName"
            },
             {
                field: "AssetNumber",
                title: "AssetNumber",
                filter: {AssetNumber: "text"},
                sortable: "AssetNumber"
            },
            {
                field: "ItemID",
                title: "ItemID",
                filter: {ItemID: "text"},
                sortable: "ItemID"
            },
             {
                field: "Address",
                title: "Address",
                filter: {Address: "text"},
                sortable: "Address"
            },
            {
                field: "City",
                title: "City",
                filter: {City: "text"},
                sortable: "City"
            },
            {
                field: "State",
                title: "State",
                filter: {State: "text"},
                sortable: "State"
            },
            {
                field: "Zip",
                title: "Zip",
                filter: {Zip: "text"},
                sortable: "Zip"
            },
            {
                field: "ReservePriceAmt",
                title: "Reserve",
                filter: {'ReservePriceAmt': "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "ReservePriceAmt"
            },
            {
                field: "ContractPrice",
                title: "ContractPrice",
                filter: {'ContractPrice': "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "ContractPrice"
            },
            {
                field: "PropertyStatus",
                title: "PropertyStatus",
                filter: {PropertyStatus: "text"},
                sortable: "PropertyStatus"
            },
             {
                field: "IssueDescription",
                title: "IssueDescription",
                filter: {IssueDescription: "text"},
                sortable: "IssueDescription"
            },
            {
                field: "ClosingStatus",
                title: "ClosingStatus",
                filter: {ClosingStatus: "text"},
                sortable: "ClosingStatus"
            },
             {
                field: "CWCOTHoldStatus",
                title: "HoldStatus",
                filter: {CWCOTHoldStatus: "text"},
                sortable: "CWCOTHoldStatus"
            },
            {
                field: "AuctionType",
                title: "AuctionType",
                filter: {AuctionType: "text"},
                sortable: "AuctionType"
            },
             {
                field: "FCLSaleDate",
                title: "FCLSaleDate",
                filter: {FCLSaleDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "FCLSaleDate"
            },
            {
                field: "SoldDate",
                title: "SoldDate",
                filter: {SoldDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "SoldDate"
            },
            {
                field: "DemandReceivedDate",
                title: "DemandReceivedDate",
                filter: {DemandReceivedDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "DemandReceivedDate"
            },
            {
                field: "RunNumber",
                title: "Cycle",
                filter: {RunNumber: "text"},
                sortable: "RunNumber"
            },
            {
                field: "RBIContact",
                title: "RBI Contact",
                getValue: 'emailLinkValue',
                filter: {'RBIContact': "text"},
                sortable: "RBIContact"
            }
        ],
        'RPT_TitleIssuesInClosing': [
            {
                field: "LoanNumber",
                title: "LoanNumber",
                filter: {LoanNumber: "text"},
                sortable: "LoanNumber"
            },
            {
                field: "Address1",
                title: "Address",
                filter: {Address1: "text"},
                sortable: "Address1"
            },
            {
                field: "City",
                title: "City",
                filter: {City: "text"},
                sortable: "City"
            },
            {
                field: "State",
                title: "State",
                filter: {State: "text"},
                sortable: "State"
            },
            {
                field: "Zip",
                title: "Zip",
                filter: {Zip: "text"},
                sortable: "Zip"
            },
            {
                field: "DemandReceivedDate",
                title: "Issue received date",
                filter: {DemandReceivedDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "DemandReceivedDate"
            },
            {
                field: "TitleIssue",
                title: "Title Issue",
                filter: {TitleIssue: "text"},
                sortable: "TitleIssue"
            },
            {
                field: "ScheduledClosingDate",
                title: "Scheduled Closing Date",
                filter: {ScheduledClosingDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ScheduledClosingDate"
            },
            {
                field: "Reserve",
                title: "Reserve",
                filter: {Reserve: "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "Reserve"
            },
             {
                field: "CWCOTHoldStatus",
                title: "HoldStatus",
                filter: {CWCOTHoldStatus: "text"},
                sortable: "CWCOTHoldStatus"
            },
            {
                field: "AuctionType",
                title: "AuctionType",
                filter: {AuctionType: "text"},
                sortable: "AuctionType"
            },
            {
                field: "ContractPrice",
                title: "Contract Price",
                filter: {ContractPrice: "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "ContractPrice"
            },
            {
                field: "DeedRecordedDate",
                title: "Deed Recorded Date",
                filter: {DeedRecordedDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "DeedRecordedDate"
            },
            {
                field: "SoldOnlineDate",
                title: "Sold Online Date",
                filter: {SoldOnlineDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "SoldOnlineDate"
            },
            {
                field: "ContractClosingDate",
                title: "Contract Closing Date",
                filter: {ContractClosingDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ContractClosingDate"
            },
            {
                field: "ContractToTC",
                title: "Contract To TC",
                filter: {ContractToTC: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ContractToTC"
            },
            {
                field: "DeedApprovedBySeller",
                title: "Deed Approved",
                filter: {DeedApprovedBySeller: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "DeedApprovedBySeller"
            },
            {
                field: "HUDReviewed",
                title: "Hud Reviewed",
                filter: {HUDReviewed: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "HUDReviewed"
            },
            {
                field: "SellerWiredDate",
                title: "Seller Wired Date",
                filter: {SellerWiredDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "SellerWiredDate"
            },
            {
                field: "CurrentEmployee",
                title: "RBI Contact",
                getValue: 'emailLinkValue',
                filter: {CurrentEmployee: "text"},
                sortable: "CurrentEmployee"
            }
        ],
        'RPT_PendingSeller': [
            {
                field: "CompanyName",
                title: "Seller",
                filter: {CompanyName: "text"},
                sortable: "CompanyName"
            },
            {
                field: "LoanNumber",
                title: "LoanNumber",
                filter: {LoanNumber: "text"},
                sortable: "LoanNumber"
            },
            {
                field: "DueTime",
                title: "Due Time",
                filter: {DueTime: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "DueTime"
            },
            {
                field: "ADDRESS1",
                title: "Address",
                filter: {ADDRESS1: "text"},
                sortable: "ADDRESS1"
            },
            {
                field: "CITY",
                title: "City",
                filter: {CITY: "text"},
                sortable: "CITY"
            },
            {
                field: "STATE",
                title: "State",
                filter: {STATE: "text"},
                sortable: "STATE"
            },
            {
                field: "zipcode",
                title: "Zip",
                filter: {zipcode: "text"},
                sortable: "zipcode"
            },
             {
                field: "CWCOTHoldStatus",
                title: "HoldStatus",
                filter: {CWCOTHoldStatus: "text"},
                sortable: "CWCOTHoldStatus"
            },
            {
                field: "AuctionType",
                title: "AuctionType",
                filter: {AuctionType: "text"},
                sortable: "AuctionType"
            },
            {
                field: "PROPERTY_ISSUE_OTHER",
                title: "Property Issue",
                filter: {PROPERTY_ISSUE_OTHER: "text"},
                sortable: "PROPERTY_ISSUE_OTHER"
            },
            {
                field: "ScheduledSaleDate",
                title: "Scheduled Sale Date",
                filter: {ScheduledSaleDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ScheduledSaleDate"
            },
            {
                field: "ScheduledClosingDate",
                title: "Scheduled Closing Date",
                filter: {ScheduledClosingDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ScheduledClosingDate"
            },
            {
                field: "Reserve",
                title: "Reserve",
                filter: {Reserve: "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "Reserve"
            },
            {
                field: "ContractPrice",
                title: "Contract Price",
                filter: {ContractPrice: "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "ContractPrice"
            },
            {
                field: "DeedRecordedDate",
                title: "Deed Recorded Date",
                filter: {DeedRecordedDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "DeedRecordedDate"
            },
            {
                field: "SoldOnlineDate",
                title: "Sold Online Date",
                filter: {SoldOnlineDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "SoldOnlineDate"
            },
            {
                field: "ContractClosingDate",
                title: "Contract Closing Date",
                filter: {ContractClosingDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ContractClosingDate"
            },
            {
                field: "ContractToTC",
                title: "Contract To TC",
                filter: {ContractToTC: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ContractToTC"
            },
            {
                field: "DeedApprovedBySeller",
                title: "Deed Approved",
                filter: {DeedApprovedBySeller: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "DeedApprovedBySeller"
            },
            {
                field: "HUDReviewed",
                title: "Hud Reviewed",
                filter: {HUDReviewed: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "HUDReviewed"
            },
            {
                field: "SellerWiredDate",
                title: "Seller Wired Date",
                filter: {SellerWiredDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "SellerWiredDate"
            },
            {
                field: "CurrentEmployee",
                title: "RBI Contact",
                getValue: 'emailLinkValue',
                filter: {CurrentEmployee: "text"},
                sortable: "CurrentEmployee"
            }
        ],
        'RPT_HUDPendingRBIReviewApproval': [
            {
                field: "CompanyName",
                title: "Seller",
                filter: {CompanyName: "text"},
                sortable: "CompanyName"
            },
            {
                field: "LoanNumber",
                title: "LoanNumber",
                filter: {LoanNumber: "text"},
                sortable: "LoanNumber"
            },
            {
                field: "Address",
                title: "Address",
                filter: {Address: "text"},
                sortable: "Address"
            },
            {
                field: "City",
                title: "City",
                filter: {City: "text"},
                sortable: "City"
            },
            {
                field: "State",
                title: "State",
                filter: {State: "text"},
                sortable: "State"
            },
            {
                field: "Zip",
                title: "Zip",
                filter: {Zip: "text"},
                sortable: "Zip"
            },
            {
                field: "CWCOTHoldStatus",
                title: "HoldStatus",
                filter: {CWCOTHoldStatus: "text"},
                sortable: "CWCOTHoldStatus"
            },
            {
                field: "AuctionType",
                title: "AuctionType",
                filter: {AuctionType: "text"},
                sortable: "AuctionType"
            },
            {
                field: "ScheduledClosingDate",
                title: "Scheduled Closing Date",
                filter: {ScheduledClosingDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ScheduledClosingDate"
            },
            {
                field: "Reserve",
                title: "Reserve",
                filter: {Reserve: "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "Reserve"
            },
            {
                field: "ContractPrice",
                title: "Contract Price",
                filter: {ContractPrice: "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "ContractPrice"
            },
            {
                field: "DeedRecordedDate",
                title: "Deed Recorded Date",
                filter: {DeedRecordedDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "DeedRecordedDate"
            },
            {
                field: "SoldOnlineDate",
                title: "Sold Online Date",
                filter: {SoldOnlineDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "SoldOnlineDate"
            },
            {
                field: "ContractClosingDate",
                title: "Contract Closing Date",
                filter: {ContractClosingDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ContractClosingDate"
            },
            {
                field: "ContractToTC",
                title: "Contract To TC",
                filter: {ContractToTC: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ContractToTC"
            },
            {
                field: "DeedApprovedBySeller",
                title: "Deed Approved",
                filter: {DeedApprovedBySeller: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "DeedApprovedBySeller"
            },
            {
                field: "hud_received_from_title_company",
                title: "Hud Received From Title Company",
                filter: {hud_received_from_title_company: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "hud_received_from_title_company"
            },
            {
                field: "HUDReviewed",
                title: "Hud Reviewed",
                filter: {HUDReviewed: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "HUDReviewed"
            },
            {
                field: "SellerWiredDate",
                title: "Seller Wired Date",
                filter: {SellerWiredDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "SellerWiredDate"
            }
        ],
        'PropertiesInInventory': [],



        'DocumentsForProperty':[
            // {
            //     field: "file_url",
            //     title: "Action",
            //     filter: {file_url: "text"},
            //     sortable: "file_url",
            //     getValue: 'interpolatedValue',
            //     interpolateExpr: $interpolate("<a target='_blank' href='{{row.file_url}}'>Download</a>")
            // },
            // {
            //     field: "LoanNumber",
            //     title: "Loan Number",
            //     filter: {LoanNumber: "text"},
            //     sortable: "LoanNumber"
            // },
            {
                field: "filename",
                title: "File Name",
                filter: {filename: "text"},
                sortable: "filename"
            },
            {
                field: "description",
                title: "Description",
                filter: {description: "text"},
                sortable: "description"
            },
            {
                field: "created_at",
                title: "Created",
                filter: {created_at: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "created_at"
            },
            {
                field: "updated_at",
                title: "Last Modified",
                filter: {updated_at: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "updated_at"
            },
            {
                field: "username",
                title: "Created By",
                filter: {username: "text"},
                sortable: "username"
            }
        ],

        'Auction':[
            {
                field: "PropertyID",
                title: "Link",
                getValue: 'interpolatedValue',
                interpolateExpr: $interpolate("<button class='btn btn-primary btn-sm' onclick='location.href=&quot;" + $rootScope.urlPrefix + "/houses-for-auction/direct/link/to/property/itemid/{{row.PropertyID}}/&quot;;'>View</button>")
            },
            // {
            //     field: "LoanNumber",
            //     title: "Loan Number",
            //     filter: {LoanNumber: "text"},
            //     sortable: "LoanNumber"
            // },
            {
                field: "PropertyID",
                title: "Property ID",
                filter: {PropertyID: "text"},
                sortable: "PropertyID"
            },
            {
                field: "EventID",
                title: "Event ID",
                filter: {EventID: "text"},
                sortable: "EventID"
            },
            {
                field: "FinalReserve",
                title: "Reserve",
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                filter: {FinalReserve: "text"},
                sortable: "FinalReserve"
            },
            // {
            //     field: "CompanyName",
            //     title: "Company Name",
            //     filter: {CompanyName: "text"},
            //     sortable: "CompanyName"
            // },
            {
                field: "Status",
                title: "Status",
                filter: {Status: "text"},
                sortable: "Status"
            },
            {
                field: "ProgramRecordType",
                title: "Program Record Type",
                filter: {ProgramRecordType: "text"},
                sortable: "ProgramRecordType"
            },
            {
                field: "AuctionStartDate",
                title: "Auction  Start Date",
                filter: {AuctionStartDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "AuctionStartDate"
            },
            {
                field: "AuctionEndDate",
                title: "Auction  End Date",
                filter: {AuctionEndDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "AuctionEndDate"
            },
            {
                field: "PostAuctionEndDate",
                title: "PostAuction  End Date",
                filter: {PostAuctionEndDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "PostAuctionEndDate"
            },


            {
                field: "CloseDate",
                title: "Close  Date",
                filter: {CloseDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "CloseDate"
            },
            {
                field: "Cycle",
                title: "Cycle",
                filter: {Cycle: "text"},
                sortable: "Cycle"
            },
            {
                field: "HighBid",
                title: "HighBid",
                filter: {HighBid: "text"},
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                sortable: "HighBid"
            }
        ],

        'Bids':[
            {
                field: "Customer",
                title: "Customer Name",
                filter: {Customer: "text"},
                sortable: "Customer"
            },
            {
                field: "BID_ID",
                title: "Offer ID",
                filter: {BID_ID: "text"},
                sortable: "BID_ID"
            },
            {
                field: "BID_AMT",
                title: "Offer Amount",
                getValue: 'evaluatedValue',
                valueFormatter: "currency:'$'",
                filter: {BID_AMT: "text"},
                sortable: "BID_AMT"
            },
            {
                field: "BID_DATE",
                title: "Offer Date",
                filter: {BID_DATE: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "BID_DATE"
            },
            // {
            //     field: "OfferAccepted",
            //     title: "Offer Accepted",
            //     filter: {OfferAccepted: "text"},
            //     getValue: 'momentValue',
            //     momentFormat: "MM-DD-YYYY",
            //     sortable: "OfferAccepted"
            // },
            {
                field: "OfferStatus",
                title: "Offer Status",
                filter: {OfferStatus: "text"},
                sortable: "OfferStatus"
            },
            {
                field: "SaleDate",
                title: "Offer Accepted",
                filter: {SaleDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "SaleDate"
            },
            {
                field: "ContractClosingDate",
                title: "Contract Closing Date",
                filter: {ContractClosingDate: "text"},
                getValue: 'momentValue',
                momentFormat: "MM-DD-YYYY",
                sortable: "ContractClosingDate"
            }
        ]
    };

    service.get = function (report) {
        if (!this[report]) {
            return [];
        }
        return this[report];
    };

    return service;

}

export default {
    name: 'ColumnDefinitionService',
    fn: ColumnDefinitionService
};
