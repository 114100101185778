function AllDocumentsCtrl($scope, $interpolate, $resource, $state,$stateParams) {
    'ngInject';

    const ctrl = this;
    const uri = '/clientportal/api/';

    let res = $resource(uri + 'DocumentsView/?nopage=1&format=json');

    ctrl.doc_cols = [
        {
            field: "file_url",
            title: "Action",
            filter: {file_url: "text"},
            sortable: "file_url",
            getValue: 'interpolatedValue',
            interpolateExpr: $interpolate("<button class='btn btn-primary btn-sm' onclick='location.href=&quot;{{row.file_url}}&quot;;'>Download</button>")
        },
        {
            field: "LoanNumber",
            title: "Loan Number",
            filter: {LoanNumber: "text"},
            sortable: "LoanNumber"
        },
        {
            field: "Address",
            title: "Address1",
            filter: {Address: "text"},
            sortable: "Address"
        },
        {
            field: "City",
            title: "City",
            filter: {City: "text"},
            sortable: "City"
        },
        {
            field: "County",
            title: "County",
            filter: {County: "text"},
            sortable: "County"
        },
        {
            field: "State",
            title: "State",
            filter: {State: "text"},
            sortable: "State"
        },
        {
            field: "Zip",
            title: "Zip",
            filter: {Zip: "text"},
            sortable: "Zip"
        },
        {
            field: "filename",
            title: "File Name",
            filter: {filename: "text"},
            sortable: "filename"
        },
        {
            field: "created_at",
            title: "Created",
            filter: {created_at: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "created_at"
        },
        {
            field: "updated_at",
            title: "Last Modified",
            filter: {updated_at: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "updated_at"
        },
        {
            field: "username",
            title: "Created By",
            filter: {username: "text"},
            sortable: "username"
        }
    ];

    ctrl.data_loaded = true;
    res.query().$promise.then(data => {
        ctrl.data = data;
        ctrl.data_loaded = true;
    });
}

export default {
    name: 'AllDocumentsCtrl',
    fn: AllDocumentsCtrl
};
