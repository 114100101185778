function OnConfig($stateProvider, $locationProvider, $urlRouterProvider, $qProvider, $compileProvider, $httpProvider, cfpLoadingBarProvider) {
    'ngInject';

    cfpLoadingBarProvider.includeSpinner = false;

    $locationProvider.html5Mode(false);
    $locationProvider.hasPrefix = '';
    if (process.env.NODE_ENV === 'production') {
        $compileProvider.debugInfoEnabled(false);

    } else {
        $qProvider.errorOnUnhandledRejections(false);
    }
    $httpProvider.defaults.withCredentials = true;
    // $httpProvider.interceptors.push('cookieHttpInterceptor');

    $stateProvider
        .state('DailyTasks', {
            url: '/dailytasks',
            controller: 'DailyTasksCtrl as ctrl',
            templateUrl: 'DailyTasks.html',
            title: 'Daily Tasks',
            resolve: {}
        })
        .state('DrillThrough', {
            url: '/DrillThrough/:report/:parameter',
            controller: 'DrillThroughCtrl as ctrl',
            templateUrl: 'DrillThrough.html',
            title: 'DrillThrough',
            resolve: {}
        })
        .state('ForeclosureStatusDrillThrough', {
            url: '/ForeclosureStatus/:parameter&:program',
            controller: 'ForeclosureStatusDrillThroughCtrl as ctrl',
            templateUrl: 'drillthrough/ForeclosureStatusDrill.html',
            title: 'ForeclosureStatus',
            resolve: {}
        })
        .state('PostForeclosureStatusDrillThrough', {
            url: '/PostForeclosureStatus/:parameter&:program',
            controller: 'PostForeclosureStatusDrillThroughCtrl as ctrl',
            templateUrl: 'drillthrough/PostForeclosureStatusDrill.html',
            title: 'PostForeclosureStatus',
            resolve: {}
        })
        .state('ActiveOnlineAuctionDrillThrough', {
            url: '/ActiveOnlineAuction/:parameter&:program&:EndDate',
            controller: 'ActiveOnlineAuctionDrillThroughCtrl as ctrl',
            templateUrl: 'drillthrough/ActiveOnlineAuctionDrill.html',
            title: 'ActiveOnlineAuction',
            resolve: {}
        })
        .state('ItemsNeededToCloseDrillThrough', {
            url: '/INCDrillThrough/',
            controller: 'ItemsNeededToCloseDrillThroughCtrl as ctrl',
            templateUrl: 'drillthrough/ItemsNeededToCloseDrillThrough.html',
            title: 'Items Needed To Close',
            resolve: {}
        })
        .state('ManagementInventoryReports', {
            url: '/mir',
            controller: 'ManagementInventoryReportsCtrl as ctrl',
            templateUrl: 'ManagementInventoryReports.html',
            title: 'Management Inventory Reports',
            resolve: {}
        })
        .state('ProgramManagers', {
            url: '/pm',
            controller: 'ProgramManagersCtrl as ctrl',
            templateUrl: 'ProgramManagers.html',
            title: 'Program Managers',
            resolve: {}
        })
        .state('ManageProperties', {
            url: '/manageproperties',
            controller: 'ManagePropertiesCtrl as ctrl',
            templateUrl: 'ManageProperties.html',
            title: 'Manage Properties',
            resolve: {}
        })
        .state('LockBoxIssueDate', {
            url: '/LockBoxIssueDate/:parameter',
            controller: 'DTLockBoxIssueDateController as ctrl',
            templateUrl: 'drillthrough/DTLockBoxIssueDate.html',
            title: 'Lockbox Issue Date',
            resolve: {}
        })
        .state('PendingFundingConfirmation', {
            url: '/PendingfundingConfirmation/:parameter',
            controller: 'PendingFundingConfirmationController as ctrl',
            templateUrl: 'drillthrough/PendingFundingConfirmation.html',
            title: 'PendingfundingConfirmation',
            resolve: {}
        })
        //PEndingSellerExecution
        .state('PendingSellerExecution', {
            url: '/PendingSellerExecution/:parameter',
            controller: 'PendingSellerExecutionController as ctrl',
            templateUrl: 'drillthrough/PendingSellerExecution.html',
            title: 'PendingSellerExecution',
            resolve: {}
        })
        .state('NewContractsDrill', {
            url: '/NewContractsDrill/:parameter',
            controller: 'NewContractsDrillController as ctrl',
            templateUrl: 'drillthrough/NewContractsDrill.html',
            title: 'NewContractsDrillController',
            resolve: {}
        })
        .state('ScheduledClosing', {
            url: '/ScheduledClosing/:parameter',
            controller: 'ScheduledClosingController as ctrl',
            templateUrl: 'drillthrough/ScheduledClosing.html',
            title: 'ScheduledClosing',
            resolve: {}
        })
        .state('ClosingPending', {
            url: '/ClosingPending/:parameter',
            controller: 'ClosingPendingController as ctrl',
            templateUrl: 'drillthrough/ClosingPending.html',
            title: 'ClosingPending',
            resolve: {}
        })
        .state('SCTC', {
            url: '/SCTC/:parameter',
            controller: 'SCTCController as ctrl',
            templateUrl: 'drillthrough/SCTC.html',
            title: 'SCTC',
            resolve: {}
        })
        .state('OLAD', {
            url: '/OLAD/:parameter',
            controller: 'OLADController as ctrl',
            templateUrl: 'drillthrough/OLAD.html',
            title: 'Outstanding Liens and Demands',
            resolve: {}
        })
        .state('OfferCancellation', {
            url: '/OfferCancellation/:parameter',
            controller: 'OfferCancellationsController as ctrl',
            templateUrl: 'drillthrough/OfferCancellation.html',
            title: 'Offer Cancellation',
            resolve: {}
        })
        .state('OfferPostponement', {
            url: '/ClosingDatePostponementDrillThrough/:parameter',
            controller: 'OfferPostponementController as ctrl',
            templateUrl: 'drillthrough/OfferPostponement.html',
            title: 'Closing Date Postponement',
            resolve: {}
        })
         .state('PostAuctionClientReview', {
            // url: '/PendingRemovalConfirmation/:parameter&:AgentId',
            url: '/PostAuctionClientReview/:parameter',
            controller: 'PostAuctionClientReviewController as ctrl',
            templateUrl: 'drillthrough/PostAuctionClientReview.html',
            title: 'Post Auction Client Review',
            resolve: {}
        })
        .state('PendingRemovalConfirmation', {
            // url: '/PendingRemovalConfirmation/:parameter&:AgentId',
            url: '/PendingRemovalConfirmation/:parameter',
            controller: 'PendingRemovalConfirmationController as ctrl',
            templateUrl: 'drillthrough/PendingRemovalConfirmation.html',
            title: 'Pending Removal Confirmation',
            resolve: {}
        })
        .state('ClosingDeedsToBeExecuted', {
            url: '/ClosingDeedsToBeExecuted/:parameter',
            controller: 'ClosingDeedsToBeExecutedController as ctrl',
            templateUrl: 'drillthrough/ClosingDeedsToBeExecuted.html',
            title: 'Closing Deeds To Be Executed',
            resolve: {}
        })
        .state('PrelimHudRequiringApproval', {
            url: '/PrelimHudRequiringApproval/:parameter',
            controller: 'PrelimHudRequiringApprovalController as ctrl',
            templateUrl: 'drillthrough/PrelimHudRequiringApproval.html',
            title: 'Prelim HUD Requiring Approval',
            resolve: {}
        })
        .state('PropertyDetails', {
            url: '/manageproperties/:id',
            controller: 'PropertyDetailsCtrl as ctrl',
            templateUrl: 'PropertyDetails.html',
            title: 'Property Details',
            resolve: {}
        })
        .state('PropertyDocuments', {
            url: '/propertydocuments/:id',
            controller: 'PropertyDocumentsCtrl as ctrl',
            templateUrl: 'PropertyDocuments.html',
            title: 'Property Documents',
            resolve: {}
        })
        .state('AllDocuments', {
            url: '/AllDocuments/',
            controller: 'AllDocumentsCtrl as ctrl',
            templateUrl: 'AllDocuments.html',
            title: 'All Documents',
            resolve: {}
        })
        .state('Charts', {
            url: '/charts',
            controller: 'ChartsCtrl as ctrl',
            templateUrl: 'Charts.html',
            title: 'Charts',
            resolve: {}
        })

    $urlRouterProvider.otherwise('/dailytasks');

    var $delegate = $stateProvider.state;
    $stateProvider.state = function(name, definition) {
        if (!definition.resolve) {
            definition.resolve = {};
        }

        return $delegate.apply(this, arguments);
    };

}

export default OnConfig;
