function cookieHttpInterceptor($cookies) {
  'ngInject';


    return {
        request: function (config) {
            let cfid = $cookies.get('CFID');
            let cftoken = $cookies.get('CFTOKEN');

            // console.log(cfid);
            // console.log(cftoken);
            // if (config.headers.Authorization === 'Bearer') {
            //     config.headers.Authorization = 'Bearer ' + btoa(OAuth.accessToken);
            // }
            return config;
        }
    };

}

export default {
  name: 'cookieHttpInterceptor',
  fn: cookieHttpInterceptor
};
