import moment from 'moment-timezone';
import appSettings from '../constants';
import _ from 'lodash';

function HeaderCtrl($scope, $rootScope, $window, $http, $location, $cookies, $interpolate, $resource, $q) {
    'ngInject';

    $scope.show_profile_menu = false;
    $scope.current_user = {};

    $http({
        method: 'GET',
        url: $rootScope.urlAPIPrefix + '/session/sessioninfo',
        withCredentials: true
    }).then(function successCallback(response) {
        if (response.status === 200) {
            $scope.current_user = response.data;
        }
        if (response.status === 401 ||
            response.status === 403 ||
            response.status === 500) {
            $window.location.href = $rootScope.urlLogin;
        }
    }, function errorCallback(response) {
        console.log(response.data.MESSAGE);
        if ($window.location.pathname !== '/login') {
            $window.location.href = $rootScope.urlLogin;
            $scope.$apply();
        }
    });

    $scope.menuProfileCSS = function(show_profile_menu) {
        if(show_profile_menu) {
            return "menu_shown";
        } else {
            return "";
        }
    }
}

export default {
    name: 'HeaderCtrl',
    fn: HeaderCtrl
};
