//Column definitions for drillthroughs
function TitleDefinitionService() {
  'ngInject';

  const service = {
      "RPT_ReviewForHUDExtension": {
          "title": "Review For HUD Extensions"
      },
      "RPT_OfferReceivedNoClosingSet": {
          "title": "Offer Received No Closing Set"
      },
      "RPT_2ndO": {
          "title": "2NDO"
      },
      "RPT_2ndC": {
          "title": "2NDC"
      },
      "RPT_MissingReserveOrValuation": {
          "title": "Missing Reserves or Valuations"
      },
      "RPT_DeedRecorededException": {
          "title": "ABC Side FCL Deed Recorded Exception"
      },
      "RPT_PendingRemovalConfirmation": {
          "title": "Pending Removal Confirmation"
      },
      "RPT_LockboxIssuesPendingCorrection": {
          "title": "Lockbox Issues Pending Correction"
      },
      "RPT_InventorybyStatus": {
          "title": "Inventory By Status"
      },
      "RPT_SubMilestoneStatus": {
          "title": "Sub-Milestone Status"
      },
      "RPT_NSM_2ndChanceAuctionEndDate": {
          "title": "2nd Look Upcoming Events By Auction End Date"
      },
      "RPT_SCTCDate": {
          "title": "SCTC Date"
      },
      "RPT_OfferCanacellation": {
          "title": "Offer Cancellation"
      },
      "RPT_PendingSeller": {
          "title": "Pending Seller Review"
      },
      "RPT_ClosingDeedsToBeExecuted": {
          "title": "Closing Deeds To Be Executed"
      },
      "RPT_OfferPostponement": {
          "title": "Offer Postponement"
      },
      "RPT_HUDPendingRBIReviewApproval": {
          "title": "HUD Pending RBI Review Approval"
      },
      "RPT_TitleIssuesInClosing": {
          "title": "Title Issues In Closing"
      },
      "RPT_OutstandingLiensAndDemands": {
          "title": "Outstanding Liens And Demands"
      },
      "RPT_ForeclosureStatusDrillThrough":{
          "title" : "ForeclosureStatus"
      },
      "RPT_ActiveOnlineAuctionDrillThrough" :{
        "title" :"Active Online Auction"
      },
      "RPT_PostForeclosureStatusDrillThrough" :{
        "title" :"PostForeclosureStatus"
      },
      "ForeclosureStatusDrillThrough" :{
          "title":"Foreclosure Status"
      },
      "RPT_2ndA": {
          "title": "2ndA"
      },
      "RPT_2ndS": {
          "title": "2ndS"
      },
      "RPT_ProvidedReserveNotMatchingCalculatedDrillThrough" :{
          "title" : "Provided Reserve Not Matching Calculated"
      },
      "RPT_MissingReserveOrValuationDrillThrough" :{
          "title" :"Missing Reserve Or Valuation"
      },
      "RPT_ProvidedReserveNotMatchingCalculated" : {
          "title": "Provided Reserve Not Matching Calculated"
      },
      "RPT_PrelimHudRequiringApproval" : {
          "title": "Prelim Hud Requiring Approval"
      },
      "RPT_PropertyIssuesDrillThrough" :{
          "title":"Property Issues"
      },
      "RPT_ClosingDatePostponementDrillThrough" : {
          "title":"Closing Date Postponement"
      },
      "RPT_TitleIssuesInClosingDrillThrough":{
          "title" : "Title Issues In Closing"
      }
  };

  service.get = function(report) {
    if(!this[report]){
      return [];
    }
    return this[report]["title"];
  };

  return service;

}

export default {
  name: 'TitleDefinitionService',
  fn: TitleDefinitionService
};
