//Column definitions for drillthroughs
function PreprocessDataService($resource, $q) {
    'ngInject';

    const service = {
        'PropertiesInInventory': [],

    };

    const uri = '/clientportal/api/';
    let res = $resource(uri + 'GetByATLoanNumber/:id?nopage=1&format=json', {id:'@id'});

    let getLoanNumber = function (data) {
        if('LoanNumber' in data){
          return data['LoanNumber'];
        }
        if('Loan_Number' in data){
            return data['Loan_Number'];
        }
        if('loannumber' in data){
            return data['loannumber'];
        }

        return '-1';
    };

    service.get = function (data) {
        if(Array.isArray(data)){
            let deferred = $q.defer();
            let deferreds = [];

            angular.forEach(data, item =>{
                let _deferred = $q.defer();
                res.query({'id':getLoanNumber(item)}).$promise.then(extra_data => {
                    if(extra_data.length < 1){
                        _deferred.resolve(item);
                        return;
                    }
                    // let merged = Object.assign(item, extra_data[0]);
                    let merged = {};

                    for (var attrname in item) { merged[attrname] = item[attrname]; }
                    for (var attrname in extra_data[0]) { merged[attrname] = extra_data[0][attrname]; }
                    console.log("MERGED");
                    console.log(merged);
                    _deferred.resolve(merged);
                });
                deferreds.push(_deferred.promise);
            });

            $q.all(deferreds).then(mergeddata => {
                deferred.resolve(mergeddata)
            });

            return deferred.promise;
        }else{
            let deferred = $q.defer();
            res.query({'id':getLoanNumber(data)}).$promise.then(extra_data => {
                if(extra_data.length < 1){
                    _deferred.resolve(item);
                    return;
                }
                // let merged = Object.assign(item, extra_data[0]);
                let merged = {};

                for (var attrname in item) { merged[attrname] = item[attrname]; }
                for (var attrname in extra_data[0]) { merged[attrname] = extra_data[0][attrname]; }
                console.log("MERGED");
                console.log(merged);
                deferred.resolve(merged);
            });
            return deferred.promise;
        }
    };

    return service;

}

export default {
    name: 'PreprocessDataService',
    fn: PreprocessDataService
};
