import moment from 'moment';

/*
    --Usage: <clever-table showfilter='true' title="Test Table" dataset="home.dataset"></clever-table>

    showfilter: show or hide the filter inputs for all columns
    title: title to show for the table
    dataset: the dataset, array of dicts. dicts should be col_name:value

    optional:
    hide-page-sizer: Show/Hide the box that lets you choose the number of results per page
    options:  options to be passed to NgTableParams()
    cols: defines which columns to show, along with extra data such as title, how to filter, how to get the value, etc.

    --Styling:

    styles/_clevertable.scss

    ct-small : a compact table useful for overviews with limited amounts of data
    ct-large : a larger table, useful for drill-downs with more detailed data

    --Col Definition example:

    If getValue is not specified, it defaults to htmlValue. To add your own getters, add them to the valueGetters dict.
    htmlValue simply ng-binds the raw data in a <span></span>

    {
        field: "ID", //Field name in dataset is ID
        title: "ID", //Col Title is ID
        sortable: 'ID', //This field is sortable on column ID
        filter: {ID: "text"} //The filter for this column is of type text
    }

    {
        field: "LoadedIntoAT",
        title: "LoadedIntoAT",
        sortable: 'LoadedIntoAT',
        getValue: 'momentValue' //Use the moment JS library to parse and display this field as a date
    },

    {
        field: "BEDROOM_COUNT",
        title: "BEDROOM_COUNT",
        sortable: 'BEDROOM_COUNT',
        filter: {BEDROOM_COUNT: "text"},
        getValue: 'interpolatedValue',  //This is an interpolated field, use interpolateExpr to generate the html
        interpolateExpr: $interpolate("<em class='text-danger'>{{ item.BEDROOM_COUNT | number:1}}</em>")
        //interpolateExpr is an html string wrapped in $interpolate()
    },

    {
        field: "VALUE",
        title: "VALUE",
        sortable: 'VALUE',
        filter: {VALUE: "text"},
        getValue: 'evaluatedValue', //use an angular filter for this field
        valueFormatter: "currency:'$'" // the filter name to apply
    }

 */
function CleverTableDirective(NgTableParams, $interpolate, $compile, $sce, $state) {
    'ngInject';

    return {
        restrict: 'EA',
        templateUrl: 'directives/clevertable.html',
        scope: {
            dataset: '=dataset',
            showfilter: '=showfilter',
            title: '@title',
            hidePageSizer: '=?',
            options: '=?',
            cols: '=?',
            control: '=',
            rowcb: '&?rowcb'
        },
        link: (scope, element, attrs) => {
            scope.internalControl = scope.control || {};

            scope.rowCB = false;

            scope.options = attrs.options || {count: 25};

            if('rowcb' in attrs){
                scope.rowCallback = function(row){
                    scope.rowcb({'row': row})
                }
            }else{
                console.log("NoCallback")
            }

            let gen_cols = false;


            let genParam = function (dataset) {
                gen_cols = true;
                let par = [];
                let keys = new Set();
                angular.forEach(dataset, (data) => {
                    angular.forEach(data, (value, key) => {
                        keys.add(key);
                    })
                });

                angular.forEach(keys, (key) => {
                    let p = {
                        field: key,
                        title: key,
                        sortable: key,
                        filter: {}
                    };

                    p.filter[key] = 'text';

                    par.push(p);
                });

                return par;
            };

            function isFunction(functionToCheck) {
                let getType = {};
                return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
            }

            scope.hidePageSizer = scope.hidePageSizer || false;

            scope.$watch('cols', (newval) => {
                if(!attrs.cols){
                    scope.cols = genParam(scope.dataset);
                }
            });

            scope.$watch('options', (newval) => {
                scope.options = newval || {count: 25};
                if (isFunction(scope.dataset)) {
                    scope.tableparams = new NgTableParams(scope.options, {
                        getData: scope.dataset,
                        hidePageSizer: scope.hidePageSizer
                    });
                } else {
                    scope.tableparams = new NgTableParams(scope.options, {
                        dataset: scope.dataset,
                        hidePageSizer: scope.hidePageSizer
                    });
                }
            });

            scope.$watch('dataset', (newval) => {
                if (isFunction(scope.dataset)) {
                    scope.tableparams = new NgTableParams(scope.options, {
                        getData: scope.dataset,
                        hidePageSizer: scope.hidePageSizer
                    });
                } else {
                    if(gen_cols){
                        if(!attrs.cols){
                            scope.cols = genParam(scope.dataset);
                        }
                    }
                    scope.tableparams = new NgTableParams(scope.options, {
                        dataset: scope.dataset,
                        hidePageSizer: scope.hidePageSizer
                    });
                }

                // clean up the data - replace nulls with ""
                angular.forEach(scope.dataset, (data) => {
                    angular.forEach(data, (value, key) => {
                        if (value === null)
                            data[key] = "";
                    })
                });

                scope.tableparams.reload();
            });

            scope.internalControl.reload = function(){
                scope.tableparams.reload();
            };

            scope.dataset = scope.dataset || [];
            scope.options = scope.options || {};

            scope.cols = scope.cols || genParam(scope.dataset);

            scope.tableparams = new NgTableParams(scope.options, {dataset: scope.dataset});

            scope.getValue = function ($scope, col, row) {
                try {
                    let getter = valueGetters[col.getValue || 'htmlValue'];
                    return getter($scope, col, row);
                }catch (ex){
                    console.log(ex.message);
                    console.log(ex);
                    return $sce.trustAsHtml('<span></span>');
                }
            };


            let valueGetters = {
                htmlValue: function htmlValue($scope, col, row) {
                    let value = row[col.field];
                    let html = "<span>" + value + "</span>";
                    return $sce.trustAsHtml(html);
                },
                momentValue: function htmlValue($scope, col, row) {
                    let value = row[col.field];
                    let format = col.momentFormat || 'LLL';
                    let parser = col.momentParser || null;
                    let date = null;
                    if (parser) {
                        date = moment(value, parser);
                    } else {
                        date = moment(value);
                    }

                    let html = '';

                    if(date.isValid()){
                        html = "<span>" + date.format(format) + "</span>";
                    }else{
                        html = "<span></span>";
                    }

                    return $sce.trustAsHtml(html);
                },
                evaluatedValue: function evaluatedValue($scope, col, row) {
                    let val = $scope.$eval("item." + col.field + " | " + col.valueFormatter, {
                        item: row
                    });
                    return $sce.trustAsHtml(val);
                },
                interpolatedValue: function interpolatedValue($scope, col, row) {
                    let data = col.extradata || {};
                    let val = col.interpolateExpr({
                        row: row,
                        $state: $state,
                        data: data,
                        col: col
                    });
                    return $sce.trustAsHtml(val);
                },
                propertyDetailLinkValue: function propertyDetailLinkValue($scope, col, row) {
                    if('ITEM_ID' in row && row['ITEM_ID']){
                        let url = $state.href("PropertyDetails", { id: row.ITEM_ID });
                        let intExp = $interpolate("<a class=\"btn btn-primary btn-sm\" href='{{url}}'>View</a>");
                        let val = intExp({
                            row: row,
                            $state: $state,
                            url: url
                        });
                        return $sce.trustAsHtml(val);
                    } else if('ITEM_ID' in row && !row['ITEM_ID']){
                        return $sce.trustAsHtml("<span>N/A</span>");
                    }else{
                        return $sce.trustAsHtml("<div class='loader'></div>");
                    }
                },
                emailLinkValue: function emailLinkValue($scope, col, row) {
                    let email = row[col.field];
                    let emaillink = row[col.field];

                    let loannum = null;
                    let itemid = null;

                    if('LoanNumber' in row){
                        loannum = row['LoanNumber'];
                    }
                    if('Loan_Number' in row){
                        loannum = row['Loan_Number'];
                    }
                    if('loannumber' in row){
                        loannum = row['loannumber'];
                    }

                    if('item_id' in row){
                        itemid = row['item_id'];
                    }
                    if('ITEM_ID' in row){
                        itemid = row['ITEM_ID'];
                    }
                    if('itemid' in row){
                        itemid = row['itemid'];
                    }
                    if('ITEMID' in row){
                        itemid = row['ITEMID'];
                    }

                    let subject = null;

                    if(itemid || loannum){
                        if(itemid){
                            subject = 'Property ID: ' + itemid + ' ';
                        }
                        if (loannum){
                            if(subject){
                               subject = subject + '/ ';
                            }else{
                                subject = '';
                            }
                            subject = subject + 'Loan Number: ' + loannum + ' ';
                        }
                    }

                    if(subject){
                        emaillink = email + '?subject=' +encodeURI(subject);
                    }

                    let intExp = $interpolate("<a href='mailto:{{emaillink}}'>{{email}}</a>");
                    let val = intExp({
                        email: email,
                        emaillink: emaillink
                    });

                    return $sce.trustAsHtml(val);
                }
            };
        }
    };
}

export default {
    name: 'cleverTable',
    fn: CleverTableDirective
};
