import data from '../data/data.js';
import _ from "lodash";

function ManagementInventoryReportsCtrl($scope, $interpolate, $resource, $q, $state) {
    'ngInject';

    $('main').css({transition: 'max-width 1s ease-in-out'})
    $('main').css({'max-width': '100%'})
    
    // ViewModel
    const ctrl = this;
    const uri = '/clientportal/api/';

    ctrl.hidePageSizer = true;

// Define the isLoaded variables for each query
    ctrl.inventorybyStatus_isLoaded = false;
    ctrl.reviewForHUDExtension_isLoaded = false;
    ctrl.NSM_2ndChanceAuctionEndDate_isLoaded = false;

// Define the queries
    let resAgent = $resource(uri + 'AmsFeatureAccess/?format=json&nopage=1');
    resAgent.query().$promise.then(data => {

     ctrl.agent = data[0]['agent_id'];
     console.log("ctrl.agent in managementinventory", ctrl.agent);
let rpt_InventoryByStatus = $resource(uri+'RPT_ForeclosureStatus/:id?format=json&nopage=1', {id:'@id','AgentId':ctrl.agent});
        let rpt_SubMilestoneStatus = $resource(uri+'RPT_PostForeclosureStatus/:id?format=json&nopage=1', {id:'@id','AgentId':ctrl.agent});
        let rpt_NSM_2ndChanceAuctionEndDate = $resource(uri+'RPT_ActiveOnlineAuction/:id?format=json&nopage=1', {id:'@id','AgentId':ctrl.agent});
        rpt_InventoryByStatus.query().$promise.then(data => {
            Load_InventoryByStatus($scope, ctrl, data);
        });
        rpt_SubMilestoneStatus.query().$promise.then(data => {
            Load_SubMilestoneStatus($scope, ctrl, data);
        });
        rpt_NSM_2ndChanceAuctionEndDate.query().$promise.then(data => {
            Load_NSM_2ndChanceAuctionEndDate($scope, ctrl, data);
        });
        });


// Execute the queries



    ctrl.inventorybyStatus_cols = [
        {
            field: "Status",
            title: "Status",
            filter: {Status: "text"}
        },
        {
            field: "PROGRAM_NAME",
            title: "Program",
            filter: {Status: "text"}
        },
        {
            field: "RecordCount",
            title: "Count",
            filter: {RecordCount: "text"}
        }
    ];
    ctrl.inventoryByStatusCB = function(row){
        console.log("INV STAT CB: ", row);
        $state.go('ForeclosureStatusDrillThrough', {'parameter':row['StatusID'], 'program':row['PROGRAM_ID']});
    };

    ctrl.subMilestoneStatus_cols = [
        {
            field: "Status",
            title: "Status",
            filter: {'Status': "text"}
        },
        {
            field: "PROGRAM_NAME",
            title: "Program",
            filter: {Status: "text"}
        },

         {
            field: "Total",
            title: "Count",
            filter: {Total: "text"}
        }

    ];
    ctrl.subMilestoneStatusCB = function(row){
        console.log(row);
        $state.go('PostForeclosureStatusDrillThrough', {'parameter':row['StatusID'], 'program':row['PROGRAM_ID']});
        //$state.go('DrillThrough', {'report':'RPT_PostForeclosureStatusDrillThrough','parameter':row['StatusID'],'program':row['PROGRAM_ID']});
        //$state.go('DrillThrough', {'report':'RPT_PostForeclosureStatusDrillThrough','parameter':row['Status'], 'AgentId':ctrl.agent});
    };


    ctrl.NSM_2ndChanceAuctionEndDate_cols = [
        {
            field: "Status",
            title: "Status",
            filter: {Status: "text"}
        },
        {
            field: "PROGRAM_NAME",
            title: "Program",
            filter: {Status: "text"}
        },
        {
            field: "EndDate",
            title: "EndDate",
            filter: {EndDate: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "ActiveAuctions"
        },
        {
            field: "Total",
            title: "Count",
            filter: {'Total': "text"}
        }
    ];
    ctrl.NSM_2ndChanceAuctionEndDateCB = function(row){
        $state.go('ActiveOnlineAuctionDrillThrough',{'parameter':row['StatusID'],'program':row['PROGRAM_ID'],'EndDate':row['EndDate']});//,'EndDate':row[EndDate]});
    };

}

export default {
    name: 'ManagementInventoryReportsCtrl',
    fn: ManagementInventoryReportsCtrl
};


// Define Load Functions

function Load_InventoryByStatus($scope, ctrl, data) {
    $scope.$watch('ctrl.inventoryByStatusData', () => {
        ctrl.inventorybyStatus_isLoaded = true;

        ctrl.inventorybyStatus_total = _.reduce(ctrl.inventorybyStatusData, (total, record) => {
            total += record.RecordCount;
            return total;
        }, 0);
    });

    //let sortedResources = _.sortBy(data, ["StatusID"]);

    let tempValue="";
    let updatedResources = _.forEach(data, function(data){
          if(tempValue != data.Status) {
              tempValue = data.Status;
          } else {
              data.Status="";
          }
      });
    ctrl.inventorybyStatusData = updatedResources;
}

function Load_SubMilestoneStatus($scope, ctrl, data) {
    $scope.$watch('ctrl.subMilestoneStatusData', () => {
        ctrl.subMilestoneStatus_isLoaded = true;

        ctrl.subMilestoneStatus_total = _.reduce(ctrl.subMilestoneStatusData, (total, record) => {
            total += record.Total;
            return total;
        }, 0);
    });

    let tempValue="";
    let updatedResources = _.forEach(data, function(data){
          if(tempValue != data.Status) {
              tempValue = data.Status;
          } else {
              data.Status="";
          }
      });
    ctrl.subMilestoneStatusData = updatedResources;
    //ctrl.subMilestoneStatusData = _.sortBy(data, ["Status", "SortOrder"]);
}


function Load_NSM_2ndChanceAuctionEndDate($scope, ctrl, data) {
    $scope.$watch('ctrl.NSM_2ndChanceAuctionEndDateData', () => {
        ctrl.NSM_2ndChanceAuctionEndDate_isLoaded = true;

        ctrl.NSM_2ndChanceAuctionEndDate_total = _.reduce(ctrl.NSM_2ndChanceAuctionEndDateData, (total, record) => {
            total += record['Total'];

            return total;
        }, 0);
    });
    let tempValue="";
    let updatedResources = _.forEach(data, function(data){
          if(tempValue != data.Status) {
              tempValue = data.Status;
          } else {
              data.Status="";
          }
      });

    ctrl.NSM_2ndChanceAuctionEndDateData = updatedResources;
}

// todo: 2nd Look Upcoming Events
