function FixDateReadOnlyFilter() {

    return function(date) {
        if(!date) return date;
        let _date = new Date(date);
        let _userOffset = _date.getTimezoneOffset()*60*1000; // user's offset time
        let out = new Date(_date.getTime() + _userOffset);
        // console.log("getDate from : " + iso_date + " to : "+ out);
        return out; // add it back
    };

}

export default {
    name: 'fixDateReadOnly',
    fn: FixDateReadOnlyFilter
};



