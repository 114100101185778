export default
[
    {
        "AGENT_ID": 9632438,
        "Client": "*RealtyBid International, LLC",
        "LoanNumber": 50442847,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "18590 HWY 127",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Withdrawn",
        "CLOSING_STATUS_ID": "17",
        "closingStatusDesc": "Withdrawn",
        "LoadedIntoAT": "2015-11-12 14:06:01.223",
        "ITEM_ID": 627531,
        "STATUS_ID": 8,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 9632438,
        "ASSET_NUMBER": "50442847",
        "ADDRESS1": "18590 Hwy 127",
        "ADDRESS2": "",
        "CITY": "Garfield",
        "STATE": "AR",
        "COUNTRY": "USA",
        "POSTAL_CODE": 72732,
        "BEDROOM_COUNT": 0,
        "BATH_COUNT": 1,
        "SQ_FT": "998",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 0,
        "FINAL_RESERVE": 36000,
        "PHOTO_PATH": "/upload.nfs/photos/0116/627531_183.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 70314,
        "CATEGORY_ID": 3,
        "COUNTY": "Benton  County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "2.11 acre",
        "PROPERTY_OWNER": "CMS 2:1 WD",
        "AUCTION_TYPE": "RE",
        "AUCTION_START_DATE": "2016-01-11 12:00:00.000",
        "AUCTION_END_DATE": "2016-01-26 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-01-26 17:00:00.000",
        "AUCTION_DAY_COUNT": 15,
        "MIN_BID_AMT": 28500,
        "RESERVE_PRICE_AMT": 35880,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "D6BB6C90-84B8-E511-8DFA-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "2012",
        "FIREPLACE": "0",
        "GARAGE_SIZE": "0",
        "HEAT_TYPE": "0",
        "BASEMENT": "0",
        "HALF_BATH": 0,
        "SUBDIVISION": "0",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RE",
        "LOADED_DATE": "2016-01-11 10:58:41.473",
        "LOAN_NUMBER": null,
        "Longitude": "-93.9675790",
        "Latitude": "36.4469130",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2016-01-29 12:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2016-01-28 17:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56536059",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 2,
        "Address_Line": "18590 Hwy 127, Garfield, AR 72732",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "18590 Hwy 127 Garfield AR 72732",
        "ID": 19228
    },
    {
        "AGENT_ID": 9632438,
        "Client": "*RealtyBid International, LLC",
        "LoanNumber": 53148557,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "13926 PINE STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Withdrawn",
        "CLOSING_STATUS_ID": "17",
        "closingStatusDesc": "Withdrawn",
        "LoadedIntoAT": "2015-10-13 15:26:24.420",
        "ITEM_ID": 627532,
        "STATUS_ID": 8,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 9632438,
        "ASSET_NUMBER": "53148557",
        "ADDRESS1": "13926 Pine Street",
        "ADDRESS2": "",
        "CITY": "Trona",
        "STATE": "CA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 93562,
        "BEDROOM_COUNT": 5,
        "BATH_COUNT": 0,
        "SQ_FT": "3280",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 0,
        "FINAL_RESERVE": 75500,
        "PHOTO_PATH": "/upload.nfs/photos/0116/627532_100.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 70315,
        "CATEGORY_ID": 5,
        "COUNTY": "San Bernardino County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "10227 sqft",
        "PROPERTY_OWNER": "CMS 2:1 WD",
        "AUCTION_TYPE": "RE",
        "AUCTION_START_DATE": "2016-01-11 12:00:00.000",
        "AUCTION_END_DATE": "2016-01-26 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-01-26 17:00:00.000",
        "AUCTION_DAY_COUNT": 15,
        "MIN_BID_AMT": 60000,
        "RESERVE_PRICE_AMT": 75460,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "DDBB6C90-84B8-E511-8DFA-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1978",
        "FIREPLACE": "Y",
        "GARAGE_SIZE": "0",
        "HEAT_TYPE": "0",
        "BASEMENT": "0",
        "HALF_BATH": 0,
        "SUBDIVISION": "0",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RE",
        "LOADED_DATE": "2016-01-11 10:58:42.730",
        "LOAN_NUMBER": null,
        "Longitude": "-117.3605630",
        "Latitude": "35.7942830",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2016-01-29 12:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2016-01-28 17:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56536059",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 2,
        "Address_Line": "13926 Pine Street, Trona, CA 93562",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "13926 Pine Street Trona CA 93562",
        "ID": 18766
    },
    {
        "AGENT_ID": 9632438,
        "Client": "*RealtyBid International, LLC",
        "LoanNumber": 53169009,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "925 VANDALIA AVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Withdrawn",
        "CLOSING_STATUS_ID": "17",
        "closingStatusDesc": "Withdrawn",
        "LoadedIntoAT": "2015-10-13 15:26:19.370",
        "ITEM_ID": 627534,
        "STATUS_ID": 8,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 9632438,
        "ASSET_NUMBER": "53169009",
        "ADDRESS1": "925 Vandalia Ave",
        "ADDRESS2": "",
        "CITY": "Porterville",
        "STATE": "CA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 93257,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1580",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 0,
        "FINAL_RESERVE": 110000,
        "PHOTO_PATH": "/upload.nfs/photos/0116/627534_87.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 70317,
        "CATEGORY_ID": 5,
        "COUNTY": "Tulare County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "CMS 2:1 WD",
        "AUCTION_TYPE": "RE",
        "AUCTION_START_DATE": "2016-01-11 12:00:00.000",
        "AUCTION_END_DATE": "2016-01-26 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-01-26 17:00:00.000",
        "AUCTION_DAY_COUNT": 15,
        "MIN_BID_AMT": 87000,
        "RESERVE_PRICE_AMT": 109340,
        "BID_INCREMENT_AMT": 1000,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "6A4B7696-84B8-E511-8DFA-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "0",
        "FIREPLACE": "0",
        "GARAGE_SIZE": "0",
        "HEAT_TYPE": "0",
        "BASEMENT": "0",
        "HALF_BATH": 0,
        "SUBDIVISION": "0",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RE",
        "LOADED_DATE": "2016-01-11 10:58:52.643",
        "LOAN_NUMBER": null,
        "Longitude": "-118.9966940",
        "Latitude": "36.0532560",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2016-01-29 12:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2016-01-28 17:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "456792 / 120013",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56536059",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "Manuel@mcrealestatecorp.com; robin.dumas@realtybid.com",
        "MLS_EXP_DATE": "2016-06-04 00:00:00.000",
        "PROGRAM_ID": 2,
        "Address_Line": "925 Vandalia Ave, Porterville, CA 93257",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "925 Vandalia Ave Porterville CA 93257",
        "ID": 18682
    },
    {
        "AGENT_ID": 56292995,
        "Client": "MATT MARTIN REAL ESTATE MANAGEMENT",
        "LoanNumber": 89042139,
        "AT_Status": "Auction",
        "AT_AuctionType": "Marketing Only",
        "AT_Address1": "143 BALTUSROL RD",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Available",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-12-15 08:07:18.837",
        "ITEM_ID": 641638,
        "STATUS_ID": 2,
        "ASSET_MANAGER_ID": 252624376,
        "SELLER_ID": 56292995,
        "ASSET_NUMBER": "481-369362",
        "ADDRESS1": "143 Baltusrol Rd",
        "ADDRESS2": null,
        "CITY": "Hixson",
        "STATE": "TN",
        "COUNTRY": "USA",
        "POSTAL_CODE": 37343,
        "BEDROOM_COUNT": 4,
        "BATH_COUNT": 3,
        "SQ_FT": "3376",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 260000,
        "FINAL_RESERVE": 260000,
        "PHOTO_PATH": "http://www.hudhomestore.com/DocumentManager/Region-4/TN/481-369362/LISTING%20SITE//FR_50553137.PNG",
        "SELLER_CONTACT_ID": 252624376,
        "AMS_SELLER_ID": "55699501",
        "AMS_AUCTION_NO": 80638,
        "CATEGORY_ID": 43,
        "COUNTY": "Hamilton County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "",
        "PROPERTY_OWNER": "Hud",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2016-09-03 19:00:00.000",
        "AUCTION_END_DATE": "2016-10-03 19:00:00.000",
        "AUCTION_STOP_DATE": "2016-10-03 19:00:00.000",
        "AUCTION_DAY_COUNT": 30,
        "MIN_BID_AMT": 130000,
        "RESERVE_PRICE_AMT": 260000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 250,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "F8E39572-E342-E611-9902-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "252624376",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 2,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2016-07-05 14:05:41.223",
        "LOAN_NUMBER": null,
        "Longitude": "-85.2129310",
        "Latitude": "35.1550030",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": null,
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": null,
        "ADDRESS_UNIT": null,
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": "2016-08-03 14:24:11.000",
        "show_on_site": 1,
        "SUB_SELLER_ID": "56547875",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "143 Baltusrol Rd, Hixson, TN 37343",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "143 Baltusrol Rd Hixson TN 37343",
        "ID": 19766
    },
    {
        "AGENT_ID": 56292995,
        "Client": "MATT MARTIN REAL ESTATE MANAGEMENT",
        "LoanNumber": 4000038396,
        "AT_Status": "Removed",
        "AT_AuctionType": "Other",
        "AT_Address1": "894 RED FOX AVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Withdrawn",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-09-16 11:41:09.007",
        "ITEM_ID": 637472,
        "STATUS_ID": 8,
        "ASSET_MANAGER_ID": 252624376,
        "SELLER_ID": 56292995,
        "ASSET_NUMBER": "481-272894",
        "ADDRESS1": "894 Red Fox Ave",
        "ADDRESS2": null,
        "CITY": "Morristown",
        "STATE": "TN",
        "COUNTRY": "USA",
        "POSTAL_CODE": 37813,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 1,
        "SQ_FT": "1268",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 38000,
        "FINAL_RESERVE": 38000,
        "PHOTO_PATH": "https://www.hudhomestore.com/Templates/ResCorpInnerStyle/NoImageFound.jpg",
        "SELLER_CONTACT_ID": 252624376,
        "AMS_SELLER_ID": "55699501",
        "AMS_AUCTION_NO": 77476,
        "CATEGORY_ID": 43,
        "COUNTY": "Hamblen County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "",
        "PROPERTY_OWNER": "Hud",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2016-05-26 14:12:53.000",
        "AUCTION_END_DATE": "2016-06-25 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-25 17:00:00.000",
        "AUCTION_DAY_COUNT": 30,
        "MIN_BID_AMT": 15000,
        "RESERVE_PRICE_AMT": 38000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 250,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "56EAA909-E321-E611-A3FA-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "252624376",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2016-05-24 14:09:35.090",
        "LOAN_NUMBER": null,
        "Longitude": null,
        "Latitude": null,
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": null,
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": null,
        "ADDRESS_UNIT": null,
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": "2016-05-26 14:12:53.000",
        "show_on_site": 1,
        "SUB_SELLER_ID": "56547875",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "894 Red Fox Ave, Morristown, TN 37813",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "894 Red Fox Ave Morristown TN 37813",
        "ID": 15028
    },
    {
        "AGENT_ID": 56292995,
        "Client": "MATT MARTIN REAL ESTATE MANAGEMENT",
        "LoanNumber": 6000002335,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "216 N MAIN STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Available",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-09-16 11:41:08.077",
        "ITEM_ID": 630911,
        "STATUS_ID": 2,
        "ASSET_MANAGER_ID": 55847794,
        "SELLER_ID": 56292995,
        "ASSET_NUMBER": "413-473030",
        "ADDRESS1": "216 N Main Street",
        "ADDRESS2": null,
        "CITY": "Mount Victory",
        "STATE": "OH",
        "COUNTRY": "USA",
        "POSTAL_CODE": 43340,
        "BEDROOM_COUNT": 2,
        "BATH_COUNT": 3,
        "SQ_FT": "1724",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 40000,
        "FINAL_RESERVE": 44000,
        "PHOTO_PATH": "http://www.hudhomestore.com/DocumentManager/Region-3/OH/413-473030/LISTING%20SITE//FRONT_47713113.jpg",
        "SELLER_CONTACT_ID": 55847794,
        "AMS_SELLER_ID": "55454905",
        "AMS_AUCTION_NO": 73106,
        "CATEGORY_ID": 36,
        "COUNTY": "Hardin County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "",
        "PROPERTY_OWNER": "Hud",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2016-05-16 19:00:00.000",
        "AUCTION_END_DATE": "2016-06-15 19:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-15 19:00:00.000",
        "AUCTION_DAY_COUNT": 30,
        "MIN_BID_AMT": 17500,
        "RESERVE_PRICE_AMT": 44000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 250,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "000C5076-6BF0-E511-937F-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "55847794",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2016-03-22 15:20:13.593",
        "LOAN_NUMBER": null,
        "Longitude": "-83.5220459",
        "Latitude": "40.5365078",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": null,
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": null,
        "ADDRESS_UNIT": null,
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": "2016-04-15 14:10:40.000",
        "show_on_site": 1,
        "SUB_SELLER_ID": "56547875",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "216 N Main Street, Mount Victory, OH 43340",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "216 N Main Street Mount Victory OH 43340",
        "ID": 14818
    },
    {
        "AGENT_ID": 56292995,
        "Client": "MATT MARTIN REAL ESTATE MANAGEMENT",
        "LoanNumber": 6000002856,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "3065 DAYTON AVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Available",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-09-16 11:42:09.257",
        "ITEM_ID": 624250,
        "STATUS_ID": 2,
        "ASSET_MANAGER_ID": 55847812,
        "SELLER_ID": 56292995,
        "ASSET_NUMBER": "331-129465",
        "ADDRESS1": "3065 Dayton Ave",
        "ADDRESS2": null,
        "CITY": "Silver Springs",
        "STATE": "NV",
        "COUNTRY": "USA",
        "POSTAL_CODE": 89429,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1740",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 15,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 120000,
        "FINAL_RESERVE": 120000,
        "PHOTO_PATH": "http://www.hudhomestore.com/DocumentManager/Region-3/NV/331-129465/LISTING%20SITE//FRONT_46082742.jpg",
        "SELLER_CONTACT_ID": 55847812,
        "AMS_SELLER_ID": "55454918",
        "AMS_AUCTION_NO": 68705,
        "CATEGORY_ID": 34,
        "COUNTY": "Lyon County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "",
        "PROPERTY_OWNER": "Hud",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2016-01-02 19:00:00.000",
        "AUCTION_END_DATE": "2016-02-01 19:00:00.000",
        "AUCTION_STOP_DATE": "2016-02-01 19:00:00.000",
        "AUCTION_DAY_COUNT": 30,
        "MIN_BID_AMT": 60000,
        "RESERVE_PRICE_AMT": 120000,
        "BID_INCREMENT_AMT": 1000,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 250,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "42A06446-5598-E511-9B99-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "55847812",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-12-01 11:59:35.987",
        "LOAN_NUMBER": null,
        "Longitude": "-119.2691658",
        "Latitude": "39.3862896",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": null,
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": null,
        "ADDRESS_UNIT": null,
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": "2015-12-02 14:12:45.000",
        "show_on_site": 1,
        "SUB_SELLER_ID": "56547875",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "3065 Dayton Ave, Silver Springs, NV 89429",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "3065 Dayton Ave Silver Springs NV 89429",
        "ID": 18346
    },
    {
        "AGENT_ID": 56292995,
        "Client": "MATT MARTIN REAL ESTATE MANAGEMENT",
        "LoanNumber": 6000008794,
        "AT_Status": "Removed",
        "AT_AuctionType": "Other",
        "AT_Address1": "782 COLLEGE STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Withdrawn",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-09-16 11:41:08.820",
        "ITEM_ID": 640072,
        "STATUS_ID": 8,
        "ASSET_MANAGER_ID": 252624376,
        "SELLER_ID": 56292995,
        "ASSET_NUMBER": "483-397250",
        "ADDRESS1": "782 College Street",
        "ADDRESS2": null,
        "CITY": "Portland",
        "STATE": "TN",
        "COUNTRY": "USA",
        "POSTAL_CODE": 37148,
        "BEDROOM_COUNT": 4,
        "BATH_COUNT": 2,
        "SQ_FT": "2130",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 101000,
        "FINAL_RESERVE": 101000,
        "PHOTO_PATH": "http://www.hudhomestore.com/DocumentManager/Region-4/TN/483-397250/LISTING%20SITE//483-397250FRT_47856560.jpg",
        "SELLER_CONTACT_ID": 252624376,
        "AMS_SELLER_ID": "55699501",
        "AMS_AUCTION_NO": 79352,
        "CATEGORY_ID": 43,
        "COUNTY": "Sumner County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "",
        "PROPERTY_OWNER": "Hud",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2016-06-18 14:16:19.000",
        "AUCTION_END_DATE": "2016-07-18 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-07-18 17:00:00.000",
        "AUCTION_DAY_COUNT": 30,
        "MIN_BID_AMT": 50000,
        "RESERVE_PRICE_AMT": 101000,
        "BID_INCREMENT_AMT": 1000,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 250,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "04B80018-2D33-E611-9902-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "252624376",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2016-06-15 14:12:26.357",
        "LOAN_NUMBER": null,
        "Longitude": null,
        "Latitude": null,
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": null,
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": null,
        "ADDRESS_UNIT": null,
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": "2016-06-18 14:16:19.000",
        "show_on_site": 1,
        "SUB_SELLER_ID": "56547875",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "782 College Street, Portland, TN 37148",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "782 College Street Portland TN 37148",
        "ID": 14993
    },
    {
        "AGENT_ID": 56292995,
        "Client": "MATT MARTIN REAL ESTATE MANAGEMENT",
        "LoanNumber": 6000009174,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "3803 NE 10TH AVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Available",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-09-16 11:41:07.773",
        "ITEM_ID": 626810,
        "STATUS_ID": 2,
        "ASSET_MANAGER_ID": 55847794,
        "SELLER_ID": 56292995,
        "ASSET_NUMBER": "494-343933",
        "ADDRESS1": "3803 Ne 10th Ave",
        "ADDRESS2": null,
        "CITY": "Amarillo",
        "STATE": "TX",
        "COUNTRY": "USA",
        "POSTAL_CODE": 79107,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 1,
        "SQ_FT": "1446",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 53000,
        "FINAL_RESERVE": 53000,
        "PHOTO_PATH": "http://www.hudhomestore.com/DocumentManager/Region-4/TX/494-343933/LISTING%20SITE//Glam_47335264.jpg",
        "SELLER_CONTACT_ID": 55847794,
        "AMS_SELLER_ID": "55454905",
        "AMS_AUCTION_NO": 69710,
        "CATEGORY_ID": 44,
        "COUNTY": "Potter County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "",
        "PROPERTY_OWNER": "Hud",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2016-01-19 19:00:00.000",
        "AUCTION_END_DATE": "2016-02-18 19:00:00.000",
        "AUCTION_STOP_DATE": "2016-02-18 19:00:00.000",
        "AUCTION_DAY_COUNT": 30,
        "MIN_BID_AMT": 21000,
        "RESERVE_PRICE_AMT": 53000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 250,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "32FE65A1-C3A5-E511-8DFA-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "55847794",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 2,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-12-18 14:12:17.600",
        "LOAN_NUMBER": null,
        "Longitude": "-101.7928390",
        "Latitude": "35.2240650",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": null,
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": null,
        "ADDRESS_UNIT": null,
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": "2015-12-19 14:12:05.000",
        "show_on_site": 1,
        "SUB_SELLER_ID": "56547875",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "3803 Ne 10th Ave, Amarillo, TX 79107",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "3803 Ne 10th Ave Amarillo TX 79107",
        "ID": 14660
    },
    {
        "AGENT_ID": 56292995,
        "Client": "MATT MARTIN REAL ESTATE MANAGEMENT",
        "LoanNumber": 6000010075,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Pending 2nd Chance",
        "AT_Address1": "1010 MYRTLE STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Available",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-12-09 07:37:48.753",
        "ITEM_ID": 636906,
        "STATUS_ID": 2,
        "ASSET_MANAGER_ID": 252624376,
        "SELLER_ID": 56292995,
        "ASSET_NUMBER": "481-279374",
        "ADDRESS1": "1010 Myrtle Street",
        "ADDRESS2": null,
        "CITY": "Kingsport",
        "STATE": "TN",
        "COUNTRY": "USA",
        "POSTAL_CODE": 37660,
        "BEDROOM_COUNT": 2,
        "BATH_COUNT": 1,
        "SQ_FT": "720",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 42000,
        "FINAL_RESERVE": 42000,
        "PHOTO_PATH": "http://www.hudhomestore.com/DocumentManager/Region-4/TN/481-279374/LISTING%20SITE//fr_279374_49743175.JPG",
        "SELLER_CONTACT_ID": 252624376,
        "AMS_SELLER_ID": "55699501",
        "AMS_AUCTION_NO": 77162,
        "CATEGORY_ID": 43,
        "COUNTY": "Sullivan County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "",
        "PROPERTY_OWNER": "Hud",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2016-09-03 19:00:00.000",
        "AUCTION_END_DATE": "2016-10-03 19:00:00.000",
        "AUCTION_STOP_DATE": "2016-10-03 19:00:00.000",
        "AUCTION_DAY_COUNT": 30,
        "MIN_BID_AMT": 16750,
        "RESERVE_PRICE_AMT": 42000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 250,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "09D7F4B4-2C1D-E611-A3FA-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "252624376",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2016-05-18 14:14:17.877",
        "LOAN_NUMBER": null,
        "Longitude": "-82.5477960",
        "Latitude": "36.5411950",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": null,
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": null,
        "ADDRESS_UNIT": null,
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": "2016-08-03 14:25:33.000",
        "show_on_site": 1,
        "SUB_SELLER_ID": "56547875",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "1010 Myrtle Street, Kingsport, TN 37660",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "1010 Myrtle Street Kingsport TN 37660",
        "ID": 19465
    },
    {
        "AGENT_ID": 56292995,
        "Client": "MATT MARTIN REAL ESTATE MANAGEMENT",
        "LoanNumber": 6000010786,
        "AT_Status": "Pre-Auction",
        "AT_AuctionType": "Pending",
        "AT_Address1": "217 LIBERTY ST",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Available",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-10-19 10:52:20.767",
        "ITEM_ID": 626216,
        "STATUS_ID": 2,
        "ASSET_MANAGER_ID": 55847794,
        "SELLER_ID": 56292995,
        "ASSET_NUMBER": "541-663239",
        "ADDRESS1": "217 Liberty St",
        "ADDRESS2": null,
        "CITY": "Petersburg",
        "STATE": "VA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 23803,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "2292",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 75500,
        "FINAL_RESERVE": 75500,
        "PHOTO_PATH": "http://www.hudhomestore.com/DocumentManager/Region-4/VA/541-663239/LISTING%20SITE//FRONT_47282242.jpg",
        "SELLER_CONTACT_ID": 55847800,
        "AMS_SELLER_ID": "55454909",
        "AMS_AUCTION_NO": 69307,
        "CATEGORY_ID": 46,
        "COUNTY": "Petersburg City County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "",
        "PROPERTY_OWNER": "Hud",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2016-01-16 19:00:00.000",
        "AUCTION_END_DATE": "2016-02-15 19:00:00.000",
        "AUCTION_STOP_DATE": "2016-02-15 19:00:00.000",
        "AUCTION_DAY_COUNT": 30,
        "MIN_BID_AMT": 30000,
        "RESERVE_PRICE_AMT": 75500,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 250,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "91BFA871-42A0-E511-8DFA-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "55847794",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-12-11 14:04:54.980",
        "LOAN_NUMBER": null,
        "Longitude": "-77.4056317",
        "Latitude": "37.2224632",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": null,
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": null,
        "ADDRESS_UNIT": null,
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": "2015-12-16 14:00:38.000",
        "show_on_site": 1,
        "SUB_SELLER_ID": "56547875",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "217 Liberty St, Petersburg, VA 23803",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "217 Liberty St Petersburg VA 23803",
        "ID": 18889
    },
    {
        "AGENT_ID": 56292995,
        "Client": "MATT MARTIN REAL ESTATE MANAGEMENT",
        "LoanNumber": 6000010786,
        "AT_Status": "Pre-Auction",
        "AT_AuctionType": "Pending",
        "AT_Address1": "217 LIBERTY ST",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Available",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-10-19 10:52:20.767",
        "ITEM_ID": 633319,
        "STATUS_ID": 2,
        "ASSET_MANAGER_ID": 252893017,
        "SELLER_ID": 56292995,
        "ASSET_NUMBER": "446-012582",
        "ADDRESS1": "217 Liberty St",
        "ADDRESS2": null,
        "CITY": "West Pittston",
        "STATE": "PA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 18643,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 1,
        "SQ_FT": "1484",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 66400,
        "FINAL_RESERVE": 83000,
        "PHOTO_PATH": "http://www.hudhomestore.com/DocumentManager/Region-3/PA/446-012582/LISTING%20SITE//FRONT_49360642.jpg",
        "SELLER_CONTACT_ID": 55847794,
        "AMS_SELLER_ID": "55454905",
        "AMS_AUCTION_NO": 74489,
        "CATEGORY_ID": 39,
        "COUNTY": "Luzerne County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "",
        "PROPERTY_OWNER": "Hud",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2016-09-03 19:00:00.000",
        "AUCTION_END_DATE": "2016-10-03 19:00:00.000",
        "AUCTION_STOP_DATE": "2016-10-03 19:00:00.000",
        "AUCTION_DAY_COUNT": 30,
        "MIN_BID_AMT": 33000,
        "RESERVE_PRICE_AMT": 83000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 250,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "DDC6EFC0-BDFD-E511-937F-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "55847794",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2016-04-08 14:11:58.690",
        "LOAN_NUMBER": null,
        "Longitude": "-75.7958480",
        "Latitude": "41.3300380",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": null,
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": null,
        "ADDRESS_UNIT": null,
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": "2016-08-03 14:21:55.000",
        "show_on_site": 1,
        "SUB_SELLER_ID": "56547875",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "217 Liberty St, West Pittston, PA 18643",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "217 Liberty St West Pittston PA 18643",
        "ID": 18889
    },
    {
        "AGENT_ID": 56292995,
        "Client": "MATT MARTIN REAL ESTATE MANAGEMENT",
        "LoanNumber": 6000011571,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "5 SLAYTON CT",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Withdrawn",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-09-16 11:42:03.743",
        "ITEM_ID": 626047,
        "STATUS_ID": 8,
        "ASSET_MANAGER_ID": 55847794,
        "SELLER_ID": 56292995,
        "ASSET_NUMBER": "052-479571",
        "ADDRESS1": "5 Slayton Ct",
        "ADDRESS2": null,
        "CITY": "Pueblo",
        "STATE": "CO",
        "COUNTRY": "USA",
        "POSTAL_CODE": 81001,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1867",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 170000,
        "FINAL_RESERVE": 170000,
        "PHOTO_PATH": "http://www.hudhomestore.com/DocumentManager/Region-1/CO/052-479571/LISTING%20SITE//Glam_46928371.jpg",
        "SELLER_CONTACT_ID": 55847794,
        "AMS_SELLER_ID": "55454905",
        "AMS_AUCTION_NO": 69258,
        "CATEGORY_ID": 6,
        "COUNTY": "Pueblo County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "",
        "PROPERTY_OWNER": "Hud",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-12-21 14:15:49.000",
        "AUCTION_END_DATE": "2016-01-20 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-01-20 17:00:00.000",
        "AUCTION_DAY_COUNT": 30,
        "MIN_BID_AMT": 85000,
        "RESERVE_PRICE_AMT": 170000,
        "BID_INCREMENT_AMT": 1000,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 250,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "8F2B8291-AF9E-E511-8DFA-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "55847794",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 2,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-12-09 14:01:07.213",
        "LOAN_NUMBER": null,
        "Longitude": "-104.5784369",
        "Latitude": "38.2964150",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": null,
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": null,
        "ADDRESS_UNIT": null,
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": "2015-12-21 14:15:49.000",
        "show_on_site": 1,
        "SUB_SELLER_ID": "56547875",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "5 Slayton Ct, Pueblo, CO 81001",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "5 Slayton Ct Pueblo CO 81001",
        "ID": 18191
    },
    {
        "AGENT_ID": 56292995,
        "Client": "MATT MARTIN REAL ESTATE MANAGEMENT",
        "LoanNumber": 6000012879,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "1051 RS COUNTY ROAD 1495",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Available",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-09-16 11:41:08.050",
        "ITEM_ID": 627135,
        "STATUS_ID": 2,
        "ASSET_MANAGER_ID": 55847794,
        "SELLER_ID": 56292995,
        "ASSET_NUMBER": "491-941945",
        "ADDRESS1": "1051 Rs County Road 1495",
        "ADDRESS2": null,
        "CITY": "Emory",
        "STATE": "TX",
        "COUNTRY": "USA",
        "POSTAL_CODE": 75440,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "2169",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 205000,
        "FINAL_RESERVE": 205000,
        "PHOTO_PATH": "http://www.hudhomestore.com/DocumentManager/Region-4/TX/491-941945/LISTING%20SITE//Glam_47330198.jpg",
        "SELLER_CONTACT_ID": 55847794,
        "AMS_SELLER_ID": "55454905",
        "AMS_AUCTION_NO": 69914,
        "CATEGORY_ID": 44,
        "COUNTY": "Rains County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "",
        "PROPERTY_OWNER": "Hud",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-12-23 14:12:50.000",
        "AUCTION_END_DATE": "2016-01-22 19:00:00.000",
        "AUCTION_STOP_DATE": "2016-01-22 19:00:00.000",
        "AUCTION_DAY_COUNT": 30,
        "MIN_BID_AMT": 102000,
        "RESERVE_PRICE_AMT": 205000,
        "BID_INCREMENT_AMT": 1000,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 250,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "3CBDB169-B1A9-E511-8DFA-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "55847794",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-12-23 14:12:02.223",
        "LOAN_NUMBER": null,
        "Longitude": "-95.8436434",
        "Latitude": "32.8691708",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": null,
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": null,
        "ADDRESS_UNIT": null,
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56547875",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "1051 Rs County Road 1495, Emory, TX 75440",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "1051 Rs County Road 1495 Emory TX 75440",
        "ID": 14809
    },
    {
        "AGENT_ID": 56292995,
        "Client": "MATT MARTIN REAL ESTATE MANAGEMENT",
        "LoanNumber": 6000019475,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "52592 SOUTH EVANS RD",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Withdrawn",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-09-16 11:41:07.797",
        "ITEM_ID": 641917,
        "STATUS_ID": 8,
        "ASSET_MANAGER_ID": 252877977,
        "SELLER_ID": 56292995,
        "ASSET_NUMBER": "15324685",
        "ADDRESS1": "52592 South Evans Rd",
        "ADDRESS2": null,
        "CITY": "Independence",
        "STATE": "LA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 70443,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1296",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 22500,
        "FINAL_RESERVE": 0,
        "PHOTO_PATH": "/upload/auction_blank.jpg",
        "SELLER_CONTACT_ID": 56090982,
        "AMS_SELLER_ID": "REOSB",
        "AMS_AUCTION_NO": 80875,
        "CATEGORY_ID": 19,
        "COUNTY": "Tangipahoa Parish",
        "BUYER_BROKER_FEE": "",
        "ACRES": "1.8",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2016-08-05 14:05:43.000",
        "AUCTION_END_DATE": "2016-09-04 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-09-04 17:00:00.000",
        "AUCTION_DAY_COUNT": 29,
        "MIN_BID_AMT": 1000,
        "RESERVE_PRICE_AMT": 0,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 250,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "67DAF270-0746-E611-9902-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56090982",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2016-07-09 14:00:49.497",
        "LOAN_NUMBER": null,
        "Longitude": "-90.5484583",
        "Latitude": "30.6120399",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": null,
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": null,
        "ADDRESS_UNIT": null,
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": "2016-08-05 14:05:43.000",
        "show_on_site": 1,
        "SUB_SELLER_ID": "56550440",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "52592 South Evans Rd, Independence, LA 70443",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "52592 South Evans Rd Independence LA 70443",
        "ID": 14684
    },
    {
        "AGENT_ID": 56292995,
        "Client": "MATT MARTIN REAL ESTATE MANAGEMENT",
        "LoanNumber": 6000021806,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "6104 BURNHAM ROAD",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Withdrawn",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-09-16 11:41:08.680",
        "ITEM_ID": 631139,
        "STATUS_ID": 8,
        "ASSET_MANAGER_ID": 252608628,
        "SELLER_ID": 56292995,
        "ASSET_NUMBER": "541-780513",
        "ADDRESS1": "6104 Burnham Road",
        "ADDRESS2": null,
        "CITY": "Roanoke",
        "STATE": "VA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 24018,
        "BEDROOM_COUNT": 4,
        "BATH_COUNT": 2,
        "SQ_FT": "2414",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 180000,
        "FINAL_RESERVE": 180000,
        "PHOTO_PATH": "http://www.hudhomestore.com/DocumentManager/Region-4/VA/541-780513/LISTING%20SITE//FRONT_48527422.jpg",
        "SELLER_CONTACT_ID": 252608628,
        "AMS_SELLER_ID": "55698126",
        "AMS_AUCTION_NO": 73116,
        "CATEGORY_ID": 46,
        "COUNTY": "Roanoke County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "",
        "PROPERTY_OWNER": "Hud",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2016-04-08 14:18:37.000",
        "AUCTION_END_DATE": "2016-05-08 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-05-08 17:00:00.000",
        "AUCTION_DAY_COUNT": 30,
        "MIN_BID_AMT": 90000,
        "RESERVE_PRICE_AMT": 180000,
        "BID_INCREMENT_AMT": 1000,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 250,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "23FD223A-6CF0-E511-937F-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "252608628",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 2,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2016-03-22 15:25:39.130",
        "LOAN_NUMBER": null,
        "Longitude": "-80.0353440",
        "Latitude": "37.2325040",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": null,
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": null,
        "ADDRESS_UNIT": null,
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": "2016-04-08 14:18:37.000",
        "show_on_site": 1,
        "SUB_SELLER_ID": "56547875",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "6104 Burnham Road, Roanoke, VA 24018",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "6104 Burnham Road Roanoke VA 24018",
        "ID": 14963
    },
    {
        "AGENT_ID": 56292995,
        "Client": "MATT MARTIN REAL ESTATE MANAGEMENT",
        "LoanNumber": 6000029218,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "2403 35TH AVE.",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Available",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-09-16 11:41:36.003",
        "ITEM_ID": 643967,
        "STATUS_ID": 2,
        "ASSET_MANAGER_ID": 252624376,
        "SELLER_ID": 56292995,
        "ASSET_NUMBER": "281-343211",
        "ADDRESS1": "2403 35th Ave.",
        "ADDRESS2": null,
        "CITY": "Meridian",
        "STATE": "MS",
        "COUNTRY": "USA",
        "POSTAL_CODE": 39301,
        "BEDROOM_COUNT": 2,
        "BATH_COUNT": 1,
        "SQ_FT": "1413",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 16000,
        "FINAL_RESERVE": 16000,
        "PHOTO_PATH": "http://www.hudhomestore.com/DocumentManager/Region-3/MS/281-343211/LISTING%20SITE//FR_50938471.PNG",
        "SELLER_CONTACT_ID": 252624376,
        "AMS_SELLER_ID": "55699501",
        "AMS_AUCTION_NO": 83174,
        "CATEGORY_ID": 26,
        "COUNTY": "Lauderdale County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "",
        "PROPERTY_OWNER": "Hud",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2016-09-03 19:00:00.000",
        "AUCTION_END_DATE": "2016-10-03 19:00:00.000",
        "AUCTION_STOP_DATE": "2016-10-03 19:00:00.000",
        "AUCTION_DAY_COUNT": 30,
        "MIN_BID_AMT": 4000,
        "RESERVE_PRICE_AMT": 16000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 250,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "E3591504-E558-E611-8107-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "252624376",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2016-08-02 14:12:13.803",
        "LOAN_NUMBER": null,
        "Longitude": "-88.7134080",
        "Latitude": "32.3833200",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": null,
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": null,
        "ADDRESS_UNIT": null,
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": "2016-08-03 14:13:03.000",
        "show_on_site": 1,
        "SUB_SELLER_ID": "56547875",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "2403 35th Ave., Meridian, MS 39301",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "2403 35th Ave. Meridian MS 39301",
        "ID": 17133
    },
    {
        "AGENT_ID": 56292995,
        "Client": "MATT MARTIN REAL ESTATE MANAGEMENT",
        "LoanNumber": 6000031479,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "103 GROUSE ROAD",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Withdrawn",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-09-16 11:41:11.383",
        "ITEM_ID": 638776,
        "STATUS_ID": 8,
        "ASSET_MANAGER_ID": 252624376,
        "SELLER_ID": 56292995,
        "ASSET_NUMBER": "011-605611",
        "ADDRESS1": "103 Grouse Road",
        "ADDRESS2": null,
        "CITY": "Dothan",
        "STATE": "AL",
        "COUNTRY": "USA",
        "POSTAL_CODE": 36305,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "2027",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 30000,
        "FINAL_RESERVE": 30000,
        "PHOTO_PATH": "http://www.hudhomestore.com/DocumentManager/Region-1/AL/011-605611/LISTING%20SITE//front_50023570.JPG",
        "SELLER_CONTACT_ID": 252624376,
        "AMS_SELLER_ID": "55699501",
        "AMS_AUCTION_NO": 78373,
        "CATEGORY_ID": 2,
        "COUNTY": "Houston County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "",
        "PROPERTY_OWNER": "Hud",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2016-06-21 14:18:19.000",
        "AUCTION_END_DATE": "2016-07-21 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-07-21 17:00:00.000",
        "AUCTION_DAY_COUNT": 30,
        "MIN_BID_AMT": 12000,
        "RESERVE_PRICE_AMT": 30000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 250,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "4DF47EDA-742E-E611-809E-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "252624376",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2016-06-09 14:03:37.950",
        "LOAN_NUMBER": null,
        "Longitude": "-85.4673170",
        "Latitude": "31.1988340",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": null,
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": null,
        "ADDRESS_UNIT": null,
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": "2016-06-21 14:18:19.000",
        "show_on_site": 1,
        "SUB_SELLER_ID": "56547875",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "103 Grouse Road, Dothan, AL 36305",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "103 Grouse Road Dothan AL 36305",
        "ID": 15350
    },
    {
        "AGENT_ID": 56292995,
        "Client": "MATT MARTIN REAL ESTATE MANAGEMENT",
        "LoanNumber": 6000036215,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "5384 CEDAR PARK DRIVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Available",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-09-16 11:41:07.800",
        "ITEM_ID": 636564,
        "STATUS_ID": 2,
        "ASSET_MANAGER_ID": 252624376,
        "SELLER_ID": 56292995,
        "ASSET_NUMBER": "281-351906",
        "ADDRESS1": "5384 Cedar Park Drive",
        "ADDRESS2": null,
        "CITY": "Jackson",
        "STATE": "MS",
        "COUNTRY": "USA",
        "POSTAL_CODE": 39206,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1491",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 45000,
        "FINAL_RESERVE": 45000,
        "PHOTO_PATH": "http://www.hudhomestore.com/DocumentManager/Region-3/MS/281-351906/LISTING%20SITE//front_49848621.JPG",
        "SELLER_CONTACT_ID": 252624376,
        "AMS_SELLER_ID": "55699501",
        "AMS_AUCTION_NO": 77062,
        "CATEGORY_ID": 26,
        "COUNTY": "Hinds County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "",
        "PROPERTY_OWNER": "Hud",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2016-09-08 19:00:00.000",
        "AUCTION_END_DATE": "2016-10-08 19:00:00.000",
        "AUCTION_STOP_DATE": "2016-10-08 19:00:00.000",
        "AUCTION_DAY_COUNT": 30,
        "MIN_BID_AMT": 18000,
        "RESERVE_PRICE_AMT": 45000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 250,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "D5CB1C4A-631C-E611-A3FA-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "252624376",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2016-05-17 14:12:28.140",
        "LOAN_NUMBER": null,
        "Longitude": "-90.1534890",
        "Latitude": "32.3731122",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": null,
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": null,
        "ADDRESS_UNIT": null,
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": "2016-08-08 14:05:55.000",
        "show_on_site": 1,
        "SUB_SELLER_ID": "56547875",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "5384 Cedar Park Drive, Jackson, MS 39206",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "5384 Cedar Park Drive Jackson MS 39206",
        "ID": 14687
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 53083119,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "825 S CRESTVIEW",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Withdrawn",
        "CLOSING_STATUS_ID": "17",
        "closingStatusDesc": "Withdrawn",
        "LoadedIntoAT": "2015-11-03 10:47:55.280",
        "ITEM_ID": 627533,
        "STATUS_ID": 8,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "53083119",
        "ADDRESS1": "825 S Crestview",
        "ADDRESS2": "",
        "CITY": "Snowflake",
        "STATE": "AZ",
        "COUNTRY": "USA",
        "POSTAL_CODE": 85937,
        "BEDROOM_COUNT": 0,
        "BATH_COUNT": 0,
        "SQ_FT": "980",
        "PHOTO_OBTAINED": "N",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 0,
        "FINAL_RESERVE": 54000,
        "PHOTO_PATH": "/upload/auction_blank.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 70316,
        "CATEGORY_ID": 4,
        "COUNTY": "Navajo County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "CMS 2:1 WD",
        "AUCTION_TYPE": "RE",
        "AUCTION_START_DATE": "2016-01-11 00:00:00.000",
        "AUCTION_END_DATE": "2016-01-26 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-01-26 17:00:00.000",
        "AUCTION_DAY_COUNT": 15,
        "MIN_BID_AMT": 43000,
        "RESERVE_PRICE_AMT": 53820,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "634B7696-84B8-E511-8DFA-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "0",
        "FIREPLACE": "0",
        "GARAGE_SIZE": "0",
        "HEAT_TYPE": "0",
        "BASEMENT": "0",
        "HALF_BATH": 0,
        "SUBDIVISION": "0",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RE",
        "LOADED_DATE": "2016-01-11 10:58:51.417",
        "LOAN_NUMBER": null,
        "Longitude": null,
        "Latitude": null,
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2016-01-29 00:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2016-01-28 17:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56536059",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 2,
        "Address_Line": "825 S Crestview, Snowflake, AZ 85937",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "825 S Crestview Snowflake AZ 85937",
        "ID": 19169
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 4000047716,
        "AT_Status": "Removed",
        "AT_AuctionType": "Other",
        "AT_Address1": "449 MERRYWOOD DR",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:11.203",
        "ITEM_ID": 613740,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE091633C",
        "ADDRESS1": "449 Merrywood Dr",
        "ADDRESS2": "",
        "CITY": "Birmingham",
        "STATE": "AL",
        "COUNTRY": "USA",
        "POSTAL_CODE": 35214,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 1,
        "SQ_FT": "1722",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613740_162.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63860,
        "CATEGORY_ID": 2,
        "COUNTY": "Jefferson County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "45AEC221-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-18 09:29:12.993",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:39.880",
        "LOAN_NUMBER": null,
        "Longitude": "-86.9018840",
        "Latitude": "33.5818400",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-19 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-19 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "449 Merrywood Dr, Birmingham, AL 35214",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "449 Merrywood Dr Birmingham AL 35214",
        "ID": 15327
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000000255,
        "AT_Status": "Removed",
        "AT_AuctionType": "Other",
        "AT_Address1": "2531 N GARLAND",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.917",
        "ITEM_ID": 613749,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE108511A",
        "ADDRESS1": "2531 N Garland",
        "ADDRESS2": "",
        "CITY": "Wichita",
        "STATE": "KS",
        "COUNTRY": "USA",
        "POSTAL_CODE": 67204,
        "BEDROOM_COUNT": 2,
        "BATH_COUNT": 1,
        "SQ_FT": "1443",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613749_125.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63869,
        "CATEGORY_ID": 17,
        "COUNTY": "Sedgwick County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "078BD52E-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-18 09:28:15.567",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:56.763",
        "LOAN_NUMBER": null,
        "Longitude": "-97.3552490",
        "Latitude": "37.7288870",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-17 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-17 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "2531 N Garland, Wichita, KS 67204",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "2531 N Garland Wichita KS 67204",
        "ID": 14755
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000000792,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "155 DOE TRAIL",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:08.003",
        "ITEM_ID": 613674,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE106403A",
        "ADDRESS1": "155 Doe Trail",
        "ADDRESS2": "",
        "CITY": "Trafford",
        "STATE": "AL",
        "COUNTRY": "USA",
        "POSTAL_CODE": 35172,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1350",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 15,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613674_112.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63794,
        "CATEGORY_ID": 2,
        "COUNTY": "Blount County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "24,160 sqft",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-08-18 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "39DC88DD-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-08-19 08:09:33.513",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "2000",
        "FIREPLACE": "Y",
        "GARAGE_SIZE": null,
        "HEAT_TYPE": "Electric",
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": "Doe Trail Estates",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:02:42.730",
        "LOAN_NUMBER": null,
        "Longitude": "-86.6731868",
        "Latitude": "33.8154301",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-08-18 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-08-18 00:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "155 Doe Trail, Trafford, AL 35172",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "155 Doe Trail Trafford AL 35172",
        "ID": 14792
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000001074,
        "AT_Status": "Pre-Auction",
        "AT_AuctionType": "Pending",
        "AT_Address1": "3830 MILLER RD",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:38.670",
        "ITEM_ID": 613753,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE113886A",
        "ADDRESS1": "3830 Miller Rd",
        "ADDRESS2": "",
        "CITY": "Bancroft",
        "STATE": "MI",
        "COUNTRY": "USA",
        "POSTAL_CODE": 48414,
        "BEDROOM_COUNT": 0,
        "BATH_COUNT": 0,
        "SQ_FT": "0",
        "PHOTO_OBTAINED": "N",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload/auction_blank.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63873,
        "CATEGORY_ID": 23,
        "COUNTY": "Shiawassee County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "278BD52E-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-02 08:14:42.773",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:05:01.940",
        "LOAN_NUMBER": null,
        "Longitude": "-84.0862912",
        "Latitude": "42.8657650",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-01 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-01 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "3830 Miller Rd, Bancroft, MI 48414",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "3830 Miller Rd Bancroft MI 48414",
        "ID": 17260
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000002587,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Redemption Hold",
        "AT_Address1": "1333 AMERICANA DRIVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.910",
        "ITEM_ID": 613695,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE108411A",
        "ADDRESS1": "1333 Americana Drive",
        "ADDRESS2": "",
        "CITY": "Birmingham",
        "STATE": "AL",
        "COUNTRY": "USA",
        "POSTAL_CODE": 35215,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1442",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613695_133.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63815,
        "CATEGORY_ID": 2,
        "COUNTY": "Jefferson County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "100EF2F4-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-22 13:33:30.927",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:23.247",
        "LOAN_NUMBER": null,
        "Longitude": "-86.7206040",
        "Latitude": "33.6347820",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-21 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-21 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "1333 Americana Drive, Birmingham, AL 35215",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "1333 Americana Drive Birmingham AL 35215",
        "ID": 14753
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000002735,
        "AT_Status": "Sold",
        "AT_AuctionType": "Sold: Marketing Only",
        "AT_Address1": "524 ELM TREE CT",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.840",
        "ITEM_ID": 613697,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE106761A",
        "ADDRESS1": "524 Elm Tree Ct",
        "ADDRESS2": "",
        "CITY": "Cincinnati",
        "STATE": "OH",
        "COUNTRY": "USA",
        "POSTAL_CODE": 45244,
        "BEDROOM_COUNT": 4,
        "BATH_COUNT": 2,
        "SQ_FT": "1946",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613697_107.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63817,
        "CATEGORY_ID": 36,
        "COUNTY": "Clermont County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "200EF2F4-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-10 08:12:33.377",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1993",
        "FIREPLACE": "Y",
        "GARAGE_SIZE": "2",
        "HEAT_TYPE": "Gas",
        "BASEMENT": "Full",
        "HALF_BATH": 1,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:25.967",
        "LOAN_NUMBER": null,
        "Longitude": "-84.2986500",
        "Latitude": "39.1138350",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-09 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-09 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "524 Elm Tree Ct, Cincinnati, OH 45244",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "524 Elm Tree Ct Cincinnati OH 45244",
        "ID": 14715
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000003038,
        "AT_Status": "Sold",
        "AT_AuctionType": "2nd Chance",
        "AT_Address1": "3814 SOUTH TIBET WAY",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:11.283",
        "ITEM_ID": 613704,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE107016B",
        "ADDRESS1": "3814 South Tibet Way",
        "ADDRESS2": "",
        "CITY": "Aurora",
        "STATE": "CO",
        "COUNTRY": "USA",
        "POSTAL_CODE": 80013,
        "BEDROOM_COUNT": 5,
        "BATH_COUNT": 3,
        "SQ_FT": "2314",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613704_109.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63824,
        "CATEGORY_ID": 6,
        "COUNTY": "Arapahoe County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "C85C3001-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-25 08:24:18.210",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:40.033",
        "LOAN_NUMBER": null,
        "Longitude": "-104.7258130",
        "Latitude": "39.6472989",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-24 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-24 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "3814 South Tibet Way, Aurora, CO 80013",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "3814 South Tibet Way Aurora CO 80013",
        "ID": 18399
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000003398,
        "AT_Status": "Auction",
        "AT_AuctionType": "Marketing Only",
        "AT_Address1": "516 DAHLIA ST",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:01.110",
        "ITEM_ID": 613734,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE111060A",
        "ADDRESS1": "516 Dahlia St",
        "ADDRESS2": "",
        "CITY": "Fort Morgan",
        "STATE": "CO",
        "COUNTRY": "USA",
        "POSTAL_CODE": 80701,
        "BEDROOM_COUNT": 2,
        "BATH_COUNT": 1,
        "SQ_FT": "1008",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613734_52.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63854,
        "CATEGORY_ID": 6,
        "COUNTY": "Morgan County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "CMS Marketing ",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 18:00:00.000",
        "AUCTION_END_DATE": "2015-10-21 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "1DE7C91B-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-11-09 11:08:04.980",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1979",
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": "Gas",
        "BASEMENT": "Partial",
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:31.973",
        "LOAN_NUMBER": null,
        "Longitude": "-103.7861865",
        "Latitude": "40.2412310",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-10-02 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-11-25 00:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "516 Dahlia St, Fort Morgan, CO 80701",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "516 Dahlia St Fort Morgan CO 80701",
        "ID": 18114
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000003635,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "290 NC HIGHWAY 32 SOUTH",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:11.173",
        "ITEM_ID": 613672,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE106964A",
        "ADDRESS1": "290 Nc Highway 32 South",
        "ADDRESS2": "",
        "CITY": "Sunbury",
        "STATE": "NC",
        "COUNTRY": "USA",
        "POSTAL_CODE": 27979,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1188",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 15,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613672_6.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63792,
        "CATEGORY_ID": 28,
        "COUNTY": "Gates County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "29DC88DD-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-03 09:26:07.117",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:02:40.437",
        "LOAN_NUMBER": null,
        "Longitude": "-76.6059520",
        "Latitude": "36.4207520",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-02 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-02 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "290 Nc Highway 32 South, Sunbury, NC 27979",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "290 Nc Highway 32 South Sunbury NC 27979",
        "ID": 15323
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000004060,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Pending 2nd Chance",
        "AT_Address1": "120 SLACK STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:40.360",
        "ITEM_ID": 613741,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE101808C",
        "ADDRESS1": "120 Slack Street",
        "ADDRESS2": "",
        "CITY": "Sarepta",
        "STATE": "LA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 71071,
        "BEDROOM_COUNT": 4,
        "BATH_COUNT": 2,
        "SQ_FT": "1792",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0815/613741_157.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63861,
        "CATEGORY_ID": 19,
        "COUNTY": "Webster Parish",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-08-12 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-08-12 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "D2E1D728-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-08-12 17:00:09.117",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "2009",
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": "Newman's",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:46.097",
        "LOAN_NUMBER": null,
        "Longitude": "-93.4478801",
        "Latitude": "32.8984857",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-08-12 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-08-12 00:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "120 Slack Street, Sarepta, LA 71071",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "120 Slack Street Sarepta LA 71071",
        "ID": 17337
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000004359,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Pending 2nd Chance",
        "AT_Address1": "3332 REILY LN",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.923",
        "ITEM_ID": 613721,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE111335A",
        "ADDRESS1": "3332 Reily Ln",
        "ADDRESS2": "",
        "CITY": "Shreveport",
        "STATE": "LA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 71105,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1618",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0815/613721_78.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63841,
        "CATEGORY_ID": 19,
        "COUNTY": "Caddo Parish",
        "BUYER_BROKER_FEE": "",
        "ACRES": "15,000 sqft",
        "PROPERTY_OWNER": "CMS MO RETURNED",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-07-29 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "497E550D-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2016-01-29 08:31:03.660",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1955",
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": "Gas",
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": "Shreve Island",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:00.870",
        "LOAN_NUMBER": null,
        "Longitude": "-93.7106690",
        "Latitude": "32.4815340",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "Y",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2016-01-28 00:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2016-01-27 00:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "3332 Reily Ln, Shreveport, LA 71105",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "3332 Reily Ln Shreveport LA 71105",
        "ID": 14759
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000004438,
        "AT_Status": "Removed",
        "AT_AuctionType": "Other",
        "AT_Address1": "10876 EAST 96TH PLACE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:59.443",
        "ITEM_ID": 613735,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE107018B",
        "ADDRESS1": "10876 East 96th Place",
        "ADDRESS2": "",
        "CITY": "Commerce City",
        "STATE": "CO",
        "COUNTRY": "USA",
        "POSTAL_CODE": 80022,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1839",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 24,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0815/613735_180.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63855,
        "CATEGORY_ID": 6,
        "COUNTY": "Adams County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-08-20 19:00:00.000",
        "AUCTION_END_DATE": "2015-09-19 19:00:00.000",
        "AUCTION_STOP_DATE": "2015-09-19 19:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "25E7C91B-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-08-20 07:57:57.487",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "2000",
        "FIREPLACE": null,
        "GARAGE_SIZE": "1",
        "HEAT_TYPE": "Other",
        "BASEMENT": "None",
        "HALF_BATH": 1,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:33.460",
        "LOAN_NUMBER": null,
        "Longitude": "-104.8614930",
        "Latitude": "39.8711000",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-08-19 17:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-08-19 12:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "10876 East 96th Place, Commerce City, CO 80022",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "10876 East 96th Place Commerce City CO 80022",
        "ID": 18062
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000004888,
        "AT_Status": "Pre-Auction",
        "AT_AuctionType": "Pending",
        "AT_Address1": "19103 CHURCH ROAD",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:30.620",
        "ITEM_ID": 613746,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE113546A",
        "ADDRESS1": "19103 Church Road",
        "ADDRESS2": "",
        "CITY": "Petersburg",
        "STATE": "VA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 23803,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 1,
        "SQ_FT": "1025",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613746_2.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63866,
        "CATEGORY_ID": 46,
        "COUNTY": "Petersburg City County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "FAE1D728-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-25 08:24:33.577",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:52.613",
        "LOAN_NUMBER": null,
        "Longitude": "-77.5013780",
        "Latitude": "37.2566879",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-24 17:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-24 17:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "19103 Church Road, Petersburg, VA 23803",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "19103 Church Road Petersburg VA 23803",
        "ID": 16855
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000005368,
        "AT_Status": "Auction",
        "AT_AuctionType": "Marketing Only",
        "AT_Address1": "5859 OIL BARON DRIVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:03.710",
        "ITEM_ID": 613680,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE114013A",
        "ADDRESS1": "5859 Oil Baron Drive",
        "ADDRESS2": "",
        "CITY": "Peyton",
        "STATE": "CO",
        "COUNTRY": "USA",
        "POSTAL_CODE": 80831,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "2040",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 15,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0815/613680_72.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63800,
        "CATEGORY_ID": 6,
        "COUNTY": "El Paso County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "2.44 ac",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-10-01 19:00:00.000",
        "AUCTION_END_DATE": "2015-10-31 19:00:00.000",
        "AUCTION_STOP_DATE": "2015-10-31 19:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "01CBC2E3-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-10-01 07:50:44.817",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "2002",
        "FIREPLACE": "Y",
        "GARAGE_SIZE": "1",
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": "Southfork ",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:02:49.870",
        "LOAN_NUMBER": null,
        "Longitude": "-104.5353440",
        "Latitude": "38.9198620",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-09-30 17:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-09-30 12:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "5859 Oil Baron Drive, Peyton, CO 80831",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "5859 Oil Baron Drive Peyton CO 80831",
        "ID": 18190
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000005897,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "3105 CHEROKEE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-09-16 11:41:07.967",
        "ITEM_ID": 632657,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "6000011649",
        "ADDRESS1": "3105 Cherokee",
        "ADDRESS2": "",
        "CITY": "River Rouge",
        "STATE": "MI",
        "COUNTRY": "USA",
        "POSTAL_CODE": 48218,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 1,
        "SQ_FT": "1232",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 0,
        "FINAL_RESERVE": 9750,
        "PHOTO_PATH": "/upload.nfs/photos/0416/632657_130.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 74067,
        "CATEGORY_ID": 23,
        "COUNTY": "Wayne County",
        "BUYER_BROKER_FEE": "$300",
        "ACRES": "3000 sq ft",
        "PROPERTY_OWNER": "CMS 2:4",
        "AUCTION_TYPE": "RE",
        "AUCTION_START_DATE": "2016-04-05 17:00:00.000",
        "AUCTION_END_DATE": "2016-04-20 15:00:00.000",
        "AUCTION_STOP_DATE": "2016-04-20 15:00:00.000",
        "AUCTION_DAY_COUNT": 14,
        "MIN_BID_AMT": 7750,
        "RESERVE_PRICE_AMT": 9750,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "83C2E500-4FFB-E511-937F-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2016-04-26 00:30:01.000",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1923",
        "FIREPLACE": "0",
        "GARAGE_SIZE": "0",
        "HEAT_TYPE": "Gas",
        "BASEMENT": "None",
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RE",
        "LOADED_DATE": "2016-04-05 11:54:11.193",
        "LOAN_NUMBER": null,
        "Longitude": "-83.1308116",
        "Latitude": "42.2713892",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2016-04-26 00:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2016-04-25 17:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "216008319",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56536059",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "tony@michiganreo.net; robin.dumas@realtybid.com",
        "MLS_EXP_DATE": "2016-07-26 00:00:00.000",
        "PROGRAM_ID": 2,
        "Address_Line": "3105 Cherokee, River Rouge, MI 48218",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "3105 Cherokee River Rouge MI 48218",
        "ID": 14778
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000006403,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "2562 KENNEDY AVENUE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:07.363",
        "ITEM_ID": 613733,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE112156A",
        "ADDRESS1": "2562 Kennedy Avenue",
        "ADDRESS2": "",
        "CITY": "Grand Junction",
        "STATE": "CO",
        "COUNTRY": "USA",
        "POSTAL_CODE": 81501,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 1,
        "SQ_FT": "888",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "484.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613733_134.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63853,
        "CATEGORY_ID": 6,
        "COUNTY": "Mesa County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "6534sf",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-08-19 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "15E7C91B-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-08-20 07:56:58.477",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1956",
        "FIREPLACE": "Y",
        "GARAGE_SIZE": null,
        "HEAT_TYPE": "Gas",
        "BASEMENT": "None",
        "HALF_BATH": 0,
        "SUBDIVISION": "Houlton Re-Sub",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:30.830",
        "LOAN_NUMBER": null,
        "Longitude": "-108.5347000",
        "Latitude": "39.0799250",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-08-19 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-08-19 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "2562 Kennedy Avenue, Grand Junction, CO 81501",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "2562 Kennedy Avenue Grand Junction CO 81501",
        "ID": 18294
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000006427,
        "AT_Status": "Pre-Auction",
        "AT_AuctionType": "Pending",
        "AT_Address1": "117 BRADY LANE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.863",
        "ITEM_ID": 613685,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE107935A",
        "ADDRESS1": "117 Brady Lane",
        "ADDRESS2": "",
        "CITY": "Maple Hill",
        "STATE": "NC",
        "COUNTRY": "USA",
        "POSTAL_CODE": 28454,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1288",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613685_198.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63805,
        "CATEGORY_ID": 28,
        "COUNTY": "Pender County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "29CBC2E3-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-02 08:14:17.797",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:02:56.547",
        "LOAN_NUMBER": null,
        "Longitude": "-77.6445899",
        "Latitude": "34.6986520",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-01 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-01 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "117 Brady Lane, Maple Hill, NC 28454",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "117 Brady Lane Maple Hill NC 28454",
        "ID": 14730
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000006477,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "10039 WARD STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.853",
        "ITEM_ID": 613716,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE107831A",
        "ADDRESS1": "10039 Ward Street",
        "ADDRESS2": "",
        "CITY": "Detroit",
        "STATE": "MI",
        "COUNTRY": "USA",
        "POSTAL_CODE": 48227,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 1,
        "SQ_FT": "1370",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613716_81.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63836,
        "CATEGORY_ID": 23,
        "COUNTY": "Wayne County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "31B75C07-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-19 08:04:41.837",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 1,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:54.167",
        "LOAN_NUMBER": null,
        "Longitude": "-83.1732460",
        "Latitude": "42.3703640",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-18 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-18 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "10039 Ward Street, Detroit, MI 48227",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "10039 Ward Street Detroit MI 48227",
        "ID": 14723
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000006757,
        "AT_Status": "Sold",
        "AT_AuctionType": "Sold: Marketing Only",
        "AT_Address1": "2710 WEST ROXBURY STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.843",
        "ITEM_ID": 613688,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE111978A",
        "ADDRESS1": "2710 West Roxbury Street",
        "ADDRESS2": "",
        "CITY": "Springfield",
        "STATE": "MO",
        "COUNTRY": "USA",
        "POSTAL_CODE": 65807,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1188",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613688_9.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63808,
        "CATEGORY_ID": 25,
        "COUNTY": "Greene County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "324F84EC-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-11 08:29:35.557",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:07.440",
        "LOAN_NUMBER": null,
        "Longitude": "-93.3329630",
        "Latitude": "37.1495850",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-10 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-10 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "2710 West Roxbury Street, Springfield, MO 65807",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "2710 West Roxbury Street Springfield MO 65807",
        "ID": 14718
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000006993,
        "AT_Status": "Pre-Auction",
        "AT_AuctionType": "Pending",
        "AT_Address1": "1606 SPRINGDALE DRIVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:44.333",
        "ITEM_ID": 635067,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "6000006993   ",
        "ADDRESS1": "1606 Springdale Drive",
        "ADDRESS2": "",
        "CITY": "Clinton",
        "STATE": "IA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 52732,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 1,
        "SQ_FT": "1200",
        "PHOTO_OBTAINED": "N",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 0,
        "FINAL_RESERVE": 75500,
        "PHOTO_PATH": "/upload/auction_blank.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 76177,
        "CATEGORY_ID": 13,
        "COUNTY": "Clinton County",
        "BUYER_BROKER_FEE": "$300",
        "ACRES": "0",
        "PROPERTY_OWNER": "CMS 2:1",
        "AUCTION_TYPE": "RE",
        "AUCTION_START_DATE": "2016-05-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-05-18 15:00:00.000",
        "AUCTION_STOP_DATE": "2016-05-18 00:00:00.000",
        "AUCTION_DAY_COUNT": 15,
        "MIN_BID_AMT": 60000,
        "RESERVE_PRICE_AMT": 75480,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "20328E8D-5211-E611-9329-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2016-05-03 12:23:40.517",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "0",
        "FIREPLACE": "0",
        "GARAGE_SIZE": "0",
        "HEAT_TYPE": "0",
        "BASEMENT": "0",
        "HALF_BATH": 0,
        "SUBDIVISION": "0",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RE",
        "LOADED_DATE": "2016-05-03 12:14:55.300",
        "LOAN_NUMBER": null,
        "Longitude": null,
        "Latitude": null,
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2016-05-24 00:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2016-05-23 17:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56536059",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 2,
        "Address_Line": "1606 Springdale Drive, Clinton, IA 52732",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "1606 Springdale Drive Clinton IA 52732",
        "ID": 17512
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000006993,
        "AT_Status": "Pre-Auction",
        "AT_AuctionType": "Pending",
        "AT_Address1": "1606 SPRINGDALE DRIVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:44.333",
        "ITEM_ID": 635068,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "6000006993 ",
        "ADDRESS1": "1606 Springdale Drive",
        "ADDRESS2": "",
        "CITY": "Clinton",
        "STATE": "IA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 52732,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 1,
        "SQ_FT": "1200",
        "PHOTO_OBTAINED": "N",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 0,
        "FINAL_RESERVE": 75500,
        "PHOTO_PATH": "/upload/auction_blank.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 76178,
        "CATEGORY_ID": 13,
        "COUNTY": "Clinton County",
        "BUYER_BROKER_FEE": "$300",
        "ACRES": "0",
        "PROPERTY_OWNER": "CMS 2:1",
        "AUCTION_TYPE": "RE",
        "AUCTION_START_DATE": "2016-05-03 00:00:00.000",
        "AUCTION_END_DATE": "2016-05-18 00:00:00.000",
        "AUCTION_STOP_DATE": "2016-05-18 00:00:00.000",
        "AUCTION_DAY_COUNT": 15,
        "MIN_BID_AMT": 60000,
        "RESERVE_PRICE_AMT": 75480,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "B37B9B89-5A11-E611-9329-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2016-05-03 13:16:53.457",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "0",
        "FIREPLACE": "0",
        "GARAGE_SIZE": "0",
        "HEAT_TYPE": "0",
        "BASEMENT": "0",
        "HALF_BATH": 0,
        "SUBDIVISION": "0",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RE",
        "LOADED_DATE": "2016-05-03 13:12:04.653",
        "LOAN_NUMBER": null,
        "Longitude": null,
        "Latitude": null,
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2016-05-24 00:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2016-05-23 00:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56536059",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 2,
        "Address_Line": "1606 Springdale Drive, Clinton, IA 52732",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "1606 Springdale Drive Clinton IA 52732",
        "ID": 17512
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000007161,
        "AT_Status": "Sold",
        "AT_AuctionType": "2nd Chance",
        "AT_Address1": "3417 LITTLE JOHN COURT",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:06.607",
        "ITEM_ID": 613711,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE107151A",
        "ADDRESS1": "3417 Little John Court",
        "ADDRESS2": "",
        "CITY": "Nampa",
        "STATE": "ID",
        "COUNTRY": "USA",
        "POSTAL_CODE": 83687,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1082",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613711_42.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63831,
        "CATEGORY_ID": 14,
        "COUNTY": "Canyon County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "6,534 sqft",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-08-11 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "005D3001-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-08-12 08:05:35.560",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1996",
        "FIREPLACE": null,
        "GARAGE_SIZE": "2",
        "HEAT_TYPE": "Gas",
        "BASEMENT": "None",
        "HALF_BATH": 0,
        "SUBDIVISION": "Kings Road Estates",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:48.330",
        "LOAN_NUMBER": null,
        "Longitude": "-116.5281920",
        "Latitude": "43.5674310",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-08-11 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-08-11 00:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "3417 Little John Court, Nampa, ID 83687",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "3417 Little John Court Nampa ID 83687",
        "ID": 18273
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000007174,
        "AT_Status": "Auction",
        "AT_AuctionType": "Marketing Only",
        "AT_Address1": "18963 EAST HAWAII DRIVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:01.140",
        "ITEM_ID": 613681,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE109495A",
        "ADDRESS1": "18963 East Hawaii Drive",
        "ADDRESS2": "",
        "CITY": "Aurora",
        "STATE": "CO",
        "COUNTRY": "USA",
        "POSTAL_CODE": 80013,
        "BEDROOM_COUNT": 4,
        "BATH_COUNT": 2,
        "SQ_FT": "1788",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613681_14.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63801,
        "CATEGORY_ID": 6,
        "COUNTY": "Los Angeles County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "6,273 sf",
        "PROPERTY_OWNER": "CMS MO RETURNED",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-30 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "09CBC2E3-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2016-04-08 09:08:45.047",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1981",
        "FIREPLACE": null,
        "GARAGE_SIZE": "1",
        "HEAT_TYPE": "Gas",
        "BASEMENT": "None",
        "HALF_BATH": 0,
        "SUBDIVISION": "Stone Ridge Park",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:02:51.023",
        "LOAN_NUMBER": null,
        "Longitude": "-104.7668000",
        "Latitude": "39.6878590",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2016-04-14 17:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2016-04-13 00:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "18963 East Hawaii Drive, Aurora, CO 80013",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "18963 East Hawaii Drive Aurora CO 80013",
        "ID": 18115
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000007219,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "33501 COUNTY ROAD 669",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:12.490",
        "ITEM_ID": 613755,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE113807A",
        "ADDRESS1": "33501 County Road 669",
        "ADDRESS2": "",
        "CITY": "Lawton",
        "STATE": "MI",
        "COUNTRY": "USA",
        "POSTAL_CODE": 49065,
        "BEDROOM_COUNT": 0,
        "BATH_COUNT": 0,
        "SQ_FT": "0",
        "PHOTO_OBTAINED": "N",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload/auction_blank.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63875,
        "CATEGORY_ID": 23,
        "COUNTY": "Van Buren County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-07-10 19:00:00.000",
        "AUCTION_END_DATE": "2015-08-09 19:00:00.000",
        "AUCTION_STOP_DATE": "2015-08-09 19:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "378BD52E-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-10 08:04:22.710",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:05:04.783",
        "LOAN_NUMBER": null,
        "Longitude": "-85.8748024",
        "Latitude": "42.1383039",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-09 17:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-09 17:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "33501 County Road 669, Lawton, MI 49065",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "33501 County Road 669 Lawton MI 49065",
        "ID": 18431
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000007386,
        "AT_Status": "Sold",
        "AT_AuctionType": "Sold: Marketing Only",
        "AT_Address1": "5617 CROSS TIMBERS DRIVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.963",
        "ITEM_ID": 613724,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE112121A",
        "ADDRESS1": "5617 Cross Timbers Drive",
        "ADDRESS2": "",
        "CITY": "Shreveport",
        "STATE": "LA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 71129,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1330",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613724_181.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63844,
        "CATEGORY_ID": 19,
        "COUNTY": "Caddo Parish",
        "BUYER_BROKER_FEE": "",
        "ACRES": "1ac",
        "PROPERTY_OWNER": "CMS MO RETURNED",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-08-05 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "617E550D-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2016-01-29 08:32:28.607",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:04.863",
        "LOAN_NUMBER": null,
        "Longitude": "-93.8465690",
        "Latitude": "32.4378140",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2016-01-28 00:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2016-01-27 00:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "5617 Cross Timbers Drive, Shreveport, LA 71129",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "5617 Cross Timbers Drive Shreveport LA 71129",
        "ID": 14777
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000007798,
        "AT_Status": "Sold",
        "AT_AuctionType": "2nd Chance",
        "AT_Address1": "333 W PLEASANT STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:11.440",
        "ITEM_ID": 613717,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE106413A",
        "ADDRESS1": "333 W Pleasant Street",
        "ADDRESS2": "",
        "CITY": "Hillsboro",
        "STATE": "OH",
        "COUNTRY": "USA",
        "POSTAL_CODE": 45133,
        "BEDROOM_COUNT": 2,
        "BATH_COUNT": 2,
        "SQ_FT": "1128",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613717_21.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63837,
        "CATEGORY_ID": 36,
        "COUNTY": "Highland County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "39B75C07-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-26 08:03:44.917",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:55.520",
        "LOAN_NUMBER": null,
        "Longitude": "-83.6189730",
        "Latitude": "39.1989779",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-25 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-25 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "333 W Pleasant Street, Hillsboro, OH 45133",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "333 W Pleasant Street Hillsboro OH 45133",
        "ID": 18403
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000008136,
        "AT_Status": "Sold",
        "AT_AuctionType": "2nd Chance",
        "AT_Address1": "145 EAST 400 SOUTH",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:10.000",
        "ITEM_ID": 613703,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE096557C",
        "ADDRESS1": "145 East 400 South",
        "ADDRESS2": "",
        "CITY": "American Fork",
        "STATE": "UT",
        "COUNTRY": "USA",
        "POSTAL_CODE": 84003,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 1,
        "SQ_FT": "1065",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613703_190.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63823,
        "CATEGORY_ID": 45,
        "COUNTY": "Utah County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "C05C3001-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-15 09:05:23.463",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:38.917",
        "LOAN_NUMBER": null,
        "Longitude": "-111.7943640",
        "Latitude": "40.3691260",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-12 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-12 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "145 East 400 South, American Fork, UT 84003",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "145 East 400 South American Fork UT 84003",
        "ID": 18365
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000008826,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "5628 TIPTON ROAD",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:10.693",
        "ITEM_ID": 613693,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE107335A",
        "ADDRESS1": "5628 Tipton Road",
        "ADDRESS2": "",
        "CITY": "Monroe",
        "STATE": "NC",
        "COUNTRY": "USA",
        "POSTAL_CODE": 28112,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1300",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613693_25.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63813,
        "CATEGORY_ID": 28,
        "COUNTY": "Union County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "000EF2F4-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-25 08:24:25.433",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:20.703",
        "LOAN_NUMBER": null,
        "Longitude": "-80.6223380",
        "Latitude": "34.8650100",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-24 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-24 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "5628 Tipton Road, Monroe, NC 28112",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "5628 Tipton Road Monroe NC 28112",
        "ID": 18384
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000009232,
        "AT_Status": "Pre-Auction",
        "AT_AuctionType": "Pending",
        "AT_Address1": "2029 WARREN STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:24.610",
        "ITEM_ID": 613709,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE113488A",
        "ADDRESS1": "2029 Warren Street",
        "ADDRESS2": "",
        "CITY": "Petersburg",
        "STATE": "VA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 23805,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 1,
        "SQ_FT": "1448",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613709_52.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63829,
        "CATEGORY_ID": 46,
        "COUNTY": "Petersburg City County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "F05C3001-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-07 09:28:34.863",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:46.050",
        "LOAN_NUMBER": null,
        "Longitude": "-77.3769837",
        "Latitude": "37.2056704",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-14 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-14 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "2029 Warren Street, Petersburg, VA 23805",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "2029 Warren Street Petersburg VA 23805",
        "ID": 16500
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000009387,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "80493 E FRENCH TABLE RD",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.973",
        "ITEM_ID": 613718,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE111346A",
        "ADDRESS1": "80493 E French Table Rd",
        "ADDRESS2": "",
        "CITY": "Broken Bow",
        "STATE": "NE",
        "COUNTRY": "USA",
        "POSTAL_CODE": 68822,
        "BEDROOM_COUNT": 0,
        "BATH_COUNT": 2,
        "SQ_FT": "1860",
        "PHOTO_OBTAINED": "N",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload/auction_blank.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63838,
        "CATEGORY_ID": 32,
        "COUNTY": "Custer County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "41B75C07-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-20 08:18:49.430",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:56.733",
        "LOAN_NUMBER": null,
        "Longitude": "-99.4814480",
        "Latitude": "41.4909229",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-16 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-16 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "80493 E French Table Rd, Broken Bow, NE 68822",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "80493 E French Table Rd Broken Bow NE 68822",
        "ID": 14780
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000009424,
        "AT_Status": "Removed",
        "AT_AuctionType": "REO",
        "AT_Address1": "615 MARAVILLA AVENUE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:12.603",
        "ITEM_ID": 613748,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE106097A",
        "ADDRESS1": "615 Maravilla Avenue",
        "ADDRESS2": "",
        "CITY": "Sebring",
        "STATE": "FL",
        "COUNTRY": "USA",
        "POSTAL_CODE": 33875,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1902",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0815/613748_3.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63868,
        "CATEGORY_ID": 10,
        "COUNTY": "Highlands County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "0AE2D728-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-15 15:52:52.727",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "2000",
        "FIREPLACE": null,
        "GARAGE_SIZE": "2",
        "HEAT_TYPE": "Electric",
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:55.450",
        "LOAN_NUMBER": null,
        "Longitude": "-81.4719110",
        "Latitude": "27.4536140",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-14 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-14 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "615 Maravilla Avenue, Sebring, FL 33875",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "615 Maravilla Avenue Sebring FL 33875",
        "ID": 18434
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000010004,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Redemption Hold",
        "AT_Address1": "1092 MEADOWLARK DR",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:10.073",
        "ITEM_ID": 613738,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE107868A",
        "ADDRESS1": "1092 Meadowlark Dr",
        "ADDRESS2": "",
        "CITY": "Waterford",
        "STATE": "MI",
        "COUNTRY": "USA",
        "POSTAL_CODE": 48327,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 1,
        "SQ_FT": "1452",
        "PHOTO_OBTAINED": "N",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload/auction_blank.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63858,
        "CATEGORY_ID": 23,
        "COUNTY": "Oakland County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-06-16 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "35AEC221-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-17 08:01:36.467",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 1,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:37.163",
        "LOAN_NUMBER": null,
        "Longitude": "-83.4075310",
        "Latitude": "42.6247270",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-16 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-16 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "1092 Meadowlark Dr, Waterford, MI 48327",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "1092 Meadowlark Dr Waterford MI 48327",
        "ID": 18367
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000012333,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "865 GLENDALE AVENUE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:08.813",
        "ITEM_ID": 613720,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE106854B",
        "ADDRESS1": "865 Glendale Avenue",
        "ADDRESS2": "",
        "CITY": "Danville",
        "STATE": "VA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 24540,
        "BEDROOM_COUNT": 2,
        "BATH_COUNT": 1,
        "SQ_FT": "870",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "328.00000",
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613720_18.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63840,
        "CATEGORY_ID": 46,
        "COUNTY": "Danville City County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "9000sf",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-08-29 19:00:00.000",
        "AUCTION_END_DATE": "2015-09-28 19:00:00.000",
        "AUCTION_STOP_DATE": "2015-09-28 19:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "417E550D-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-08-31 08:10:42.827",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1947",
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": "Electric",
        "BASEMENT": "None",
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:59.593",
        "LOAN_NUMBER": null,
        "Longitude": "-79.4023057",
        "Latitude": "36.6222032",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-08-28 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-08-28 00:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "865 Glendale Avenue, Danville, VA 24540",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "865 Glendale Avenue Danville VA 24540",
        "ID": 18334
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000012694,
        "AT_Status": "Sold",
        "AT_AuctionType": "2nd Chance",
        "AT_Address1": "1686 KENDRICK FORD ROAD",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:10.117",
        "ITEM_ID": 613739,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE111906A",
        "ADDRESS1": "1686 Kendrick Ford Road",
        "ADDRESS2": "",
        "CITY": "Front Royal",
        "STATE": "VA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 22630,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "2191",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613739_49.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63859,
        "CATEGORY_ID": 46,
        "COUNTY": "Warren County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-06-16 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "3DAEC221-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-17 08:01:48.977",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 1,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:38.437",
        "LOAN_NUMBER": null,
        "Longitude": "-78.2283228",
        "Latitude": "38.9426750",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-16 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-16 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "1686 Kendrick Ford Road, Front Royal, VA 22630",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "1686 Kendrick Ford Road Front Royal VA 22630",
        "ID": 18368
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000013227,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "718 HOUSTON DRIVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.917",
        "ITEM_ID": 613722,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE097240B",
        "ADDRESS1": "718 Houston Drive",
        "ADDRESS2": "",
        "CITY": "Benton",
        "STATE": "AR",
        "COUNTRY": "USA",
        "POSTAL_CODE": 72015,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1679",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0815/613722_38.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63842,
        "CATEGORY_ID": 3,
        "COUNTY": "Grant County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "13,167 sf",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-07-29 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "517E550D-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-30 08:30:12.437",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1972",
        "FIREPLACE": null,
        "GARAGE_SIZE": "1",
        "HEAT_TYPE": "Gas",
        "BASEMENT": "None",
        "HALF_BATH": 0,
        "SUBDIVISION": "Melody Hills",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:02.257",
        "LOAN_NUMBER": null,
        "Longitude": "-92.5686090",
        "Latitude": "34.5692280",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-29 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-29 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "718 Houston Drive, Benton, AR 72015",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "718 Houston Drive Benton AR 72015",
        "ID": 14756
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000013235,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "7083 BRUSH LANE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:07.400",
        "ITEM_ID": 613678,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE103069B",
        "ADDRESS1": "7083 Brush Lane",
        "ADDRESS2": "",
        "CITY": "Alamosa",
        "STATE": "CO",
        "COUNTRY": "USA",
        "POSTAL_CODE": 81101,
        "BEDROOM_COUNT": 4,
        "BATH_COUNT": 2,
        "SQ_FT": "1698",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "1008.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 15,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0815/613678_169.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63798,
        "CATEGORY_ID": 6,
        "COUNTY": "Alamosa County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0.78 acres",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-08-20 19:00:00.000",
        "AUCTION_END_DATE": "2015-09-19 19:00:00.000",
        "AUCTION_STOP_DATE": "2015-09-19 19:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "59DC88DD-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-08-20 07:56:10.850",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1979",
        "FIREPLACE": null,
        "GARAGE_SIZE": "2",
        "HEAT_TYPE": "Gas",
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:02:47.453",
        "LOAN_NUMBER": null,
        "Longitude": "-105.8438378",
        "Latitude": "37.4730956",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-08-19 17:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-08-19 17:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "7083 Brush Lane, Alamosa, CO 81101",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "7083 Brush Lane Alamosa CO 81101",
        "ID": 18295
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000013736,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Redemption Hold",
        "AT_Address1": "20984 NEGAUNEE STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:00.983",
        "ITEM_ID": 613727,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE109155A",
        "ADDRESS1": "20984 Negaunee Street",
        "ADDRESS2": "",
        "CITY": "Southfield",
        "STATE": "MI",
        "COUNTRY": "USA",
        "POSTAL_CODE": 48033,
        "BEDROOM_COUNT": 2,
        "BATH_COUNT": 2,
        "SQ_FT": "1085",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "2004.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0815/613727_116.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63847,
        "CATEGORY_ID": 23,
        "COUNTY": "Oakland County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0.19 acres",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-07-22 19:00:00.000",
        "AUCTION_END_DATE": "2015-08-25 18:00:00.000",
        "AUCTION_STOP_DATE": "2015-08-21 19:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "6280C314-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-08-19 16:31:58.710",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "2001",
        "FIREPLACE": "Y",
        "GARAGE_SIZE": "2",
        "HEAT_TYPE": "Electric",
        "BASEMENT": "Full",
        "HALF_BATH": 0,
        "SUBDIVISION": "OAKLAND MEADOWS",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:17.760",
        "LOAN_NUMBER": null,
        "Longitude": "-83.2827093",
        "Latitude": "42.4620892",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-08-25 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-08-25 00:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "20984 Negaunee Street, Southfield, MI 48033",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "20984 Negaunee Street Southfield MI 48033",
        "ID": 18110
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000014048,
        "AT_Status": "Sold",
        "AT_AuctionType": "Sold: Marketing Only",
        "AT_Address1": "4321 WEST ALPINE STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:05.193",
        "ITEM_ID": 613708,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE106496A",
        "ADDRESS1": "4321 West Alpine Street",
        "ADDRESS2": "",
        "CITY": "Boise",
        "STATE": "ID",
        "COUNTRY": "USA",
        "POSTAL_CODE": 83705,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1437",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "1170.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613708_168.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63828,
        "CATEGORY_ID": 14,
        "COUNTY": "Ada County",
        "BUYER_BROKER_FEE": "",
        "ACRES": ".32",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-07-31 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "E85C3001-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-08-03 09:21:23.150",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1910",
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": "Electric",
        "BASEMENT": "None",
        "HALF_BATH": 0,
        "SUBDIVISION": "Scotts Sub",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:44.883",
        "LOAN_NUMBER": null,
        "Longitude": "-116.2357520",
        "Latitude": "43.6040629",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-31 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-31 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "4321 West Alpine Street, Boise, ID 83705",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "4321 West Alpine Street Boise ID 83705",
        "ID": 18231
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000015179,
        "AT_Status": "Pre-Auction",
        "AT_AuctionType": "Pending",
        "AT_Address1": "55 CHURCH STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Withdrawn",
        "CLOSING_STATUS_ID": "17",
        "closingStatusDesc": "Withdrawn",
        "LoadedIntoAT": "2015-09-16 11:41:47.720",
        "ITEM_ID": 613690,
        "STATUS_ID": 8,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE109207A",
        "ADDRESS1": "55 Church Street",
        "ADDRESS2": "",
        "CITY": "Carrollton",
        "STATE": "AL",
        "COUNTRY": "USA",
        "POSTAL_CODE": 35447,
        "BEDROOM_COUNT": 2,
        "BATH_COUNT": 1,
        "SQ_FT": "1112",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613690_165.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63810,
        "CATEGORY_ID": 2,
        "COUNTY": "Pickens County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "424F84EC-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:10.140",
        "LOAN_NUMBER": null,
        "Longitude": "-88.0921031",
        "Latitude": "33.2602532",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-16 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-16 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "55 Church Street, Carrollton, AL 35447",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "55 Church Street Carrollton AL 35447",
        "ID": 17648
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000015844,
        "AT_Status": "Auction",
        "AT_AuctionType": "1st",
        "AT_Address1": "236 RUTLEDGE DRIVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-09-16 11:41:41.647",
        "ITEM_ID": 633816,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "60000158440",
        "ADDRESS1": "236 Rutledge Drive",
        "ADDRESS2": "",
        "CITY": "Winder",
        "STATE": "GA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 30680,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1032",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 0,
        "FINAL_RESERVE": 66500,
        "PHOTO_PATH": "/upload.nfs/photos/0416/633816_97.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 74887,
        "CATEGORY_ID": 11,
        "COUNTY": "Barrow County",
        "BUYER_BROKER_FEE": "$300",
        "ACRES": "",
        "PROPERTY_OWNER": "CMS 2:3",
        "AUCTION_TYPE": "RE",
        "AUCTION_START_DATE": "2016-04-15 17:00:00.000",
        "AUCTION_END_DATE": "2016-05-02 15:00:00.000",
        "AUCTION_STOP_DATE": "2016-05-02 15:00:00.000",
        "AUCTION_DAY_COUNT": 16,
        "MIN_BID_AMT": 52500,
        "RESERVE_PRICE_AMT": 66220,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "A9957B8C-1403-E611-9329-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2016-05-06 00:30:01.000",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1990",
        "FIREPLACE": "0",
        "GARAGE_SIZE": "0",
        "HEAT_TYPE": null,
        "BASEMENT": "None",
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RE",
        "LOADED_DATE": "2016-04-15 09:15:53.277",
        "LOAN_NUMBER": null,
        "Longitude": "-83.7028030",
        "Latitude": "33.9961870",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2016-05-06 00:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2016-05-05 17:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "07599040 / 5655368",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56536059",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "joe@txreopro.com;Michael.kamp@brikollc.com; assignment@brikorealtyservices.com;robin.dumas@realtybid.com",
        "MLS_EXP_DATE": "2016-09-03 00:00:00.000",
        "PROGRAM_ID": 2,
        "Address_Line": "236 Rutledge Drive, Winder, GA 30680",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "236 Rutledge Drive Winder GA 30680",
        "ID": 17395
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000015844,
        "AT_Status": "Auction",
        "AT_AuctionType": "1st",
        "AT_Address1": "236 RUTLEDGE DRIVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "18",
        "closingStatusDesc": "In-Negotiations",
        "LoadedIntoAT": "2015-09-16 11:41:41.647",
        "ITEM_ID": 629702,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "60000158440",
        "ADDRESS1": "236 Rutledge Drive",
        "ADDRESS2": "",
        "CITY": "Winder",
        "STATE": "GA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 30680,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1032",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 0,
        "FINAL_RESERVE": 66500,
        "PHOTO_PATH": "/upload.nfs/photos/0316/629702_87.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 72488,
        "CATEGORY_ID": 11,
        "COUNTY": "Barrow County",
        "BUYER_BROKER_FEE": "$300",
        "ACRES": "0",
        "PROPERTY_OWNER": "CMS 2:1",
        "AUCTION_TYPE": "RE",
        "AUCTION_START_DATE": "2016-03-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-03-21 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-03-21 17:00:00.000",
        "AUCTION_DAY_COUNT": 18,
        "MIN_BID_AMT": 52500,
        "RESERVE_PRICE_AMT": 66220,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "F992FEB3-50E1-E511-AE85-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2016-03-25 00:30:08.000",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1990",
        "FIREPLACE": "0",
        "GARAGE_SIZE": "0",
        "HEAT_TYPE": "0",
        "BASEMENT": "0",
        "HALF_BATH": 0,
        "SUBDIVISION": "0",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RE",
        "LOADED_DATE": "2016-03-03 09:00:54.183",
        "LOAN_NUMBER": null,
        "Longitude": "-83.7028030",
        "Latitude": "33.9961870",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2016-03-25 00:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2016-03-24 17:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "07599040 / 5655368",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56536059",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "joe@txreopro.com;Michael.kamp@brikollc.com; robin.dumas@realtybid.com",
        "MLS_EXP_DATE": "2016-09-03 00:00:00.000",
        "PROGRAM_ID": 2,
        "Address_Line": "236 Rutledge Drive, Winder, GA 30680",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "236 Rutledge Drive Winder GA 30680",
        "ID": 17395
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000015844,
        "AT_Status": "Auction",
        "AT_AuctionType": "1st",
        "AT_Address1": "236 RUTLEDGE DRIVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "18",
        "closingStatusDesc": "In-Negotiations",
        "LoadedIntoAT": "2015-09-16 11:41:41.647",
        "ITEM_ID": 631508,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "60000158440",
        "ADDRESS1": "236 Rutledge Drive",
        "ADDRESS2": "",
        "CITY": "Winder",
        "STATE": "GA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 30680,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1032",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 0,
        "FINAL_RESERVE": 66500,
        "PHOTO_PATH": "/upload.nfs/photos/0316/631508_174.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 73256,
        "CATEGORY_ID": 11,
        "COUNTY": "Barrow County",
        "BUYER_BROKER_FEE": "$300",
        "ACRES": "",
        "PROPERTY_OWNER": "CMS 2:2",
        "AUCTION_TYPE": "RE",
        "AUCTION_START_DATE": "2016-03-25 17:00:00.000",
        "AUCTION_END_DATE": "2016-04-11 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-04-11 17:00:00.000",
        "AUCTION_DAY_COUNT": 17,
        "MIN_BID_AMT": 52500,
        "RESERVE_PRICE_AMT": 66220,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "C12CDAAA-94F2-E511-937F-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2016-04-15 00:30:12.000",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1990",
        "FIREPLACE": "0",
        "GARAGE_SIZE": "0",
        "HEAT_TYPE": null,
        "BASEMENT": "None",
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RE",
        "LOADED_DATE": "2016-03-25 09:20:06.247",
        "LOAN_NUMBER": null,
        "Longitude": "-83.7028030",
        "Latitude": "33.9961870",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2016-04-15 00:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2016-04-14 17:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "07599040 / 5655368",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56536059",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "joe@txreopro.com;Michael.kamp@brikollc.com; robin.dumas@realtybid.com",
        "MLS_EXP_DATE": "2016-09-03 00:00:00.000",
        "PROGRAM_ID": 2,
        "Address_Line": "236 Rutledge Drive, Winder, GA 30680",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "236 Rutledge Drive Winder GA 30680",
        "ID": 17395
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000015844,
        "AT_Status": "Auction",
        "AT_AuctionType": "1st",
        "AT_Address1": "236 RUTLEDGE DRIVE",
        "CLOSING_DATE": "2016-06-24 00:00:00.000",
        "SOLD_DATE": "2016-05-23 00:00:00.000",
        "STATUS_DESCRIPTION": "Sold",
        "CLOSING_STATUS_ID": "14",
        "closingStatusDesc": "Closed Collected",
        "LoadedIntoAT": "2015-09-16 11:41:41.647",
        "ITEM_ID": 635245,
        "STATUS_ID": 1,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "60000158440",
        "ADDRESS1": "236 Rutledge Drive",
        "ADDRESS2": "",
        "CITY": "Winder",
        "STATE": "GA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 30680,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1032",
        "PHOTO_OBTAINED": "N",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 0,
        "FINAL_RESERVE": 66500,
        "PHOTO_PATH": "/upload.nfs/xlr8/photos/0516/635245_6.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 76368,
        "CATEGORY_ID": 11,
        "COUNTY": "Barrow County",
        "BUYER_BROKER_FEE": "$300",
        "ACRES": "",
        "PROPERTY_OWNER": "CMS 2:4",
        "AUCTION_TYPE": "RE",
        "AUCTION_START_DATE": "2016-05-06 17:00:00.000",
        "AUCTION_END_DATE": "2016-05-23 15:00:00.000",
        "AUCTION_STOP_DATE": "2016-05-23 15:00:00.000",
        "AUCTION_DAY_COUNT": 16,
        "MIN_BID_AMT": 52500,
        "RESERVE_PRICE_AMT": 66220,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "8C4325F1-A613-E611-9329-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": null,
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1990",
        "FIREPLACE": "0",
        "GARAGE_SIZE": "0",
        "HEAT_TYPE": null,
        "BASEMENT": "None",
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RE",
        "LOADED_DATE": "2016-05-06 11:24:06.000",
        "LOAN_NUMBER": null,
        "Longitude": "-83.7028030",
        "Latitude": "33.9961870",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2016-05-27 00:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2016-05-26 17:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "07599040 / 5655368",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56536059",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "joe@txreopro.com;Michael.kamp@brikollc.com; assignment@brikorealtyservices.com;robin.dumas@realtybid.com",
        "MLS_EXP_DATE": "2016-09-03 00:00:00.000",
        "PROGRAM_ID": 2,
        "Address_Line": "236 Rutledge Drive, Winder, GA 30680",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "236 Rutledge Drive Winder GA 30680",
        "ID": 17395
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000016294,
        "AT_Status": "Sold",
        "AT_AuctionType": "2nd Chance",
        "AT_Address1": "7490 SOUTH 2920 WEST",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.877",
        "ITEM_ID": 613750,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE106583B",
        "ADDRESS1": "7490 South 2920 West",
        "ADDRESS2": "",
        "CITY": "West Jordan",
        "STATE": "UT",
        "COUNTRY": "USA",
        "POSTAL_CODE": 84084,
        "BEDROOM_COUNT": 4,
        "BATH_COUNT": 1,
        "SQ_FT": "1763",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613750_183.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63870,
        "CATEGORY_ID": 45,
        "COUNTY": "Salt Lake County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-07-07 19:00:00.000",
        "AUCTION_END_DATE": "2015-08-06 19:00:00.000",
        "AUCTION_STOP_DATE": "2015-08-06 19:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "0F8BD52E-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-07 08:50:14.927",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 1,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:58.140",
        "LOAN_NUMBER": null,
        "Longitude": "-111.9638980",
        "Latitude": "40.6149490",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-06 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-06 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "7490 South 2920 West, West Jordan, UT 84084",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "7490 South 2920 West West Jordan UT 84084",
        "ID": 14736
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000016438,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "48191 RICE RD",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.850",
        "ITEM_ID": 613714,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE106065A",
        "ADDRESS1": "48191 Rice Rd",
        "ADDRESS2": "",
        "CITY": "Amherst",
        "STATE": "OH",
        "COUNTRY": "USA",
        "POSTAL_CODE": 44001,
        "BEDROOM_COUNT": 4,
        "BATH_COUNT": 2,
        "SQ_FT": "1936",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613714_30.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63834,
        "CATEGORY_ID": 36,
        "COUNTY": "Lorain County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "21B75C07-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-19 08:04:13.343",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:51.883",
        "LOAN_NUMBER": null,
        "Longitude": "-82.2580730",
        "Latitude": "41.3794240",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-17 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-17 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "48191 Rice Rd, Amherst, OH 44001",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "48191 Rice Rd Amherst OH 44001",
        "ID": 14721
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000016515,
        "AT_Status": "Pre-Auction",
        "AT_AuctionType": "Pending",
        "AT_Address1": "120 LONE OAK RD",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.860",
        "ITEM_ID": 613728,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE108130A",
        "ADDRESS1": "120 Lone Oak Rd",
        "ADDRESS2": "",
        "CITY": "Partlow",
        "STATE": "VA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 22534,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1152",
        "PHOTO_OBTAINED": "N",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload/auction_blank.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63848,
        "CATEGORY_ID": 46,
        "COUNTY": "Spotsylvania County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "6A80C314-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-24 08:04:31.503",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:18.903",
        "LOAN_NUMBER": null,
        "Longitude": "-77.6547150",
        "Latitude": "38.1193150",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-23 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-23 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "120 Lone Oak Rd, Partlow, VA 22534",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "120 Lone Oak Rd Partlow VA 22534",
        "ID": 14727
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000017326,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "10220 AMELIA SPRINGS ROAD",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.860",
        "ITEM_ID": 613730,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE103211B",
        "ADDRESS1": "10220 Amelia Springs Road",
        "ADDRESS2": "",
        "CITY": "Jetersville",
        "STATE": "VA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 23083,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1104",
        "PHOTO_OBTAINED": "N",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload/auction_blank.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63850,
        "CATEGORY_ID": 46,
        "COUNTY": "Amelia County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "FDE6C91B-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-01 08:34:37.070",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:26.780",
        "LOAN_NUMBER": null,
        "Longitude": "-78.1145901",
        "Latitude": "37.3678412",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-30 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-30 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "10220 Amelia Springs Road, Jetersville, VA 23083",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "10220 Amelia Springs Road Jetersville VA 23083",
        "ID": 14728
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000018172,
        "AT_Status": "Pre-Auction",
        "AT_AuctionType": "Pending",
        "AT_Address1": "54 OXFORD PLACE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:35.250",
        "ITEM_ID": 613737,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE113083A",
        "ADDRESS1": "54 Oxford Place",
        "ADDRESS2": "",
        "CITY": "Troy",
        "STATE": "MO",
        "COUNTRY": "USA",
        "POSTAL_CODE": 63379,
        "BEDROOM_COUNT": 0,
        "BATH_COUNT": 0,
        "SQ_FT": "2356",
        "PHOTO_OBTAINED": "N",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload/auction_blank.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63857,
        "CATEGORY_ID": 25,
        "COUNTY": "Lincoln County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "2DAEC221-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-15 09:05:31.420",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:36.043",
        "LOAN_NUMBER": null,
        "Longitude": "-91.0195830",
        "Latitude": "38.9688090",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-12 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-12 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "54 Oxford Place, Troy, MO 63379",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "54 Oxford Place Troy MO 63379",
        "ID": 17096
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000018349,
        "AT_Status": "Auction",
        "AT_AuctionType": "Marketing Only",
        "AT_Address1": "59858 EAST COUNTY ROAD 30",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:02.297",
        "ITEM_ID": 613745,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE097817B",
        "ADDRESS1": "59858 East County Road 30",
        "ADDRESS2": "",
        "CITY": "Strasburg",
        "STATE": "CO",
        "COUNTRY": "USA",
        "POSTAL_CODE": 80136,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1559",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613745_27.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63865,
        "CATEGORY_ID": 6,
        "COUNTY": "Arapahoe County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "35.4 ac",
        "PROPERTY_OWNER": "CMS MO RETURNED",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-10-28 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "F2E1D728-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2016-06-02 09:37:03.273",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1998",
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": "Gas",
        "BASEMENT": "Full",
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:51.200",
        "LOAN_NUMBER": null,
        "Longitude": "-104.2884900",
        "Latitude": "39.6336010",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2016-06-02 00:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2016-06-01 00:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "59858 East County Road 30, Strasburg, CO 80136",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "59858 East County Road 30 Strasburg CO 80136",
        "ID": 18147
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000018874,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "7211 JENNIFER LN.",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:12.680",
        "ITEM_ID": 613742,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE102519B",
        "ADDRESS1": "7211 Jennifer Ln.",
        "ADDRESS2": "",
        "CITY": "Princeton",
        "STATE": "LA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 71067,
        "BEDROOM_COUNT": 0,
        "BATH_COUNT": 0,
        "SQ_FT": "1344",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 15,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613742_36.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63862,
        "CATEGORY_ID": 19,
        "COUNTY": "Bossier Parish",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "DAE1D728-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-16 09:30:20.197",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:47.367",
        "LOAN_NUMBER": null,
        "Longitude": "-93.5215120",
        "Latitude": "32.5738950",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-15 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-15 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "7211 Jennifer Ln., Princeton, LA 71067",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "7211 Jennifer Ln. Princeton LA 71067",
        "ID": 18436
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000018894,
        "AT_Status": "Closing",
        "AT_AuctionType": "Contract to TC",
        "AT_Address1": "12325 RICHARDSON LANE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:02.333",
        "ITEM_ID": 613725,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE111199A",
        "ADDRESS1": "12325 Richardson Lane",
        "ADDRESS2": "",
        "CITY": "Peyton",
        "STATE": "CO",
        "COUNTRY": "USA",
        "POSTAL_CODE": 80831,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1456",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0815/613725_11.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63845,
        "CATEGORY_ID": 6,
        "COUNTY": "El Paso County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "5.22 ac",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-09-09 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "5280C314-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-08-20 12:21:58.883",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "2000",
        "FIREPLACE": null,
        "GARAGE_SIZE": "1",
        "HEAT_TYPE": "Other",
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:12.413",
        "LOAN_NUMBER": null,
        "Longitude": "-104.5981130",
        "Latitude": "38.9087020",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-09-09 17:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-09-09 12:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "12325 Richardson Lane, Peyton, CO 80831",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "12325 Richardson Lane Peyton CO 80831",
        "ID": 18148
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000018939,
        "AT_Status": "Auction",
        "AT_AuctionType": "Marketing Only",
        "AT_Address1": "2315 MANTOOTH DRIVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:06.743",
        "ITEM_ID": 613679,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE109567A",
        "ADDRESS1": "2315 Mantooth Drive",
        "ADDRESS2": "",
        "CITY": "Cabot",
        "STATE": "AR",
        "COUNTRY": "USA",
        "POSTAL_CODE": 72023,
        "BEDROOM_COUNT": 0,
        "BATH_COUNT": 0,
        "SQ_FT": "2240",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 15,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613679_19.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63799,
        "CATEGORY_ID": 3,
        "COUNTY": "Lonoke County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "1.42 acres",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-08-12 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "61DC88DD-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-08-06 15:43:40.140",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1999",
        "FIREPLACE": "Y",
        "GARAGE_SIZE": null,
        "HEAT_TYPE": "Gas",
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:02:48.590",
        "LOAN_NUMBER": null,
        "Longitude": "-92.1264220",
        "Latitude": "35.0057690",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-08-12 17:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-08-12 00:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "2315 Mantooth Drive, Cabot, AR 72023",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "2315 Mantooth Drive Cabot AR 72023",
        "ID": 18277
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000019203,
        "AT_Status": "Pre-Auction",
        "AT_AuctionType": "Pending",
        "AT_Address1": "1004 STANDIFER LANE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:15.893",
        "ITEM_ID": 613760,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE106600A",
        "ADDRESS1": "1004 Standifer Lane",
        "ADDRESS2": "",
        "CITY": "Monroe",
        "STATE": "LA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 71202,
        "BEDROOM_COUNT": 0,
        "BATH_COUNT": 0,
        "SQ_FT": "0",
        "PHOTO_OBTAINED": "N",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload/auction_blank.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63880,
        "CATEGORY_ID": 19,
        "COUNTY": "Ouachita Parish",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "4159F034-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-08 10:27:36.560",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:05:11.323",
        "LOAN_NUMBER": null,
        "Longitude": "-92.1010950",
        "Latitude": "32.4637570",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-08 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-08 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "1004 Standifer Lane, Monroe, LA 71202",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "1004 Standifer Lane Monroe LA 71202",
        "ID": 15820
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000019227,
        "AT_Status": "Closing",
        "AT_AuctionType": "Contract to TC",
        "AT_Address1": "4318 DELRYAN DR.",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.870",
        "ITEM_ID": 613757,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE105857A",
        "ADDRESS1": "4318 Delryan Dr.",
        "ADDRESS2": "",
        "CITY": "Cincinnati",
        "STATE": "OH",
        "COUNTRY": "USA",
        "POSTAL_CODE": 45238,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1532",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": null,
        "HOA_DUES_DUE": null,
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613757_194.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63877,
        "CATEGORY_ID": 36,
        "COUNTY": "Hamilton County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": null,
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "2959F034-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-03 09:26:28.287",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:05:07.347",
        "LOAN_NUMBER": null,
        "Longitude": "-84.5950610",
        "Latitude": "39.0895050",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-02 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-02 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": null,
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": null,
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "4318 Delryan Dr., Cincinnati, OH 45238",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "4318 Delryan Dr. Cincinnati OH 45238",
        "ID": 14734
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000019417,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "624 CABIN CREEK DRIVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:08.030",
        "ITEM_ID": 613759,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE111075A",
        "ADDRESS1": "624 Cabin Creek Drive",
        "ADDRESS2": "",
        "CITY": "Hopewell",
        "STATE": "VA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 23860,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 1,
        "SQ_FT": "1392",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0815/613759_17.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63879,
        "CATEGORY_ID": 46,
        "COUNTY": "Hopewell City County",
        "BUYER_BROKER_FEE": "",
        "ACRES": ".54",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-08-27 18:00:00.000",
        "AUCTION_STOP_DATE": "2015-08-27 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "3959F034-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-08-28 08:06:06.840",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1980",
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": "Electric",
        "BASEMENT": "None",
        "HALF_BATH": 1,
        "SUBDIVISION": "Colonial Manor Section 4",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:05:10.180",
        "LOAN_NUMBER": null,
        "Longitude": "-77.3266870",
        "Latitude": "37.2716469",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-08-27 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-08-27 00:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "624 Cabin Creek Drive, Hopewell, VA 23860",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "624 Cabin Creek Drive Hopewell VA 23860",
        "ID": 14802
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000019456,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Redemption Hold",
        "AT_Address1": "833 BROADWAY AVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.843",
        "ITEM_ID": 613702,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE106876A",
        "ADDRESS1": "833 Broadway Ave",
        "ADDRESS2": "",
        "CITY": "Owosso",
        "STATE": "MI",
        "COUNTRY": "USA",
        "POSTAL_CODE": 48867,
        "BEDROOM_COUNT": 2,
        "BATH_COUNT": 1,
        "SQ_FT": "1080",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613702_176.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63822,
        "CATEGORY_ID": 23,
        "COUNTY": "Manistee County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "88EBF2FA-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-11 08:29:46.257",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:32.373",
        "LOAN_NUMBER": null,
        "Longitude": "-84.1634407",
        "Latitude": "42.9871841",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-10 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-10 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "833 Broadway Ave, Owosso, MI 48867",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "833 Broadway Ave Owosso MI 48867",
        "ID": 14717
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000020352,
        "AT_Status": "Removed",
        "AT_AuctionType": "Other",
        "AT_Address1": "7013 ALBA ROAD",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:09.590",
        "ITEM_ID": 613676,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE112036A",
        "ADDRESS1": "7013 Alba Road",
        "ADDRESS2": "",
        "CITY": "Gaylord",
        "STATE": "MI",
        "COUNTRY": "USA",
        "POSTAL_CODE": 49735,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1352",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 15,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613676_155.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63796,
        "CATEGORY_ID": 23,
        "COUNTY": "Otsego County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "49DC88DD-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-12 08:30:23.857",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:02:45.150",
        "LOAN_NUMBER": null,
        "Longitude": "-84.8212061",
        "Latitude": "44.9887882",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-11 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-11 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "7013 Alba Road, Gaylord, MI 49735",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "7013 Alba Road Gaylord MI 49735",
        "ID": 15123
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000020359,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "28 ALLI PAIGE DRIVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:10.850",
        "ITEM_ID": 613706,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE104817B",
        "ADDRESS1": "28 Alli Paige Drive",
        "ADDRESS2": "",
        "CITY": "Vilonia",
        "STATE": "AR",
        "COUNTRY": "USA",
        "POSTAL_CODE": 72173,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1704",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613706_42.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63826,
        "CATEGORY_ID": 3,
        "COUNTY": "De Soto County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "D85C3001-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-01 08:36:01.137",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:42.420",
        "LOAN_NUMBER": null,
        "Longitude": "-92.1806730",
        "Latitude": "35.0875530",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-30 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-30 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "28 Alli Paige Drive, Vilonia, AR 72173",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "28 Alli Paige Drive Vilonia AR 72173",
        "ID": 18388
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000020384,
        "AT_Status": "Sold",
        "AT_AuctionType": "2nd Chance",
        "AT_Address1": "117 OLD CAMP RD",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.787",
        "ITEM_ID": 613683,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE107956A",
        "ADDRESS1": "117 Old Camp Rd",
        "ADDRESS2": "",
        "CITY": "Meridianville",
        "STATE": "AL",
        "COUNTRY": "USA",
        "POSTAL_CODE": 35759,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1830",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613683_11.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63803,
        "CATEGORY_ID": 2,
        "COUNTY": "Madison County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "19CBC2E3-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-22 13:32:50.387",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:02:53.690",
        "LOAN_NUMBER": null,
        "Longitude": "-86.5903410",
        "Latitude": "34.8490370",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-21 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-21 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "117 Old Camp Rd, Meridianville, AL 35759",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "117 Old Camp Rd Meridianville AL 35759",
        "ID": 14676
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000020425,
        "AT_Status": "Auction",
        "AT_AuctionType": "Marketing Only",
        "AT_Address1": "2258 GLADE ST",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:08.153",
        "ITEM_ID": 613751,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE110797A",
        "ADDRESS1": "2258 Glade St",
        "ADDRESS2": "",
        "CITY": "Burton",
        "STATE": "MI",
        "COUNTRY": "USA",
        "POSTAL_CODE": 48509,
        "BEDROOM_COUNT": 5,
        "BATH_COUNT": 1,
        "SQ_FT": "1488",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "1084.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613751_9.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63871,
        "CATEGORY_ID": 23,
        "COUNTY": "Genesee County",
        "BUYER_BROKER_FEE": "",
        "ACRES": ".24 acres",
        "PROPERTY_OWNER": "CMS MO RETURNED",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-09-23 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "178BD52E-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2016-05-20 11:23:42.677",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1956",
        "FIREPLACE": null,
        "GARAGE_SIZE": "2",
        "HEAT_TYPE": null,
        "BASEMENT": "None",
        "HALF_BATH": 1,
        "SUBDIVISION": "Centerwood",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:59.667",
        "LOAN_NUMBER": null,
        "Longitude": "-83.6328360",
        "Latitude": "43.0402790",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2016-05-19 00:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2016-05-18 00:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "2258 Glade St, Burton, MI 48509",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "2258 Glade St Burton MI 48509",
        "ID": 14843
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000020534,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "128 VALLEY STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:10.657",
        "ITEM_ID": 613705,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE108244A",
        "ADDRESS1": "128 Valley Street",
        "ADDRESS2": "",
        "CITY": "Pulaski",
        "STATE": "VA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 24301,
        "BEDROOM_COUNT": 4,
        "BATH_COUNT": 2,
        "SQ_FT": "1929",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613705_146.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63825,
        "CATEGORY_ID": 46,
        "COUNTY": "Lee County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "D05C3001-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-25 08:24:10.957",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:41.280",
        "LOAN_NUMBER": null,
        "Longitude": "-80.7851270",
        "Latitude": "37.0439080",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-24 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-24 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "128 Valley Street, Pulaski, VA 24301",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "128 Valley Street Pulaski VA 24301",
        "ID": 18383
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000021629,
        "AT_Status": "Pre-Auction",
        "AT_AuctionType": "Pending",
        "AT_Address1": "3219 HEBERT ST",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:35.290",
        "ITEM_ID": 613689,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE112087A",
        "ADDRESS1": "3219 Hebert St",
        "ADDRESS2": "",
        "CITY": "Saint Louis",
        "STATE": "MO",
        "COUNTRY": "USA",
        "POSTAL_CODE": 63107,
        "BEDROOM_COUNT": 4,
        "BATH_COUNT": 2,
        "SQ_FT": "1894",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613689_23.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63809,
        "CATEGORY_ID": 25,
        "COUNTY": "Saint Louis City County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "3A4F84EC-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-10 10:18:55.713",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 1,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:08.810",
        "LOAN_NUMBER": null,
        "Longitude": "-90.2165920",
        "Latitude": "38.6589360",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-11 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-11 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "3219 Hebert St, Saint Louis, MO 63107",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "3219 Hebert St Saint Louis MO 63107",
        "ID": 17098
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000022232,
        "AT_Status": "Sold",
        "AT_AuctionType": "Sold: Marketing Only",
        "AT_Address1": "104 21ST AVENUE NW",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.847",
        "ITEM_ID": 613691,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE108709A",
        "ADDRESS1": "104 21st Avenue Nw",
        "ADDRESS2": "",
        "CITY": "Birmingham",
        "STATE": "AL",
        "COUNTRY": "USA",
        "POSTAL_CODE": 35215,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1166",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613691_181.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63811,
        "CATEGORY_ID": 2,
        "COUNTY": "Jefferson County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-06-16 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "F00DF2F4-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-17 08:01:10.897",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1935",
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": "Gas",
        "BASEMENT": "None",
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:18.377",
        "LOAN_NUMBER": null,
        "Longitude": "-86.6860810",
        "Latitude": "33.6405580",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-16 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-16 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "104 21st Avenue Nw, Birmingham, AL 35215",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "104 21st Avenue Nw Birmingham AL 35215",
        "ID": 14719
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000022540,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "707 WEST PRINCESS STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:05.230",
        "ITEM_ID": 613726,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE106947A",
        "ADDRESS1": "707 West Princess Street",
        "ADDRESS2": "",
        "CITY": "York",
        "STATE": "PA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 17404,
        "BEDROOM_COUNT": 5,
        "BATH_COUNT": 1,
        "SQ_FT": "1785",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "2000.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 3,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613726_117.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63846,
        "CATEGORY_ID": 39,
        "COUNTY": "York County",
        "BUYER_BROKER_FEE": "",
        "ACRES": ".03 acres",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-08-03 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "5A80C314-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-08-04 08:16:55.080",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1900",
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": "Other",
        "BASEMENT": "Full",
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:13.730",
        "LOAN_NUMBER": null,
        "Longitude": "-76.7442660",
        "Latitude": "39.9544260",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-08-03 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-08-03 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "707 West Princess Street, York, PA 17404",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "707 West Princess Street York PA 17404",
        "ID": 18232
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000022714,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Redemption Hold",
        "AT_Address1": "347 N. PARKER ST.",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": null,
        "closingStatusDesc": null,
        "LoadedIntoAT": "2015-09-16 11:41:17.007",
        "ITEM_ID": 632965,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "6000010645",
        "ADDRESS1": "347 N. Parker St.",
        "ADDRESS2": "",
        "CITY": "North Branch",
        "STATE": "MI",
        "COUNTRY": "USA",
        "POSTAL_CODE": 48461,
        "BEDROOM_COUNT": 0,
        "BATH_COUNT": 0,
        "SQ_FT": null,
        "PHOTO_OBTAINED": "N",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 0,
        "FINAL_RESERVE": 88000,
        "PHOTO_PATH": "/upload/auction_blank.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 74290,
        "CATEGORY_ID": 23,
        "COUNTY": "Lapeer County",
        "BUYER_BROKER_FEE": "$300",
        "ACRES": "10.14 acres",
        "PROPERTY_OWNER": "CMS 2:2 ROR",
        "AUCTION_TYPE": "RE",
        "AUCTION_START_DATE": "2016-04-06 17:00:00.000",
        "AUCTION_END_DATE": "2016-04-21 15:00:00.000",
        "AUCTION_STOP_DATE": "2016-04-21 15:00:00.000",
        "AUCTION_DAY_COUNT": 14,
        "MIN_BID_AMT": 70000,
        "RESERVE_PRICE_AMT": 87750,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "6FD6323A-13FC-E511-937F-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2016-04-27 00:30:01.000",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": "0",
        "GARAGE_SIZE": "0",
        "HEAT_TYPE": null,
        "BASEMENT": "None",
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": null,
        "EVENT_TYPE": "RE",
        "LOADED_DATE": "2016-04-06 11:18:45.290",
        "LOAN_NUMBER": null,
        "Longitude": "-83.2905050",
        "Latitude": "43.2052463",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2016-04-27 00:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2016-04-26 17:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "216029613",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56536059",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "joe@txreopro.com;Michael.kamp@brikollc.com; robin.dumas@realtybid.com",
        "MLS_EXP_DATE": "2016-09-29 00:00:00.000",
        "PROGRAM_ID": 2,
        "Address_Line": "347 N. Parker St., North Branch, MI 48461",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "347 N. Parker St. North Branch MI 48461",
        "ID": 15918
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000023373,
        "AT_Status": "Closing",
        "AT_AuctionType": "Contract to TC",
        "AT_Address1": "535 E 52ND PL N",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:58.857",
        "ITEM_ID": 613701,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE108399A",
        "ADDRESS1": "535 E 52nd Pl N",
        "ADDRESS2": "",
        "CITY": "Tulsa",
        "STATE": "OK",
        "COUNTRY": "USA",
        "POSTAL_CODE": 74126,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 1,
        "SQ_FT": "1298",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613701_104.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63821,
        "CATEGORY_ID": 37,
        "COUNTY": "Tulsa County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0.17 ac",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-08-11 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "80EBF2FA-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-08-12 08:06:05.553",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1958",
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:31.010",
        "LOAN_NUMBER": null,
        "Longitude": "-95.9877110",
        "Latitude": "36.2299930",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-08-11 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-08-11 00:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "535 E 52nd Pl N, Tulsa, OK 74126",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "535 E 52nd Pl N Tulsa OK 74126",
        "ID": 18043
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000024853,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "219 N. 3RD STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:04.633",
        "ITEM_ID": 613732,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE106584A",
        "ADDRESS1": "219 N. 3rd Street",
        "ADDRESS2": "",
        "CITY": "Girard",
        "STATE": "IL",
        "COUNTRY": "USA",
        "POSTAL_CODE": 62640,
        "BEDROOM_COUNT": 4,
        "BATH_COUNT": 2,
        "SQ_FT": "1734",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "2348.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0815/613732_27.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63852,
        "CATEGORY_ID": 15,
        "COUNTY": "Sangamon County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "0DE7C91B-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-24 08:33:52.187",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1939",
        "FIREPLACE": "Y",
        "GARAGE_SIZE": "2",
        "HEAT_TYPE": "Gas",
        "BASEMENT": "Full",
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:29.680",
        "LOAN_NUMBER": null,
        "Longitude": "-89.7805982",
        "Latitude": "39.4478824",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-23 17:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-23 17:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "219 N. 3rd Street, Girard, IL 62640",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "219 N. 3rd Street Girard IL 62640",
        "ID": 18217
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000027413,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "416 SHEFFIELD DRIVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:10.227",
        "ITEM_ID": 613673,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE108536A",
        "ADDRESS1": "416 Sheffield Drive",
        "ADDRESS2": "",
        "CITY": "Four Oaks",
        "STATE": "NC",
        "COUNTRY": "USA",
        "POSTAL_CODE": 27524,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1428",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 15,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613673_157.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63793,
        "CATEGORY_ID": 28,
        "COUNTY": "Dubuque County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-16 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "31DC88DD-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-17 08:00:59.963",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:02:41.597",
        "LOAN_NUMBER": null,
        "Longitude": "-78.5172340",
        "Latitude": "35.5178850",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-16 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-16 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "416 Sheffield Drive, Four Oaks, NC 27524",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "416 Sheffield Drive Four Oaks NC 27524",
        "ID": 18371
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000028756,
        "AT_Status": "Sold",
        "AT_AuctionType": "2nd Chance",
        "AT_Address1": "237 53RD STREET SE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.830",
        "ITEM_ID": 613699,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE105859A",
        "ADDRESS1": "237 53rd Street Se",
        "ADDRESS2": "",
        "CITY": "Canton",
        "STATE": "OH",
        "COUNTRY": "USA",
        "POSTAL_CODE": 44707,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 1,
        "SQ_FT": "1220",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613699_118.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63819,
        "CATEGORY_ID": 36,
        "COUNTY": "Stark County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "70EBF2FA-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-09 08:19:16.710",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:28.450",
        "LOAN_NUMBER": null,
        "Longitude": "-81.3679600",
        "Latitude": "40.7384310",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-08 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-08 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "237 53rd Street Se, Canton, OH 44707",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "237 53rd Street Se Canton OH 44707",
        "ID": 14710
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000028925,
        "AT_Status": "Removed",
        "AT_AuctionType": "Other",
        "AT_Address1": "913 N. 3RD STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:58.590",
        "ITEM_ID": 613723,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE111231A",
        "ADDRESS1": "913 N. 3rd Street",
        "ADDRESS2": "",
        "CITY": "Augusta",
        "STATE": "AR",
        "COUNTRY": "USA",
        "POSTAL_CODE": 72006,
        "BEDROOM_COUNT": 0,
        "BATH_COUNT": 1,
        "SQ_FT": "1280",
        "PHOTO_OBTAINED": "N",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload/auction_blank.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63843,
        "CATEGORY_ID": 3,
        "COUNTY": "Woodruff County",
        "BUYER_BROKER_FEE": "",
        "ACRES": ".33",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-07-30 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "597E550D-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-30 10:57:29.290",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1960",
        "FIREPLACE": null,
        "GARAGE_SIZE": "1",
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": "Crescent Hill",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:03.713",
        "LOAN_NUMBER": null,
        "Longitude": "-91.3648980",
        "Latitude": "35.2894319",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-30 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-30 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "913 N. 3rd Street, Augusta, AR 72006",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "913 N. 3rd Street Augusta AR 72006",
        "ID": 18034
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000029317,
        "AT_Status": "Removed",
        "AT_AuctionType": "Conveyed to HUD",
        "AT_Address1": "15528 BEACON POINT DRIVE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:04.387",
        "ITEM_ID": 613671,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE106459B",
        "ADDRESS1": "15528 Beacon Point Drive",
        "ADDRESS2": "",
        "CITY": "Northport",
        "STATE": "AL",
        "COUNTRY": "USA",
        "POSTAL_CODE": 35475,
        "BEDROOM_COUNT": 4,
        "BATH_COUNT": 2,
        "SQ_FT": "2077",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 15,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613671_34.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63791,
        "CATEGORY_ID": 2,
        "COUNTY": "Tuscaloosa County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "21DC88DD-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-27 13:04:50.327",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:02:39.280",
        "LOAN_NUMBER": null,
        "Longitude": "-87.5682420",
        "Latitude": "33.3640230",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-21 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-21 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "15528 Beacon Point Drive, Northport, AL 35475",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "15528 Beacon Point Drive Northport AL 35475",
        "ID": 18210
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000030043,
        "AT_Status": "Auction",
        "AT_AuctionType": "Marketing Only",
        "AT_Address1": "2918 EAST 81ST STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:35.350",
        "ITEM_ID": 613694,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE105860A",
        "ADDRESS1": "2918 East 81st Street",
        "ADDRESS2": "",
        "CITY": "Kansas City",
        "STATE": "MO",
        "COUNTRY": "USA",
        "POSTAL_CODE": 64132,
        "BEDROOM_COUNT": 4,
        "BATH_COUNT": 2,
        "SQ_FT": "1512",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613694_128.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63814,
        "CATEGORY_ID": 25,
        "COUNTY": "Jackson County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "080EF2F4-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-01 08:54:29.320",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:22.017",
        "LOAN_NUMBER": null,
        "Longitude": "-94.5535720",
        "Latitude": "38.9799600",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-01 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-01 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "2918 East 81st Street, Kansas City, MO 64132",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "2918 East 81st Street Kansas City MO 64132",
        "ID": 17101
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000030517,
        "AT_Status": "Removed",
        "AT_AuctionType": "Other",
        "AT_Address1": "510 36TH AVENUE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:57.550",
        "ITEM_ID": 613736,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE112175A",
        "ADDRESS1": "510 36th Avenue",
        "ADDRESS2": "",
        "CITY": "Greeley",
        "STATE": "CO",
        "COUNTRY": "USA",
        "POSTAL_CODE": 80634,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1092",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0815/613736_118.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63856,
        "CATEGORY_ID": 6,
        "COUNTY": "Weld County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "1092 sqft",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-08-26 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "25AEC221-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-08-25 15:32:40.093",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1967",
        "FIREPLACE": null,
        "GARAGE_SIZE": "1",
        "HEAT_TYPE": "Gas",
        "BASEMENT": "Full",
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:04:34.667",
        "LOAN_NUMBER": null,
        "Longitude": "-104.7369110",
        "Latitude": "40.4285370",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-08-26 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-08-26 00:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "510 36th Avenue, Greeley, CO 80634",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "510 36th Avenue Greeley CO 80634",
        "ID": 17999
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000030688,
        "AT_Status": "Removed",
        "AT_AuctionType": "Other",
        "AT_Address1": "45 DEER MEADOW LANE",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:07.990",
        "ITEM_ID": 613754,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE109399A",
        "ADDRESS1": "45 Deer Meadow Lane",
        "ADDRESS2": "",
        "CITY": "Elkland",
        "STATE": "MO",
        "COUNTRY": "USA",
        "POSTAL_CODE": 65644,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 1,
        "SQ_FT": "1356",
        "PHOTO_OBTAINED": "N",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload/auction_blank.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63874,
        "CATEGORY_ID": 25,
        "COUNTY": "Webster County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-07-08 17:00:00.000",
        "AUCTION_STOP_DATE": "2015-07-08 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "2F8BD52E-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-08 10:18:00.540",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:05:03.460",
        "LOAN_NUMBER": null,
        "Longitude": "-93.0375914",
        "Latitude": "37.4932229",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-08 17:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-08 17:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "45 Deer Meadow Lane, Elkland, MO 65644",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "45 Deer Meadow Lane Elkland MO 65644",
        "ID": 14787
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000031184,
        "AT_Status": "Pre-Auction",
        "AT_AuctionType": "Pending",
        "AT_Address1": "1444 PARK ROAD",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:41.893",
        "ITEM_ID": 613675,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE109536A",
        "ADDRESS1": "1444 Park Road",
        "ADDRESS2": "",
        "CITY": "Lake Charles",
        "STATE": "LA",
        "COUNTRY": "USA",
        "POSTAL_CODE": 70611,
        "BEDROOM_COUNT": 4,
        "BATH_COUNT": 3,
        "SQ_FT": "2027",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 15,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613675_137.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63795,
        "CATEGORY_ID": 19,
        "COUNTY": "Calcasieu Parish",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "41DC88DD-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-06-10 10:16:56.480",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:02:44.020",
        "LOAN_NUMBER": null,
        "Longitude": "-93.2194240",
        "Latitude": "30.3250520",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-06-10 17:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-06-10 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "1444 Park Road, Lake Charles, LA 70611",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "1444 Park Road Lake Charles LA 70611",
        "ID": 17406
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000031333,
        "AT_Status": "Post-Auction",
        "AT_AuctionType": "Assigned 2nd Chance",
        "AT_Address1": "220 MICHIGAN STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:42:07.570",
        "ITEM_ID": 613710,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE106290A",
        "ADDRESS1": "220 Michigan Street",
        "ADDRESS2": "",
        "CITY": "Gooding",
        "STATE": "ID",
        "COUNTRY": "USA",
        "POSTAL_CODE": 83330,
        "BEDROOM_COUNT": 1,
        "BATH_COUNT": 1,
        "SQ_FT": "784",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "1161.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 50000,
        "FINAL_RESERVE": 50000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613710_180.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63830,
        "CATEGORY_ID": 14,
        "COUNTY": "Gooding County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "6100sf",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2015-08-20 18:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 20000,
        "RESERVE_PRICE_AMT": 50000,
        "BID_INCREMENT_AMT": 500,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "F85C3001-340A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-08-21 08:06:03.377",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": "1950",
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": "Gas",
        "BASEMENT": "None",
        "HALF_BATH": 0,
        "SUBDIVISION": "Gooding",
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:03:47.200",
        "LOAN_NUMBER": null,
        "Longitude": "-114.7066482",
        "Latitude": "42.9433710",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-08-20 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-08-20 00:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "220 Michigan Street, Gooding, ID 83330",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "220 Michigan Street Gooding ID 83330",
        "ID": 18300
    },
    {
        "AGENT_ID": 56533352,
        "Client": "Carrington Mortgage Services, LLC",
        "LoanNumber": 6000032723,
        "AT_Status": "Pre-Auction",
        "AT_AuctionType": "Pending",
        "AT_Address1": "500 PATE STREET",
        "CLOSING_DATE": null,
        "SOLD_DATE": null,
        "STATUS_DESCRIPTION": "Returned",
        "CLOSING_STATUS_ID": "39",
        "closingStatusDesc": "Returned",
        "LoadedIntoAT": "2015-09-16 11:41:11.390",
        "ITEM_ID": 613684,
        "STATUS_ID": 15,
        "ASSET_MANAGER_ID": 56088188,
        "SELLER_ID": 56533352,
        "ASSET_NUMBER": "NCE111689A",
        "ADDRESS1": "500 Pate Street",
        "ADDRESS2": "",
        "CITY": "Ashford",
        "STATE": "AL",
        "COUNTRY": "USA",
        "POSTAL_CODE": 36312,
        "BEDROOM_COUNT": 3,
        "BATH_COUNT": 2,
        "SQ_FT": "1864",
        "PHOTO_OBTAINED": "Y",
        "TAXES_DUE": "0.00000",
        "HOA_DUES_DUE": "0.00000",
        "TYPE": 22,
        "VERIFIED": "N",
        "SPECIAL_INFO": null,
        "VALUE": 5000,
        "FINAL_RESERVE": 5000,
        "PHOTO_PATH": "/upload.nfs/photos/0615/613684_68.jpg",
        "SELLER_CONTACT_ID": 56088188,
        "AMS_SELLER_ID": "rb55695135",
        "AMS_AUCTION_NO": 63804,
        "CATEGORY_ID": 2,
        "COUNTY": "Houston County",
        "BUYER_BROKER_FEE": "",
        "ACRES": "0",
        "PROPERTY_OWNER": "",
        "AUCTION_TYPE": "RL",
        "AUCTION_START_DATE": "2015-06-03 17:00:00.000",
        "AUCTION_END_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_STOP_DATE": "2016-06-03 17:00:00.000",
        "AUCTION_DAY_COUNT": 366,
        "MIN_BID_AMT": 2500,
        "RESERVE_PRICE_AMT": 5000,
        "BID_INCREMENT_AMT": 250,
        "ESCROW_AMT": null,
        "DDI_AMT": null,
        "BUYER_PREMIUM": 0.05,
        "PIP_CODE": null,
        "VT_URL": "",
        "FEATURED": "N",
        "SEALED_BID": "N",
        "BID_BY_ACRE": "N",
        "rowguid": "21CBC2E3-330A-E511-8F79-12D9BB12D0DE",
        "REOFFER": "N",
        "SELLER_CLOSING_CONTACT_ID": "56088188",
        "LISTING_AGENT_FEE": null,
        "PROPERTY_OWNER_ID": null,
        "DUTCH_STYLE": "N",
        "ASSET_MGT_FEE": null,
        "DAYS_ON_MARKET": 0,
        "RETURNED_DATE": "2015-07-15 15:45:09.750",
        "REMARKET_STARTING_BID": null,
        "YEAR_BUILT": null,
        "FIREPLACE": null,
        "GARAGE_SIZE": null,
        "HEAT_TYPE": null,
        "BASEMENT": null,
        "HALF_BATH": 0,
        "SUBDIVISION": null,
        "BASEMENT_FINISHED": null,
        "TITLE_ORDER_NO": "",
        "EVENT_TYPE": "RL",
        "LOADED_DATE": "2015-06-03 16:02:55.043",
        "LOAN_NUMBER": null,
        "Longitude": "-85.2402850",
        "Latitude": "31.1892880",
        "BPO_VALUE": null,
        "TITLE_POLICY_FEE": null,
        "DONT_RETURN_YET": "N",
        "REAUCTIONED": "N",
        "MI_APPROVAL": "N",
        "RETURN_ON_DATE": "2015-07-21 18:00:00.000",
        "BPO_DATE": null,
        "OCCUPANCY_DATE": null,
        "NET_OPERATING_INCOME": null,
        "NUM_STORIES": null,
        "NOI_PERIOD": null,
        "OCCUPANCY": null,
        "PARCEL_ID": null,
        "PROPERTY_NAME": null,
        "NUM_BUILDINGS": null,
        "NUM_UNITS": null,
        "PRE_AUCTION_START_DATE": null,
        "OPEN_HOUSE1": null,
        "OPEN_HOUSE2": null,
        "POST_AUCTION_END_DATE": "2015-07-21 18:00:00.000",
        "ADDRESS_UNIT": "",
        "APPRAISED_VALUE": null,
        "MI_FLAG": null,
        "HOMEPATH_PROTECTION_FLAG": null,
        "HOMEPATH_RENOVATION_FLAG": null,
        "RECONSIDERATION_FLAG": null,
        "MLS_NUMBER": "",
        "MLS_NUMBER_VERIFIED": null,
        "LAST_MODIFIED_DATE": null,
        "show_on_site": 1,
        "SUB_SELLER_ID": "56533356",
        "BIDDIRECT_MIN_OFFER_PERCENT": 0.8,
        "AQUISITION_PRICE": 0,
        "OPTION_CONTRACT": 0,
        "ADD_MLS": 0,
        "MLS_EMAIL": "",
        "MLS_EXP_DATE": null,
        "PROGRAM_ID": 1,
        "Address_Line": "500 Pate Street, Ashford, AL 36312",
        "uploader_type_id": null,
        "at_property_id": null,
        "at_property_export_id": null,
        "Address_Line_Full": "500 Pate Street Ashford AL 36312",
        "ID": 15351
    }
]
