//Column definitions for drillthroughs
function ItemidForLoanNumService($q, $resource) {
  'ngInject';

    const service = {};

    const uri = '/clientportal/api/';
    let GetIDByATLoanNumber = $resource(uri + 'GetIDByATLoanNumber/:id?nopage=1&format=json', {id:'@id'});

    service.get = function(data){
        let deferred = $q.defer();
        let deferreds = [];

        angular.forEach(data, (item, pos) =>{
            let _deferred = $q.defer();
            let loannum = null;

            if('LoanNumber' in item){
                loannum = item['LoanNumber'];
            }
            if('Loan_Number' in item){
                loannum = item['Loan_Number'];
            }
            if('loannumber' in item){
                loannum = item['loannumber'];
            }

            GetIDByATLoanNumber.query({'id':loannum}).$promise.then(extra_data => {
                if(extra_data.length < 1){
                    _deferred.resolve(item);
                    return;
                }
                // let merged = Object.assign(item, extra_data[0]);
                let merged = {};

                for (var attrname in item) { merged[attrname] = item[attrname]; }
                for (var attrname in extra_data[0]) { merged[attrname] = extra_data[0][attrname]; }

                data[pos] = merged;

                _deferred.resolve(merged);
            });
            deferreds.push(_deferred.promise);
        });

        $q.all(deferreds).then(mergeddata => {
            deferred.resolve(mergeddata)
        });

        return deferred.promise;
    }

  return service;

}

export default {
  name: 'ItemidForLoanNumService',
  fn: ItemidForLoanNumService
};
