import 'jui-datepicker';

function Check_Version(){
    var rv = -1; // Return value assumes failure.

    if (navigator.appName == 'Microsoft Internet Explorer'){

        var ua = navigator.userAgent,
            re  = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})");

        if (re.exec(ua) !== null){
            rv = parseFloat( RegExp.$1 );
        }
    }
    else if(navigator.appName == "Netscape"){
        /// in IE 11 the navigator.appVersion says 'trident'
        /// in Edge the navigator.appVersion does not say trident
        if(navigator.appVersion.indexOf('Trident') === -1) rv = 12;
        else rv = 11;
    }

    return rv;
}

Check_Version();

function DateDirective($parse,dateFilter) {
    'ngInject';

    return {
        restrict: 'A',
        require:['ngModel','^form'],
        link:function (scope, elm, attrs, ctrl) {
            var ngModel = $parse(attrs.ngModel);

            //can't disable native picker on Edge, so don't add our own
            if(window.navigator.userAgent.indexOf("Edge") < 0){
                angular.element(elm).addClass('no-chrome-picker');

                jQuery(elm)
                    .wrap('<div id="sq" class="divclearable"></div>')
                    .parent()
                    .append('<span class="red remove icon">x</span>');

                jQuery(elm).on('click', function(e) {e.preventDefault();})
                    .datepicker(
                        {
                            inline: true,
                            autoSize: true,
                            changeYear:true,
                            changeMonth:true,
                            dateFormat: 'mm-dd-yy',
                            onSelect:function (dateText, inst) {
                                console.log(dateText);
                                scope.$apply(function(scope){
                                    // Change binded variable
                                    let arr = dateText.split('-');
                                    let date = new Date();
                                    date.setYear(parseInt(arr[2]));
                                    date.setMonth(parseInt(arr[0]-1)); //Because JS has zero based months :/
                                    date.setDate(parseInt(arr[1]));

                                    console.log(dateText);
                                    console.log(date);
                                    ngModel.assign(scope, date);
                                    ctrl[1][elm.attr('name')].$setDirty();
                                });
                            }
                        }
                    );

                jQuery(elm).siblings('span')
                    .attr('title', 'Clear')
                    .click(function() {
                        jQuery(this).prev().val('').focus();
                        scope.$apply(function(scope){
                            ngModel.assign(scope, null);
                            ctrl[1][elm.attr('name')].$setDirty();
                        });
                    });
            }
        }
    };
}

export default {
    name: 'date',
    fn: DateDirective
};
