function ManagePropertiesCtrl($scope, $interpolate, $resource, $state, ColumnDefinitionService, NgTableParams) {
    'ngInject';

    const ctrl = this;
    const uri = '/clientportal/api/';

    ctrl.removal_dropdown  = [
        "Conveyed to HUD",
        "Sale rescinded",
        "Bankruptcy",
        "Not eligible for conveyance",
        "Other"];

    ctrl.data = [];
    ctrl.showfullerror = false;
    ctrl.showerror = false;
    ctrl.showsuccess = false;

    // let res = $resource(uri + 'ATPropertiesView/?format=json&nopage=1');
    //     //, {
    // //     'update': { method:'PATCH' }
    // // }
        let res = $resource(uri + 'ATPropertiesView/:id/?format=json&nopage=1', {id:'@id'}, {
        'update': { method:'PATCH' }
    });


    ctrl.cols = [
        {
            field: "LoanNumber",
            title: "LoanNumber",
            filter: {LoanNumber: "text"},
            sortable: "LoanNumber"
        },
        {
            field: "Address",
            title: "Address",
            filter: {Address: "text"},
            sortable: "Address"
        },
        {
            field: "City",
            title: "City",
            filter: {City: "text"},
            sortable: "City"
        },
        {
            field: "State",
            title: "State",
            filter: {State: "text"},
            sortable: "State"
        },
        {
            field: "Zip",
            title: "Zip",
            filter: {Zip: "text"},
            sortable: "Zip"
        },
        {
            field: "County",
            title: "County",
            filter: {County: "text"},
            sortable: "County"
        },
        {
            field: "AuctionTypeName",
            title: "Auction Type Name",
            filter: {AuctionTypeName: "text"},
            sortable: "AuctionTypeName"
        },
        {
            field: "Status",
            title: "Status",
            filter: {Status: "text"},
            sortable: "Status"
        },
        {
            field: "review_remove_date",
            title: "Review Remove Date",
            filter: {review_remove_date: "text"},
            getValue: 'interpolatedValue',  //This is an interpolated field, use interpolateExpr to generate the html
            interpolateExpr: $interpolate("<input type='text' ng-model='row.review_remove_date' />"),
            sortable: "review_remove_date"
        },
        {
            field: "removal_cause",
            title: "Removal Cause",
            filter: {removal_cause: "text"},
            getValue: 'interpolatedValue',  //This is an interpolated field, use interpolateExpr to generate the html
            interpolateExpr: $interpolate("<input type='text' ng-model='row.removal_cause' />"),
            sortable: "removal_cause"
        },
        {
            field: "remove_flag",
            title: "Removal Flag",
            filter: {remove_flag: "text"},
            getValue: 'interpolatedValue',  //This is an interpolated field, use interpolateExpr to generate the html
            interpolateExpr: $interpolate("<input type='text' ng-model='row.remove_flag' />"),
            sortable: "remove_flag"
        }
    ];


    if(ctrl.cols && ctrl.cols.length === 0){
        ctrl.cols = false;
    }

    ctrl.tableParams = new NgTableParams({
        count: 15
    }, {
        filterDelay: 0
    });

    res.query().$promise.then(data => {
        Load_Data(data);
    });

    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    ctrl.save = function save(row) {
        ctrl.showerror = false;
        ctrl.showsuccess = false;

        try{
            if(row.review_remove_date){
                let date = row.review_remove_date;
                let str = pad(date.getFullYear(),4) + '-' + pad(date.getMonth()+1,2) + '-' + pad(date.getDate(),2);
                row.review_remove_date = str;
            }else{
                row.review_remove_date = null;
            }
        }catch(e){
            console.log(e)
        }

        if(row.remove_flag && (!row.removal_cause || row.removal_cause === '')){
            ctrl.showerror = true;
            ctrl.error = "Removal Cause required before saving."
        }else{
            row.$update(function(data){
                console.log('success, got data: ', data);
                ctrl.showsuccess = true;
            }, function(err){
                ctrl.showerror = true;
                if("data" in err && "detail" in err["data"]){
                    ctrl.error = err["data"]["detail"];
                }else{
                    ctrl.error = err["statusText"];
                }

                ctrl.fullError = err;
            });
        }
    };

    //-------------------------------------
    function Load_Data(data) {
        let dout = [];

        angular.forEach(data, function(row){
            try{
                if(row.review_remove_date !== null){
                    let arr = row.review_remove_date.split('-');
                    let date = new Date();
                    date.setYear(parseInt(arr[0]));
                    date.setMonth(parseInt(arr[1]-1)); //Because JS has zero based months :/
                    date.setDate(parseInt(arr[2]));
                    row.review_remove_date = date;
                }
            }catch(e){
                console.log(e)
            }

            dout.push(row);
        });


        ctrl.data = dout;

        ctrl.tableParams.settings({
            dataset: ctrl.data
        });

        ctrl.data_loaded = true;

        ctrl.data['show_otherTextBox']=false;

    }

    ctrl.propertyDetailsCB = function(row){
        console.log(row);
        $state.go('PropertyDetails', {'id':row['id']});
    };

    $scope.changedValue = function(row) {
        if (row['removal_cause'] === 'Other')
            row['show_otherTextBox']=true;
        else
            row['show_otherTextBox']=false;
    }
}

export default {
    name: 'ManagePropertiesCtrl',
    fn: ManagePropertiesCtrl
};
