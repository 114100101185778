function PrettyPrintJsonFilter() {

  return function(input) {
    return JSON.stringify(input, null, " ");
  };

}

export default {
  name: 'prettyJSON',
  fn: PrettyPrintJsonFilter
};
