function PropertyDetailsCtrl($scope, $interpolate, $resource, $stateParams, $previousState, $state, ColumnDefinitionService, $http) {
    'ngInject';

    const ctrl = this;
    const uri = '/clientportal/api/';

    let res = $resource(uri + 'ATPropertiesView/:id/?format=json&nopage=1', {id: '@id'});
    let auction_res = $resource(uri + 'AuctionView/:id?format=json&nopage=1', {id: '@id'});
    let bids_res = $resource(uri + 'AuctionBidsView/:id?format=json&nopage=1', {id: '@id'});
    let docs_res = $resource(uri + 'DocumentsView/?LoanNumber=:id&nopage=1&format=json', {id: '@id'});
    let current_employee_res = $resource(uri + 'CurrentEmployee/:id?nopage=1&format=json', {id: '@id'});

    let codes_for_property_res = $resource(uri + 'CodesForProperty/:id/?format=json&nopage=1', {id:'@id'}, {
            'update': { method:'PATCH' }
    });

    ctrl.doc_cols = [
        {
            field: "file_url",
            title: "Action",
            filter: {file_url: "text"},
            sortable: "file_url",
            getValue: 'interpolatedValue',
            interpolateExpr: $interpolate("<button class='btn btn-primary btn-sm' onclick='location.href=&quot;{{row.file_url}}&quot;;'>Download</button>")
        },
        {
            field: "LoanNumber",
            title: "Loan Number",
            filter: {LoanNumber: "text"},
            sortable: "LoanNumber"
        },
        {
            field: "filename",
            title: "File Name",
            filter: {filename: "text"},
            sortable: "filename"
        },
        {
            field: "created_at",
            title: "Created",
            filter: {created_at: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "created_at"
        },
        {
            field: "updated_at",
            title: "Last Modified",
            filter: {updated_at: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "updated_at"
        },
    ];

        //ColumnDefinitionService.get('DocumentsForProperty');

    ctrl.auction_cols = ColumnDefinitionService.get("Auction");
    ctrl.bids_cols = ColumnDefinitionService.get("Bids");

    ctrl.current_employee_data_loaded = false;

    ctrl.back = function () {
        $previousState.go();
    };

    ctrl.init = function () {

        ctrl.cols = ColumnDefinitionService.get('ATPropertiesView');

        console.log(ctrl.cols);

        if (ctrl.cols && ctrl.cols.length === 0) {
            ctrl.cols = false;
        }

        res.get({'id': ctrl.id}).$promise.then(data => {
            Load_Data(data);

            auction_res.query({'id': data.item_id}).$promise.then(data => {
                Load_Auction_Data(data);
            });

            current_employee_res.query({'id': data.item_id}).$promise.then(data => {
                Load_Current_Employee_Data(data);
            })

            bids_res.query({'id': data.item_id}).$promise.then(data => {
                Load_Bids_Data(data);
            });
            codes_for_property_res.query({'id':data.item_id}).$promise.then(data => {
                Load_Code_Data(data);
            })
        });
    };

    if (!('id' in $stateParams) || !$stateParams.id) {
        $state.go('ManageProperties');
    } else {
        ctrl.id = $stateParams.id;
        ctrl.init();
    }

    //-------------------------------------
    function Load_Data(data) {
        ctrl.data_loaded = true;

        ctrl.data = data;

        if ('LoanNumber' in ctrl.data) {
            docs_res.query({'id': data.LoanNumber}).$promise.then(docs => {
                Load_Document_Data(docs);
            });
        }
    }

    //-------------------------------------
    function Load_Document_Data(data) {
        console.log(data);
        ctrl.doc_data_loaded = true;


        let dout = [];

        angular.forEach(data, function (row) {

            let dates = [
                'created_at',
                'updated_at'
            ];

            angular.forEach(dates, function (name) {
                try {
                    if (row[name] === null) return;
                    let arr = row[name].split('-');
                    let date = new Date();
                    date.setYear(parseInt(arr[0]));
                    date.setMonth(parseInt(arr[1] - 1)); //Because JS has zero based months :/
                    date.setDate(parseInt(arr[2]));
                    // let parsed = Date.parse(data[name]);
                    // data[name] = new Date(parsed);
                    row[name] = date;
                } catch (e) {
                    console.log(e)
                }
            });

            dout.push(row);
        });

        ctrl.doc_data = dout;
    }

    //-------------------------------------
    function Load_Auction_Data(data) {
        ctrl.auction_data_loaded = true;

        let dout = [];

        angular.forEach(data, function (row) {

            let address = row['Address'] + ' , ' + row['City'] + ' , ' + row['State'] + ' , ' + row['Zip'];

            row['Address'] = address;

            let dates = [
                'AuctionEndDate',
                'AuctionStartDate'
                ,
            'PostAuctionEndDate'
            ];

            angular.forEach(dates, function (name) {
                try {
                    if (row[name] === null) return;
                    let arr = row[name].split('-');
                    let date = new Date();
                    date.setYear(parseInt(arr[0]));
                    date.setMonth(parseInt(arr[1] - 1)); //Because JS has zero based months :/
                    date.setDate(parseInt(arr[2]));
                    // let parsed = Date.parse(data[name]);
                    // data[name] = new Date(parsed);
                    row[name] = date;
                } catch (e) {
                    console.log(e)
                }
            });



            dout.push(row);
        });

        ctrl.auction_data = dout;
    }//-------------------------------------
    function Load_Bids_Data(data) {
        ctrl.bids_data_loaded = true;

        let dout = [];

        angular.forEach(data, function (row) {

            let dates = [
                'BID_DATE',
                'SaleDate',
                'ContractClosingDate'
            ];

            angular.forEach(dates, function (name) {
                try {
                    if (row[name] === null) return;
                    let arr = row[name].split('-');
                    let date = new Date();
                    date.setYear(parseInt(arr[0]));
                    date.setMonth(parseInt(arr[1] - 1)); //Because JS has zero based months :/
                    date.setDate(parseInt(arr[2]));
                    // let parsed = Date.parse(data[name]);
                    // data[name] = new Date(parsed);
                    row[name] = date;
                } catch (e) {
                    console.log(e)
                }
            });

            dout.push(row);
        });

        ctrl.bids_data = data;
    }

    function Load_Code_Data(data) {
        data = data[0];

 /*        let dates = [
            'TWONDL',
            'TWONDF',
            'TWONDO',
            'TWONDC',
            'TWONDA',
            'TWONDS',
            'HEXF',
            'HEXA',
            'HEXD',
            'SCTC',
            'CDUP'
        ]; */
        let dates = [
            'SecondChanceApprovalDate',
            'SecondRem',
            'OfferReceived',
            'CLOSINGDATE',
            'hudapprovedbyseller',
            'fundeddate',
            'deedapprovedbyseller'
        ];

        angular.forEach(dates, function (name) {
            try {
                if (data[name] === null) return;
                let arr = data[name].split('-');
                let date = new Date();
                date.setYear(parseInt(arr[0]));
                date.setMonth(parseInt(arr[1] - 1)); //Because JS has zero based months :/
                date.setDate(parseInt(arr[2]));
                // let parsed = Date.parse(data[name]);
                // data[name] = new Date(parsed);
                data[name] = date;
            } catch (e) {
                console.log(e)
            }
        });

        ctrl.code_data_loaded = true;

        ctrl.code_data = data;

        if ($scope.form) {
            $scope.form.$setPristine();
            $scope.form.$setUntouched();
        }
    }

    function pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    ctrl.saveCodesCB = function saveCodesCB(code_data) {
        console.log("ctrl.code_data",code_data)
        let data = {};
        //console.log(ctrl.code_data);

   /*      let dates = [
            'TWONDL',
            'TWONDF',
            'TWONDO',
            'TWONDC',
            'TWONDA',
            'TWONDS',
            'HEXF',
            'HEXA',
            'HEXD',
            'SCTC',
            'CDUP'
        ]; */

        let dates = [
            'SecondChanceApprovalDate',
            'SecondRem',
            'OfferReceived',
            'CLOSINGDATE',
            'hudapprovedbyseller',
            'fundeddate',
            'deedapprovedbyseller'
        ];

        angular.forEach(dates, function (name) {
            try {

                if ($scope.form[name]) {
                    console.log("in if loop")
                    console.log("$scope.form[name]", $scope.form[name])
                    if (ctrl.code_data[name] && !isNaN(ctrl.code_data[name].getTime())) {
                        let date = ctrl.code_data[name];
                        let str = pad(date.getFullYear(), 4) + '-' + pad(date.getMonth() + 1, 2) + '-' + pad(date.getDate(), 2);
                        data[name] = str;
                    } else {
                        data[name] = null;
                    }
                }
            } catch (e) {
                console.log(e)
            }
        });

        console.log(data);
        data.sellercontribution =ctrl.code_data.sellercontribution
        data.auctionfeecontribution =ctrl.code_data.auctionfeecontribution

        //codes_for_property_res
        ctrl.showerror = false;
        ctrl.showsuccess = false;
        // alert("Work in progress")
        data.id = ctrl.data.LoanNumber;
        data.itemid = ctrl.id;

       codes_for_property_res.update(data, function(data){
            console.log('success, got data: ', data);
            ctrl.showsuccess = true;
            codes_for_property_res.query({id: ctrl.id}).$promise.then(data => {
                Load_Code_Data(data);
            });
        }, function(err){
            ctrl.showerror = true;
            if("data" in err && "detail" in err["data"]){
                ctrl.error = err["data"]["detail"];
            }else{
                ctrl.error = err["statusText"];
            }

            ctrl.fullError = err;
        });
    }


    ctrl.uploadFile = function () {
        var file = $scope.myFile;
        let uploadUrl = uri + 'XLR8ItemProfilesDetailsFile/';
        let property_id = $stateParams.id;

        // FileUploadService.get(file, uploadUrl, property_id, showSuccess);
        var fd = new FormData();
        fd.append('file', file);
        fd.append('property_id', property_id);
        fd.append('name', file['name']);
        $http.post(uploadUrl, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        }).then(function (success) {
            ctrl.showsuccess = true;
            window.location.reload(true);
        }).catch(function (error) {
            ctrl.showsuccess = false;
        });
    }
    // var f = document.getElementById('myFile').files[0];
    // console.log(f)
    //  $scope.interface.getFiles($scope.interface.FILE_TYPES.VALID).forEach(function (model) {
    //     // console.log(model);
    //     //Angular doesn't seem to do multipart forms, so xhr it is.
    //     var fd = new FormData();
    //     //Take the first selected file
    //     fd.append("file", model.file);
    // let uploadRes = uri + 'XLR8ItemProfilesDetailsFile/' ;
    // $http.post(uploadRes, fd, $scope.property_id{
    //         withCredentials: true,
    //         headers: {
    //             'Content-Type': undefined},
    //         transformRequest: angular.identity
    //     }),function(success){
    //         alert("Upload Success");
    //     };
    //
    //
    // });


    ctrl.AuctionDocumentsCB = function (row) {
        $state.go('PropertyDocuments', {'id': row['AuctionID']});
    }


    function Load_Current_Employee_Data(data) {
        ctrl.current_employee_data_loaded = true;
        ctrl.current_employee_data = data[0];
    }
};

export default {
    name: 'PropertyDetailsCtrl',
    fn: PropertyDetailsCtrl
};

angular.element(document).ready(function () {
    window.$ = window.jQuery = require("jquery");
    require('jquery-ui');
    $('.codedate').datepicker();
});
