import moment from 'moment-timezone';
import appSettings from '../constants';

function DrillThroughCtrl($scope, $interpolate, $resource, $q, $state, $stateParams, $previousState, ModalService, ColumnDefinitionService, PreprocessDataService, TitleDefinitionService) {
    'ngInject';

    let mapper = {
        "55+ Days": "1",
        "Less Than 45 Days": "2"
    };

    let parameter = $stateParams.parameter;
   // let program=$stateParams.program;

    if(parameter in mapper){
        parameter = mapper[parameter];
    }


    let currentSystemTime = moment().tz(appSettings.systemTimezone);

    // ViewModel
    const ctrl = this;
    const uri = '/clientportal/api/';

    
    ctrl.back = function() {
        if (ctrl.title =='Provided Reserve Not Matching Calculated' || ctrl.title == 'Missing Reserve Or Valuation') {
            $state.go('DailyTasks');
        }
        else if (ctrl.title == 'Post Foreclosure Status'){
            $state.go('ManagementInventoryReports');
            }
    else {
            $state.go('ProgramManagers');
        }
    };

    ctrl.report = $stateParams.report;
    ctrl.title = TitleDefinitionService.get(ctrl.report);

    ctrl.parameter = parameter;

/*     let invstatus_params = {
      1:"Pre-Auction",
      2:"Auction",
      3:"Post-Auction",
      4:"Closing",
      5:"Sold",
      6:"Removed",
    }; */

    let submilestonestatus_params = {
      1:"1st",
      27:"Contract to TC",
      2:"Assigned 2nd Chance",
      21:"Pending",
      5:"Conveyed to HUD",
      13:"Removed from 1st Chance",
      7:"1st Chance",
      // 7:"Sold: 1st Chance",
      8:"2nd Chance",
      // 8:"Sold: 2nd Chance",
      9:"Sold: Marketing Only",
      31: "Closed"
    };

    if (moment(parameter, moment.ISO_8601, true).isValid()){
        ctrl.titleparam = moment(parameter, moment.ISO_8601, true).format('MM-DD-YYYY');
    }else{

        if(ctrl.report === 'RPT_ForeclosureStatusDrillThrough'){
            ctrl.titleparam = invstatus_params[ctrl.parameter];
        }
        if(ctrl.report === 'RPT_PostForeclosureStatusDrillThrough'){
            ctrl.titleparam = ctrl.parameter;
        }

        if(!ctrl.titleparam){
            ctrl.titleparam = ctrl.parameter;
        }
    }

    if(ctrl.titleparam === '__all__'){
        ctrl.titleparam = 'All';
    }

    ctrl.hidePageSizer = true;

    let res = $resource(uri + ctrl.report + '/:id?format=json&nopage=1', {id:'@id'});
    console.log("ctrl.report in drill", ctrl.report)
    // if(ctrl.report === 'RPT_ForeclosureStatusDrillThrough') {
    //     console.log("ctrl.report", ctrl.report);
    //
    //     let res = $resource(uri + ctrl.report + '/?format=json&nopage=1', {
    //         id: '@id',
    //         'program': program,
    //         'AgentId': '253076991'
    //     });
    //     console.log("res in in",res);
    // }
    //
    // else{
    // let res = $resource(uri + ctrl.report + '/?format=json&nopage=1', {id: '@id', 'AgentId': $stateParams.AgentId});

    ctrl.cols = ColumnDefinitionService.get(ctrl.report);


    let link = {
        title: "Link",
        getValue: 'propertyDetailLinkValue',
    };

    if(ctrl.cols && ctrl.cols.length === 0){
        ctrl.cols = false;
    }else{
        let newcols = [];

        angular.forEach(ctrl.cols, function(val){
            if(val.title !== 'Link'){
                newcols.push(val);
            }
        });

        ctrl.cols = newcols;
        ctrl.cols.unshift(link);
    }
    res.query({id: parameter}).$promise.then(data => {
        Load_Data(data);
    });

    let GetIDByATLoanNumber = $resource(uri + 'GetIDByATLoanNumber/:id?nopage=1&format=json', {id:'@id'});

    //-------------------------------------
    function Load_Data(data) {
        $scope.$watch('ctrl.data', () => {
            ctrl.data_loaded = true;
        });

        if(ctrl.report === 'RPT_OutstandingLiensAndDemands'){
            data = ctrl.handleOutstandingLeinsAndDemands(data);
        }

        ctrl.data = data;

        let deferred = $q.defer();
        let deferreds = [];

        angular.forEach(data, (item, pos) =>{
            let _deferred = $q.defer();
            let loannum = null;

            if('ITEM_ID' in item && item['ITEM_ID']){
                _deferred.resolve(item);
            }else{
                if('Asset_Number' in item){
                    loannum = item['AssetNumber'];
                }
                if('AssetNumber' in item){
                    loannum = item['AssetNumber'];
                }
                if('assetnumber' in item){
                    loannum = item['AssetNumber'];
                }

                GetIDByATLoanNumber.query({'id':loannum}).$promise.then(extra_data => {
                    if(extra_data.length < 1){
                        extra_data = [{'ITEM_ID' : null}]
                    }
                    // let merged = Object.assign(item, extra_data[0]);
                    let merged = {};

                    for (var attrname in item) { merged[attrname] = item[attrname]; }
                    for (var attrname in extra_data[0]) { merged[attrname] = extra_data[0][attrname]; }

                    ctrl.data[pos] = merged;

                    _deferred.resolve(merged);
                });
            }

            deferreds.push(_deferred.promise);
        });

        $q.all(deferreds).then(mergeddata => {
            deferred.resolve(mergeddata)
        });

        deferred.promise.then(dataout => {
            ctrl.data = dataout;
        });
    }

/*
    ctrl.handleOutstandingLeinsAndDemands = function(data){
        let newdata = [];

        angular.forEach(data, function(val){
            val._toggle = false;
            val._cb = function(val){
                console.log(val);
            };
            newdata.push(val);
        });

        let editcol = {
            field: "resolved",
            title: "Resolved",
            filter: {resolved: "text"},
            getValue: 'interpolatedValue',  //This is an interpolated field, use interpolateExpr to generate the html
            interpolateExpr: $interpolate("<button>Resolve</button>"),  //Careful, can't use ng-click or anything here
            callback: function(row){
                // ModalService.showModal({
                //     templateUrl: "modals/outstandingleinsanddemands.html",
                //     controller: "ModalOLADController",
                //     inputs: {
                //         title: "Outstanding Leins And Demands - Resolve"
                //     }
                // }).then(function(modal) {
                //     modal.element.modal();
                //     modal.close.then(function(result) {
                //         if(!result){
                //             //dismissed, don't do anythin
                //         }else{
                //             console.log("Name: " + result.name + ", age: " + result.age);
                //         }
                //     });
                // });
                console.log("CB!!");
                console.log(row);
            }
        };

        let newcols = [];

        angular.forEach(ctrl.cols, function(val){
            if(val.title !== 'Resolved'){
                newcols.push(val);
            }
        });

        newcols.push(editcol);

        ctrl.cols = newcols;

        return newdata;
    }
*/
}

export default {
    name: 'DrillThroughCtrl',
    fn: DrillThroughCtrl
};
