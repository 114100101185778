function SCTCController($scope, $interpolate, $resource, $state, $stateParams, ColumnDefinitionService, NgTableParams, ItemidForLoanNumService) {
    'ngInject'

    const ctrl = this;
    const uri = '/clientportal/api/';

    let parameter = $stateParams.parameter || '__all__';
    ctrl.parameter = parameter;
    if (moment(parameter, moment.ISO_8601, true).isValid()){
        ctrl.titleparam = moment(parameter, moment.ISO_8601, true).format('MM-DD-YYYY');
    }else{
        ctrl.titleparam = ctrl.parameter;
    }
    if(ctrl.titleparam === '__all__'){
        ctrl.titleparam = 'All';
    }

    ctrl.data = [];
    ctrl.showfullerror = false;
    ctrl.showerror = false;
    ctrl.showsuccess = false;

    let res = $resource(uri + 'RPT_SCTCDate/:id/?format=json&nopage=1', {id:'@id'}, {
        'update': { method:'PATCH' }
    });


    ctrl.tableParams = new NgTableParams({
        count: 15
    }, {
        filterDelay: 0
    });

    res.query({id: parameter}).$promise.then(data => {
        Load_Data(data);
    });

    ctrl.save = function save(row) {
        console.log(row);
        ctrl.showerror = false;
        ctrl.showsuccess = false;

        // alert("Work in progress")
        row.id = row.LoanNumber;

        row.$update(function(data){
            console.log('success, got data: ', data);
            ctrl.showsuccess = true;
            res.query({id: parameter}).$promise.then(data => {
                Load_Data(data);
            });
        }, function(err){
            ctrl.showerror = true;
            if("data" in err && "detail" in err["data"]){
                ctrl.error = err["data"]["detail"];
            }else{
                ctrl.error = err["statusText"];
            }

            ctrl.fullError = err;
        });
    };

    //-------------------------------------
    function Load_Data(data) {
        ItemidForLoanNumService.get(data).then((data) => {
            console.log(data);
            ctrl.data = data;
            ctrl.tableParams.settings({
                dataset: ctrl.data
            });

            ctrl.data_loaded = true;

            ctrl.data['show_otherTextBox']=false;


            angular.forEach(ctrl.data, (data) => {
                angular.forEach(data, (value, key) => {
                    var dt=new Date(value);
                    if ( (value !==null) && dt !== "Invalid Date" && !isNaN(dt) ) {
                        var isZDate = value.toString().indexOf("00Z") >=0;
                        console.log("Date is " + value + " is zdate: " + isZDate);
                        if (isZDate) {
                            var dtn = new Date(value);
                            data[key] = new Date(dtn.getFullYear(), dtn.getMonth(),dtn.getDate());
                        }
                    }
                })
            });
        });
    }

    ctrl.propertyDetailsCB = function(row){
        console.log(row);
        $state.go('PropertyDetails', {'id':row['id']});
    };
}

export default {
    name: 'SCTCController',
    fn: SCTCController
};
