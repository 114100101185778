function ItemsNeededToCloseDrillThroughCtrl($scope, $interpolate, $resource, $q, $stateParams, $previousState, ColumnDefinitionService, PreprocessDataService) {
    'ngInject';

    // ViewModel
    const ctrl = this;
    const uri = '/clientportal/api/';


    ctrl.back = function(){
        $previousState.go();
    };

    ctrl.hidePageSizer = true;

    let res_olad = $resource(uri + 'RPT_OutstandingLiensAndDemands/:id?format=json&nopage=1', {id:'@id'});
    let res_pending_seller = $resource(uri + 'RPT_PendingSeller/:id?format=json&nopage=1', {id:'@id'});
    let res_title_issues = $resource(uri + 'RPT_TitleIssuesInClosing/:id?format=json&nopage=1', {id:'@id'});
    let res_hud_pending = $resource(uri + 'RPT_HUDPendingRBIReviewApproval/:id?format=json&nopage=1', {id:'@id'});

    ctrl.cols_olad = ColumnDefinitionService.get("RPT_OutstandingLiensAndDemands");
    ctrl.cols_pending_seller = ColumnDefinitionService.get("RPT_PendingSeller");
    ctrl.cols_title_issues = ColumnDefinitionService.get("RPT_TitleIssuesInClosing");
    ctrl.cols_hud_pending = ColumnDefinitionService.get("RPT_HUDPendingRBIReviewApproval");


    let GetIDByATLoanNumber = $resource(uri + 'GetIDByATLoanNumber/:id?nopage=1&format=json', {id:'@id'});


    let populate_item_id = function(data){
        let deferred = $q.defer();
        let deferreds = [];


        angular.forEach(data, item =>{
            let _deferred = $q.defer();
            let loannum = null;

            if('LoanNumber' in item){
                loannum = item['LoanNumber'];
            }
            if('Loan_Number' in item){
                loannum = item['Loan_Number'];
            }
            if('loannumber' in item){
                loannum = item['loannumber'];
            }

            GetIDByATLoanNumber.query({'id':loannum}).$promise.then(extra_data => {
                if(extra_data.length < 1){
                    _deferred.resolve(item);
                    return;
                }
                // let merged = Object.assign(item, extra_data[0]);
                let merged = {};

                for (var attrname in item) { merged[attrname] = item[attrname]; }
                for (var attrname in extra_data[0]) { merged[attrname] = extra_data[0][attrname]; }
                console.log("MERGED");
                console.log(merged);
                _deferred.resolve(merged);
            });
            deferreds.push(_deferred.promise);
        });

        $q.all(deferreds).then(mergeddata => {
            deferred.resolve(mergeddata)
        });

        return deferred.promise;
    }

    res_olad.query().$promise.then(data => {
        $scope.$watch('ctrl.data_olad', () => {
            ctrl.data_olad_loaded = true;
        });

        populate_item_id(data).then(dataout => {
            ctrl.data_olad = dataout;
            ctrl.cols_olad.unshift({
                title: "Link",
                getValue: 'propertyDetailLinkValue',
            });
        });

        ctrl.data_olad = data;
    });
    res_pending_seller.query().$promise.then(data => {
        $scope.$watch('ctrl.data_pending_seller', () => {
            ctrl.data_pending_seller_loaded = true;
        });

        populate_item_id(data).then(dataout => {
            ctrl.data_pending_seller_loaded = dataout;
            ctrl.cols_pending_seller_loaded.unshift({
                title: "Link",
                getValue: 'propertyDetailLinkValue',
            });
        });

        ctrl.data_pending_seller = data;
    });
    res_title_issues.query().$promise.then(data => {
        $scope.$watch('ctrl.data_title_issues', () => {
            ctrl.data_title_issues_loaded = true;
        });

        populate_item_id(data).then(dataout => {
            ctrl.data_title_issues_loaded = dataout;
            ctrl.cols_title_issues_loaded.unshift({
                title: "Link",
                getValue: 'propertyDetailLinkValue',
            });
        });

        ctrl.data_title_issues = data;
    });
    res_hud_pending.query().$promise.then(data => {
        $scope.$watch('ctrl.data_hud_pending', () => {
            ctrl.data_hud_pending_loaded = true;
        });

        populate_item_id(data).then(dataout => {
            ctrl.data_hud_pending_loaded = dataout;
            ctrl.cols_hud_pending_loaded.unshift({
                title: "Link",
                getValue: 'propertyDetailLinkValue',
            });
        });

        ctrl.data_hud_pending = data;
    });
}

export default {
    name: 'ItemsNeededToCloseDrillThroughCtrl',
    fn: ItemsNeededToCloseDrillThroughCtrl
};
