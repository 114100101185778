let urlPrefix = "https://www.realtybid.com";
let urlAPIPrefix = "https://api.realtybid.com";
let urlAppServerPrefix = "https://app.realtybid.com"

if (window.location.href.includes('clientportal.dev.internal') ||
    window.location.href.includes('clientportaldev.realtybid.com') ||
    window.location.href.includes('localdev.realtybid.com') ||
    window.location.href.includes('localhost')) {
    urlPrefix = "https://dev.realtybid.com";
    urlAppServerPrefix = "https://appdev.realtybid.com"
    urlAPIPrefix = "https://apidev.realtybid.com";
}

//QA Support
if (window.location.href.includes('clientportal.qa.internal') ||
    window.location.href.includes('clientportalqa.realtybid.com')) {
    urlPrefix = "https://qa.realtybid.com";
    urlAppServerPrefix = "https://appdev.realtybid.com"
    urlAPIPrefix = "https://apiqa.realtybid.com";
}

//UAT Support
if (window.location.href.includes('clientportal.uat.internal') ||
    window.location.href.includes('clientportaluat.realtybid.com')) {
    urlPrefix = "https://uat.realtybid.com";
    urlAppServerPrefix = "https://appuat.realtybid.com"
    urlAPIPrefix = "https://apiuat.realtybid.com";
}

//BETA Support
if (window.location.href.includes('clientportal-beta.realtybid.com') ||
    window.location.href.includes('clientportalbeta.realtybid.com')) {
    urlPrefix = "https://beta.realtybid.com";
    urlAppServerPrefix = "https://app.realtybid.com"
    urlAPIPrefix = "https://api.realtybid.com";
}

let urlLogin = urlPrefix + "/login";

const AppSettings = {
    appTitle: 'Client Portal',
    apiUrl: '/api/v1',
    systemTimezone: 'US/Central',
    urlPrefix: urlPrefix,
    urlAPIPrefix: urlAPIPrefix,
    urlAppServerPrefix: urlAppServerPrefix,
    urlLogin: urlLogin
};

export default AppSettings;
