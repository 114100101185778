import moment from 'moment-timezone';

function PostForeclosureStatusDrillThroughCtrl($scope, $interpolate, $resource, $state, $stateParams, ColumnDefinitionService, NgTableParams, ItemidForLoanNumService, TitleDefinitionService) {
    'ngInject';

    let mapper = {
        "55+ Days": "1",
        "Less Than 45 Days": "2"
    };

    let parameter = $stateParams.parameter;
    let program=$stateParams.program;

    if(parameter in mapper){
        parameter = mapper[parameter];
    }

    console.log(parameter);


    // ViewModel
    const ctrl = this;
    const uri = '/clientportal/api/';

    ctrl.back = function(){
        $previousState.go();
    };

    ctrl.title = TitleDefinitionService.get(ctrl.report);
    ctrl.parameter = parameter;

    let PostForstatus_params = {
        1:"Sold",
        2:"Available",
        3:"Pending",
        4:"Hold",
        5:"Withdrawn",
        9: "In-Negotiations"
     //   6:"Removed",
     //   8:"Withdrawn"
      };



    if (moment(parameter, moment.ISO_8601, true).isValid()){
        ctrl.titleparam = moment(parameter, moment.ISO_8601, true).format('MM-DD-YYYY');
    }else{
        ctrl.titleparam = PostForstatus_params[ctrl.parameter];

    }

  

    if(ctrl.titleparam === '__all__'){
        ctrl.titleparam = 'All';
    }

    ctrl.hidePageSizer = true;

    // let res = $resource(uri + ctrl.report + '/:id?format=json&nopage=1', {id:'@id'});
    let res = $resource(uri + 'RPT_PostForeclosureStatusDrillThrough/:id&:program/?format=json&nopage=1', {
            id: '@id',
            'program': $stateParams.program});
    ctrl.cols = ColumnDefinitionService.get(ctrl.report);

     ctrl.tableParams = new NgTableParams({
        count: 15
    }, {
        filterDelay: 0
    });

    let link = {
        title: "Link",
        getValue: 'propertyDetailLinkValue',
    };

    if(ctrl.cols && ctrl.cols.length === 0){
        ctrl.cols = false;
    }else{
        let newcols = [];

        angular.forEach(ctrl.cols, function(val){
            if(val.title !== 'Link'){
                newcols.push(val);
            }
        });

        ctrl.cols = newcols;
        ctrl.cols.unshift(link);
    }
    res.query({id: parameter}).$promise.then(data => {
        Load_Data(data);
    });

    let GetIDByATLoanNumber = $resource(uri + 'GetIDByATLoanNumber/:id?nopage=1&format=json', {id:'@id'});

    //-------------------------------------
    function Load_Data(data) {
        ItemidForLoanNumService.get(data).then((data) => {
            console.log(data);
            ctrl.data = data;
            ctrl.tableParams.settings({
                dataset: ctrl.data
            });

            ctrl.data_loaded = true;

            ctrl.data['show_otherTextBox']=false;


            angular.forEach(ctrl.data, (data) => {
                angular.forEach(data, (value, key) => {
                    var dt=new Date(value);
                    if ( (value !==null) && dt !== "Invalid Date" && !isNaN(dt) ) {
                        var isZDate = value.toString().indexOf("00Z") >=0;
                        if (isZDate) {
                            var dtn = new Date(value);
                            data[key] = new Date(dtn.getFullYear(), dtn.getMonth(),dtn.getDate());
                        }
                    }
                })
            });
        });
    }
    ctrl.propertyDetailsCB = function(row){
        console.log(row);
        $state.go('PropertyDetails', {'id':row['id']});
    };

}

export default {
    name: 'PostForeclosureStatusDrillThroughCtrl',
    fn: PostForeclosureStatusDrillThroughCtrl
};
