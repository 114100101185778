import * as pbi from 'powerbi-client';
import _ from 'lodash';

function ChartsCtrl($scope, $interpolate, $resource, $q, $state, $http, $sce) {
    'ngInject'

    //First thing we're going to do is make an ajax call to get our token.
    var newCss = {'max-width': '100%', 'width': '100%'};
    var embedToken = '';
    $.ajax({
        dataType: "json",
        url: '/clientportal/api/retrieve_token',
        data: {},
        type: "GET",
        success: function(response){
            embedToken = response.embedToken
            
            //Onload, animation to widen the page.
            $('main').css({transition: 'max-width 1s ease-in-out'})
            $('main').css({'max-width': '100%'})
            embedChart(embedToken);
        }
    })

    function embedChart(embedToken){
        
        // Read embed application token from textbox
        var txtAccessToken = embedToken;  // Read embed URL from textbox
        var txtEmbedUrl = "https://app.powerbi.com/reportEmbed?reportId=89ec3fb0-7eb3-43ea-97ef-561dc0edcce8&groupId=11382b8d-3aa5-408c-9191-c63b2fa3d600&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLUVBU1QtQS1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJtb2Rlcm5FbWJlZCI6dHJ1ZX19"
        
        // Read report Id from textbox
        var txtEmbedReportId = "89ec3fb0-7eb3-43ea-97ef-561dc0edcce8";
        // Read embed type from radio
        var tokenType = 1

        // Get models. models contains enums that can be used.
        // var models = window['powerbi-client'].models;

        // We give All permissions to demonstrate switching between View and Edit mode and saving report.
        var permissions = 1

        // Embed configuration used to describe the what and how to embed.
        // This object is used when calling powerbi.embed.
        // This also includes settings and options such as filters.
        // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Embed-Configuration-Details.
        var config = {
            type: 'report',
            tokenType: tokenType,
            accessToken: txtAccessToken,
            embedUrl: txtEmbedUrl,
            id: txtEmbedReportId,
            permissions: permissions,
            pageView: 'fitToWidth',
            settings: {
            panes: {
                filters: {
                visible: false
                },
                pageNavigation: {
                visible: false
                }
            }
            }
        };

        // Get a reference to the embedded report HTML element
        var embedContainer = $('#embedContainer')[0];

        // Embed the report and display it within the div container.
        var report = powerbi.embed(embedContainer, config);

        // Report.off removes a given event handler if it exists.
        report.off("loaded");

        // Report.on will add an event handler which prints to Log window.
        report.on("loaded", function () {
            console.log("Loaded");
        });

        // Report.off removes a given event handler if it exists.
        report.off("rendered");

        // Report.on will add an event handler which prints to Log window.
        report.on("rendered", function () {
            console.log("Rendered");
        });

        report.on("error", function (event) {
            console.log(event.detail);

            report.off("error");
        });

        report.off("saved");
        report.on("saved", function (event) {
            console.log(event.detail);
            if (event.detail.saveAs) {
                console.log('In order to interact with the new report, create a new token and load the new report');
            }
        });
    }

    // // ViewModel
    // const ctrl = this;
    // const uri = '/clientportal/api/';

    // ctrl.hidePageSizer = true;

    // let params = {}

    // ctrl.url = uri+'export_charts/';

    // // $http.get(uri+'export_charts',params, {responseType:'blob'})
    // //     .success(function (response) {
    // //         var file = new Blob([response], {type: 'application/pdf'});
    //         // var fileURL = URL.createObjectURL(response);
    //         // $scope.content = $sce.trustAsResourceUrl(fileURL);
    //     // });

    // // todo: HUD Pending ADC Review/Approval
    // // todo: Items Needed to Close
}

export default {
    name: 'ChartsCtrl',
    fn: ChartsCtrl
};
