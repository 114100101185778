window.moment = require('moment');
import jQuery from 'jquery';
window.$ = window.jQuery = require("jquery");
require('jquery-ui');
import 'bootstrap';
import pdfobject from 'pdfobject';
window.pdf = pdfobject;

window.cookiepaste = function(){
    var cookies = document.cookie.split(";");
    var pre = '';
    cookies.forEach(function(element){
        var str = "document.cookie = '" + element + "';"
        pre = pre + str + '\n';
    });
    var newWindow = window.open("about:blank", "", "_blank");
    newWindow.document.write('<html><body>Paste the following into the javascript debug console: <pre>'+pre+'</pre></body></html>');
};

console.log(moment.now());
// import 'nodep-date-input-polyfill';

import angular from 'angular';

import {ngTable} from 'ng-table';
import {ngResource} from 'angular-resource';
import {ngAnimate} from 'angular-animate';
import {cgBusy} from 'angular-busy';
import {ngCookies} from 'angular-cookies';
import {uiextras} from 'ui-router-extras';
import {angularloadingbar} from 'angular-loading-bar';
import {stateloader} from 'angular-state-loader';
// import {datetimePicker} from 'angularjs-datetime-picker';
import {modalService} from 'angular-modal-service';
import {datetimeinput} from 'angular-date-time-input';

// angular modules
import constants from './constants';
import onConfig  from './on_config';
import onRun     from './on_run';
import 'angular-ui-router';
import './templates';
import './filters';
import './controllers';
import './controllers/drillthrough';
import './services';
import './factories';
import './directives';
import offClick from "./directives/offClick.js";

// create and bootstrap application
const requires = [
    'ui.router',
    'templates',
    'app.filters',
    'app.controllers',
    'app.controllers.drillthrough',
    'app.services',
    'app.factories',
    'app.directives',
    'ngTable',
    'ngResource',
    'ngAnimate',
    'ngCookies',
    'cgBusy',
    'ct.ui.router.extras',
    'angular-loading-bar',
    'ec.stateloader',
    // 'angularjs-datetime-picker',
    'angularModalService',
    'ui.dateTimeInput'
];


// mount on window for testing
window.app = angular.module('app', requires);

angular.module('app').constant('AppSettings', constants);

angular.module('app').config(onConfig);

//angular.module('app').directive('offClick', ['$document', '$parse', '$timeout', offClick]);
angular.module('app').directive('offClick', ['$document', '$parse', '$timeout', function ($document, $parse, $timeout) {
    return {
        restrict: 'A',
        compile: function(tElement, tAttrs) {
            var fn = $parse(tAttrs.offClick);

            return function(scope, iElement, iAttrs) {
                function eventHandler(ev) {
                    if (iElement[0].contains(ev.target)) {
                        $document.one('click touchend', eventHandler);
                    } else {
                        scope.$apply(function() {
                          fn(scope);
                        });
                    }
                }
                scope.$watch(iAttrs.offClickActivator, function(activate) {
                    if (activate) {
                        $timeout(function() {
                            // Need to defer adding the click handler, otherwise it would
                            // catch the click event from ng-click and trigger handler expression immediately
                            $document.one('click touchend', eventHandler);
                        });
                    } else {
                        $document.off('click touchend', eventHandler);
                    }
                });
            };
        }
    };
}]);

angular.module('app').run(onRun);

angular.bootstrap(document, ['app'], {
    strictDi: true
});
