function PDFDirective() {
    'ngInject';

    return {
        replace: 'true',
        scope:{
            url : "@url",
            height: "@height",
            width: "@width"
        },
        template: '<div id="pdf"><div>',
        link: function (scope, elem, attrs) {
            console.log(window.pdf);
            var success = window.pdf.embed(scope.url,elem, {width:'100%',height:'100%',fallbackLink:scope.url, forcePDFJS:true});
            console.log(success);
        }
    };
}

export default {
    name: 'pdf',
    fn: PDFDirective
};
