function SessionExpiredModalCtrl($scope, $rootScope, $window, $timeout, $http) {
    'ngInject';
    $scope.hide_expired_message = true;

    $scope.logout = function () {
        $window.location.href = $rootScope.urlLogin;
    }

    $scope.onTimeout = function(){
        $rootScope.sessionTimeout--;
        if($rootScope.sessionTimeout < 31) {
            $scope.hide_expired_message = false;
        }
        if($rootScope.sessionTimeout == 0) {
            $scope.logout();
        }
        mytimeout = $timeout($scope.onTimeout,1000);
    }
    var mytimeout = $timeout($scope.onTimeout,1000);



    $scope.extend_session = function() {
        $rootScope.sessionTimeout = 300; // 5 minutes

        $http({
            method: 'GET',
            url: $rootScope.urlAPIPrefix + '/session/sessioninfo',
            withCredentials: true
        }).then(function successCallback(response) {
            if (response.status === 200) {
                $scope.hide_expired_message = true;
            }
            if (response.status === 401 ||
                response.status === 403 ||
                response.status === 500) {
                $window.location.href = $rootScope.urlLogin;
            }
        }, function errorCallback(response) {
            console.log(response.data.MESSAGE);
            if ($window.location.pathname !== '/login') {
                $window.location.href = $rootScope.urlLogin;
            }
        });
    }
}

export default {
    name: 'SessionExpiredModalCtrl',
    fn: SessionExpiredModalCtrl
};
