import moment from 'moment-timezone';
import appSettings from '../constants';
import _ from 'lodash';

function DailyTasksCtrl($scope, $rootScope, $interpolate, $resource, $q, $state) {
    'ngInject';

    $('main').css({'max-width': '1060px'});

    const ctrl = this;
    ctrl.agent='';
    const uri = '/clientportal/api/';

    let currentSystemTime = moment().tz(appSettings.systemTimezone);


    // ViewModel

    ctrl.hidePageSizer = true;

// Define the isLoaded variables for each query
    ctrl.pendingRemovalConfirmation_isLoaded = false;
    ctrl.reviewForHUDExtension_isLoaded = false;
    ctrl.lockboxIssuesPendingCorrection_isLoaded = false;
    ctrl._2ndO_isLoaded = false;
    ctrl._2ndC_isLoaded = false;
    ctrl._ProvidedReserveNotMatchingCalculated_isLoaded = false;
    ctrl.offerReceivedNoClosingSet_isLoaded = false;
    // todo: Provided Reserve Not Matching Calculated
    ctrl.deedRecorededException_isLoaded = false;  // Yes, recoreded
    ctrl.missingReserveOrValuation_isLoaded = false;
    ctrl.newContract_isLoaded = false;

    let resAgent = $resource(uri + 'AmsFeatureAccess/?format=json&nopage=1');
    resAgent.query().$promise.then(data => {

     ctrl.agent = data[0]['agent_id'];
     let rpt_postAuctionClientReview = $resource(uri+'RPT_PostAuctionClientReview/:id?format=json&nopage=1', {id:'@id','AgentId':ctrl.agent});
     rpt_postAuctionClientReview.query().$promise.then(data => {
         Load_PostAuctionClientReviewData($scope,ctrl,data);
     });
     let rpt_pendingRemovalConfirmation = $resource(uri+'RPT_PendingRemovalConfirmation/:id?format=json&nopage=1', {id:'@id','AgentId':ctrl.agent});
     rpt_pendingRemovalConfirmation.query().$promise.then(data => {
        Load_PendingRemovalConfirmation($scope, ctrl, data);
    });



// Execute the queries
//     rpt_pendingRemovalConfirmation.query({'AgentId':ctrl.agent}).$promise.then(data => {
//         Load_PendingRemovalConfirmation($scope, ctrl, data);
//     });
    /*rpt_ReviewForHUDExtension.query().$promise.then(data => {*/
    /*    Load_ReviewForHUDExtension($scope, ctrl, data);*/
    /*});*/
    let rpt_lockboxIssuesPendingCorrection = $resource(uri+'RPT_LockboxIssuesPendingCorrection/:id?format=json&nopage=1',{id:'@id','AgentId':ctrl.agent});
    rpt_lockboxIssuesPendingCorrection.query().$promise.then(data => {
        Load_LockboxIssuesPendingCorrection($scope, ctrl, data);
    });
    let rpt_2ndO = $resource(uri+'RPT_PendingSellerExecution/:id?format=json&nopage=1', {id:'@id','AgentId':ctrl.agent});
    rpt_2ndO.query().$promise.then(data => {
        Load_2ndO($scope, ctrl, data);
    });
    let rpt_ProvidedReserveNotMatchingCalculated = $resource(uri+'RPT_ProvidedReserveNotMatchingCalculated/:id?format=json&nopage=1', {id:'@id','AgentId':ctrl.agent});
    rpt_ProvidedReserveNotMatchingCalculated.query().$promise.then(data => {
        Load_ProvidedReserveNotMatchingCalculated($scope, ctrl, data);
    });
     let rpt_2ndC = $resource(uri+'RPT_ScheduledClosing/:id?format=json&nopage=1', {id:'@id','AgentId':ctrl.agent});
    rpt_2ndC.query().$promise.then(data => {
        Load_2ndC($scope, ctrl, data);
    });
    let rpt_MissingReserveOrValuation = $resource(uri+'RPT_MissingReserveOrValuation/:id?format=json&nopage=1', {id:'@id','AgentId':ctrl.agent});
    rpt_MissingReserveOrValuation.query().$promise.then(data => {
        Load_MissingReserveOrValuation($scope, ctrl, data);
    });
    let rpt_newContracts = $resource(uri+'RPT_NewContractsReport/:id?format=json&nopage=1', {id: '@id', 'AgentId': ctrl.agent});
    rpt_newContracts.query().$promise.then(data=>{
        console.log("debug DATA: ", data);
        Load_newContracts($scope, ctrl, data);
    });
    });

    ctrl.postAuctionReview_cols = [
        {
            field: "PostAuctionEndDate",
            title: "Post Auction End Date",
            filter: {PostAuctionEndDate: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY"
        },
        {
            field: "RecordCount",
            title: "Count",
            filter: {RecordCount: "text"}
        }

    ];
     ctrl.postAuctionClientReviewCB = function(row){
        $state.go('PostAuctionClientReview', {'parameter':row['PostAuctionEndDate']});
    };
// Define the context variables
    ctrl.pendingRemovalConfirmation_cols = [
        {
            field: "RemovalConfirmationCycleTime",
            title: "Removal Confirmation Cycle Time",
            filter: {RemovalConfirmationCycleTime: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY"
        },
        {
            field: "RecordCount",
            title: "Count",
            filter: {RecordCount: "text"}
        }
    ];
    ctrl.pendingRemovalConfirmationCB = function(row){
        console.log(row);
        $state.go('PendingRemovalConfirmation', {'parameter':row['RemovalConfirmationCycleTime']});
    };


    ctrl.reviewForHUDExtension_cols = [
        {
            field: "DaysSince",
            title: "Days Since FCL Deed Recorded",
            filter: {DaysSince: "text"}
        },
        {
            field: "RecordCount",
            title: "Count",
            filter: {RecordCount: "text"}
        }
    ];
   /* ctrl.reviewForHUDExtensionCB = function(row){*/
   /*     console.log(row);*/
   /*     let param = null;*/
/*
*/

   /*     if(row['DaysSince'] === "Less than 45 Days"){*/
   /*         param = 2;*/
   /*     }else if(row['DaysSince'] === "55+ Days"){*/
   /*         param = 1;*/
   /*     }*/
   /*     $state.go('DrillThrough', {'report':'RPT_ReviewForHUDExtension','parameter':param});*/
   /* };*/
/*
*/

/*
*/

    ctrl.lockboxIssuesPendingCorrection_cols = [
        {
            field: "AssetLockboxIssueDate",
            title: "Lockbox Issue Date",
            filter: {AssetLockboxIssueDate: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "AssetLockboxIssueDate"
        },
        {
            field: "RecordCount",
            title: "Count",
            filter: {RecordCount: "text"}
        }
    ];
    ctrl.lockboxIssuesPendingCorrectionCB = function(row){
        $state.go('LockBoxIssueDate', {'parameter':row['AssetLockboxIssueDate']});
        // $state.go('LockBoxIssueDate', {'parameter':row['AssetLockboxIssueDate'],'AgentId':ctrl.agent});
    };


    ctrl._2ndO_cols = [
        {
            field: "ContractSentTSellerDate",
            title: "Contract From Seller",
            filter: {ChangeDate: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "ChangeDate"
        },
        {
            field: "RecordCount",
            title: "Count",
            filter: {RecordCount: "text"}
        }
    ];
    ctrl._2ndOCB = function(row){
        $state.go('PendingSellerExecution', {'parameter':row['ContractSentTSellerDate']});
        // $state.go('2ndo', {'parameter':row['ContractSentTSellerDate'],'AgentId':ctrl.agent});
    };


    ctrl._ProvidedReserveNotMatchingCalculated_cols = [
        {
            field: "ScheduledSaleDate",
            title: "Scheduled Sale Date",
            filter: {ScheduledSaleDate: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "ScheduledSaleDate"
        },
        {
            field: "RecordCount",
            title: "Count",
            filter: {RecordCount: "text"}
        }
    ];
    ctrl._ProvidedReserveNotMatchingCalculatedCB = function(row){
        console.log(row);
        $state.go('DrillThrough', {'report':'RPT_ProvidedReserveNotMatchingCalculatedDrillThrough','parameter':row['ScheduledSaleDate']});
        // $state.go('DrillThrough', {'report':'RPT_ProvidedReserveNotMatchingCalculated','parameter':row['ScheduledSaleDate'],'AgentId':ctrl.agent});
    };


    ctrl._2ndC_cols = [
        {
            field: "ScheduledClosingDate",
            title: "Scheduled Closing Date",
            filter: {ChangeDate: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "ScheduledClosingDate"
        },
        {
            field: "RecordCount",
            title: "Count",
            filter: {RecordCount: "text"}
        }
    ];
    ctrl._2ndCCB = function(row){
        $state.go('ScheduledClosing', {'parameter':row['ScheduledClosingDate']});
        //$state.go('2ndc', {'parameter':row['ScheduledClosingDate'],'AgentId':ctrl.agent});
    };

    //New Contracts Drillthrou gh
    ctrl.newContract_cols = [
        {
            field: "NewContracts",
            title: "Executed Contract Received",
            filter: {ChangeDate: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "NewContracts"
        },
        {
            field: "RecordCount",
            title: "Count",
            filter: {RecordCount: "text"}
        }
    ];
    ctrl.newContractCCB = function(row){
        $state.go('NewContractsDrill', {'parameter':row['NewContracts']});
        //$state.go('2ndc', {'parameter':row['ScheduledClosingDate'],'AgentId':ctrl.agent});
    };


   /* ctrl.offerReceivedNoClosingSet_cols = [*/
   /*     {*/
   /*         field: "daysdeed",*/
   /*         title: "Days Since FCL Deed Recorded",*/
   /*         filter: {daysdeed: "text"}*/
   /*     },*/
   /*     {*/
   /*         field: "RecordCount",*/
   /*         title: "Count",*/
   /*         filter: {RecordCount: "text"}*/
   /*     }*/
   /* ];*/
   /* ctrl.offerReceivedNoClosingSetCB = function(row){*/
   /*     console.log(row);*/
   /*     $state.go('DrillThrough', {'report':'RPT_OfferReceivedNoClosingSet','parameter':row['daysdeed']});*/
   /* };*/


    // todo: Provided Reserve Not Matching Calculated
    // ctrl.deedRecorededException_cols = [  // Yes, recoreded
    //     {
    //         field: "DeedRecordedDate",
    //         title: "Deed Recorded Date",
    //         filter: {DeedRecordedDate: "text"},
    //         getValue: 'momentValue',
    //         momentFormat: "MM-DD-YYYY",
    //         sortable: "DeedRecordedDate"
    //     },
    //     {
    //         field: "RecordCount",
    //         title: "Count",
    //         filter: {RecordCount: "text"}
    //     }
    // ];
    // ctrl.deedRecorededExceptionCB = function(row){
    //     console.log(row);
    //     $state.go('DrillThrough', {'report':'RPT_DeedRecorededException','parameter':row['DeedRecordedDate']});
    // };
    //

    ctrl.missingReserveOrValuation_cols = [
        {
            field: "Type",
            title: "Missing Reserves or Valuations",
            filter: {Type: "text"}
        },
        {
            field: "Count",
            title: "Count",
            filter: {Count: "text"}
        }
    ];
    ctrl.missingReserveOrValuationCB = function(row){
        $state.go('DrillThrough', {'report':'RPT_MissingReserveOrValuationDrillThrough','parameter':row['Type']});
    };


}

export default {
    name: 'DailyTasksCtrl',
    fn: DailyTasksCtrl
};


// Define Load Functions

function Load_PendingRemovalConfirmation($scope, ctrl, data) {
    $scope.$watch('ctrl.pendingRemovalConfirmationData', () => {
        ctrl.pendingRemovalConfirmation_isLoaded = true;
        ctrl.pendingRemovalConfirmation_total = _.reduce(ctrl.pendingRemovalConfirmationData, (total, record) => {
            total += record.RecordCount;
            return total;
        }, 0);
    });

    ctrl.pendingRemovalConfirmationData = data;
}

function convertKey(from) {
    if (from === "GreaterOrEqualTo55")
        return '55+ Days';
    else if (from === "LessThan45")
            return 'Less than 45 Days';
    else return from;

    }
//-------------------------------------
// NOTE: The data received cannot be readily used in columnar table
//       Must do some contrived pivot op to get this to work...
function Load_ReviewForHUDExtension($scope, ctrl, data) {
    $scope.$watch('ctrl.rawReviewForHUDExtensionData', () => {
        ctrl.reviewForHUDExtension_isLoaded = true;

        let table = [];
        ctrl.reviewForHUDExtension_total = _.reduce(ctrl.rawReviewForHUDExtensionData, (total, record) => {
            let keys = Object.keys(record);
            for(var i = 0; i < keys.length; i++) {
                let key = keys[i];
                table.push({"DaysSince": convertKey(keys[i]), "RecordCount": record[key]});
                total += record[key];

                console.log(keys[i]);

            }
            return total;
        }, 0);

        ctrl.reviewForHUDExtensionData = table;
    });

    ctrl.rawReviewForHUDExtensionData = data;
}

//-----------------------------------------------------------------
function Load_PostAuctionClientReviewData($scope,ctrl,data) {
    $scope.$watch('ctrl.postAuctionClientReviewData', () => {
        ctrl.postAuctionClientReview_isLoaded = true;
        ctrl.postAuctionClientReview_total = _.reduce(ctrl.postAuctionClientReviewData, (total, record) => {
            total += record.RecordCount;
            return total;
        }, 0);
    });

    ctrl.postAuctionClientReviewData = data;
}

//--------------------------------------------------------------------------

function Load_LockboxIssuesPendingCorrection($scope, ctrl, data) {
    $scope.$watch('ctrl.lockboxIssuesPendingCorrectionData', () => {
        ctrl.lockboxIssuesPendingCorrection_isLoaded = true;
        ctrl.lockboxIssuesPendingCorrection_total = _.reduce(ctrl.lockboxIssuesPendingCorrectionData, (total, record) => {
            total += record.RecordCount;
            return total;
        }, 0);
    });

    ctrl.lockboxIssuesPendingCorrectionData = data;
}

//-------------------------------------
function Load_2ndO($scope, ctrl, data) {
    $scope.$watch('ctrl._2ndOData', function() {
        ctrl._2ndO_isLoaded = true;
        ctrl._2ndO_total = _.reduce(ctrl._2ndOData, (total, record) => {
            total += record.RecordCount;
            return total;
        }, 0);
    });

    ctrl._2ndOData = data;
}

//-------------------------------------
function Load_ProvidedReserveNotMatchingCalculated($scope, ctrl, data) {
    $scope.$watch('ctrl._ProvidedReserveNotMatchingCalculatedData', function() {
        ctrl._ProvidedReserveNotMatchingCalculated_isLoaded = true;
        ctrl._ProvidedReserveNotMatchingCalculated_total = _.reduce(ctrl._ProvidedReserveNotMatchingCalculatedData, (total, record) => {
            total += record.RecordCount;
            return total;
        }, 0);
    });

    ctrl._ProvidedReserveNotMatchingCalculatedData = data;
}

//-------------------------------------
function Load_2ndC($scope, ctrl, data) {
    $scope.$watch('ctrl._2ndCData', () => {
        ctrl._2ndC_isLoaded = true;
        ctrl._2ndC_total = _.reduce(ctrl._2ndCData, (total, record) => {
            total += record.RecordCount;
            return total;
        }, 0);
    });

    ctrl._2ndCData = data;
}

//-------------------------------------
function Load_OfferReceivedNoClosingSet($scope, ctrl, data) {
    $scope.$watch('ctrl.offerReceivedNoClosingSetData', () => {
        ctrl.offerReceivedNoClosingSet_isLoaded = true;
        ctrl.offerReceivedNoClosingSet_total = _.reduce(ctrl.offerReceivedNoClosingSetData, (total, record) => {
            total += record.RecordCount;
            return total;
        }, 0);
    });

    ctrl.offerReceivedNoClosingSetData = data;
}

// todo: Provided Reserve Not Matching Calculated

//-------------------------------------
function Load_DeedRecorededException($scope, ctrl, data) {
    $scope.$watch('ctrl.deedRecorededExceptionData', () => {
        ctrl.deedRecorededException_isLoaded = true;
        ctrl.deedRecorededException_total = _.reduce(ctrl.deedRecorededExceptionData, (total, record) => {
            total += record.RecordCount;
            return total;
        }, 0);
    });

    ctrl.deedRecorededExceptionData = data;
}


//-------------------------------------
function Load_newContracts($scope, ctrl, data) {
    $scope.$watch('ctrl.newContractData', function() {
        ctrl.newContract_isLoaded = true;
        ctrl.newContract_total = _.reduce(ctrl.newContractData, (total, record) => {
            total += record.RecordCount;
            return total;
        }, 0);
    });

    ctrl.newContractData = data;
}

//-------------------------------------
// NOTE: The data received cannot be readily used in columnar table
//       Must do some contrived pivot op to get this to work...
function Load_MissingReserveOrValuation($scope, ctrl, data) {
    $scope.$watch('ctrl.missingReserveOrValuationData', () => {
        ctrl.missingReserveOrValuation_isLoaded = true;
         ctrl.missingReserveOrValuation_total = _.reduce(ctrl.missingReserveOrValuationData, (total, record) => {
            total += record.Count;
            return total;
        }, 0);

       /* let names = {
            'Missing Reserve':
                {'name':'Missing Reserve','id':1}

            /!*'BothCount':
                {'name':'Both','id':3},
            'ValuationCount':
                {'name':'Value','id':2},*!/
        };

        let table = [];*/
        /*ctrl.missingReserveOrValuation_total = _.reduce(ctrl.rawMissingReserveOrValuationData, (total, record) => {
            let keys = Object.keys(record);
            console.log("Object.keys(record)",Object.keys(record))
            for(var i = 0; i < keys.length; i++) {
                let key = keys[i];
                console.log("key",names[key]);
                table.push({"Name": names[key]['name'],"id": names[key]['id'], "RecordCount": record[key]});
                total += record[key];
            }
            return total;
        }, 0);
        ctrl.missingReserveOrValuationData = table;*/
    });

    ctrl.missingReserveOrValuationData = data;
}
