//Column definitions for drillthroughs
function FileUploadService($http) {
    'ngInject';

    const service = {};

    // service.get = function (report) {
    //     if (!this[report]) {
    //         return [];
    //     }
    //     return this[report];
    // };
    //
    // return service;

       service.get = function(file, uploadUrl){
        var fd = new FormData();
        fd.append('file', file);
        $http.post(uploadUrl, fd,{
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        })
        ,function(success){
        }
        ,function(error){
        };
    }
    return service;
}

export default {
    name: 'FileUploadService',
    fn: FileUploadService
};
