import data from '../data/data.js';
import _ from 'lodash';

function ProgramManagersCtrl($scope, $interpolate, $resource, $q, $state) {
    'ngInject';

    // ViewModel
    const ctrl = this;
    const uri = '/clientportal/api/';

    ctrl.hidePageSizer = true;

// Define the isLoaded variables for each query
    // todo: SCTCDate Date
    // todo: Closing Deeds to be Executed
    // todo: Outstanding Liens and Demands
    // todo: Prelim HUD Requiring Approval
    ctrl._2ndA_isLoaded = false;
    ctrl._2ndS_isLoaded = false;
    // ctrl._SCTCDate_isLoaded = false;
    // ctrl._SCTCDate_isLoaded = false;
    ctrl._OutstandingLiensAndDemands_isLoaded = false;
    ctrl._OfferCanacellation_isLoaded = false;  // Yes, canacellation
    ctrl._ClosingDeedsToBeExecuted_isLoaded = false;  // Yes, canacellation
    ctrl._HUDPendingRBIReviewApproval_isLoaded = false;  // Yes, canacellation
    // todo: Offer Postponement
    // todo: Title Issues in Closing
    ctrl.pendingSeller_isLoaded = false;
    // todo: HUD Pending ADC Review/Approval
    // todo: Items Needed to Close

    ctrl._ItemsNeededToClose_Control = {};

// Define the queries
    // todo: SCTCDate Date
    // todo: Closing Deeds to be Executed
    // todo: Outstanding Liens and Demands
    // todo: Prelim HUD Requiring Approval
    let resAgent = $resource(uri + 'AmsFeatureAccess/?format=json&nopage=1');
    resAgent.query().$promise.then(data => {

        ctrl.agent = data[0]['agent_id'];
        console.log("ctrl.agent inprogramManager", ctrl.agent);
        console.log("ctrl.agent inprogramManager", ctrl.agent);
        let rpt_2ndA = $resource(uri + 'RPT_ClosingPending/:id?format=json&nopage=1', {
            id: '@id',
            'AgentId': ctrl.agent
        });
        let rpt_2ndS = $resource(uri + 'RPT_PendingfundingConfirmations/:id?format=json&nopage=1', {
            id: '@id',
            'AgentId': ctrl.agent
        });
        // let rpt_SCTCDate = $resource(uri+'RPT_SCTCDate/:id?format=json&nopage=1', {id:'@id'});
        let rpt_OfferPostponement = $resource(uri + 'RPT_ClosingDatePostponement/:id?format=json&nopage=1', {
            id: '@id',
            'AgentId': ctrl.agent
        });
        let rpt_OfferCanacellation = $resource(uri + 'RPT_OfferCanacellation/:id?format=json&nopage=1', {
            id: '@id',
            'AgentId': ctrl.agent
        });
        let rpt_OutstandingLiensAndDemands = $resource(uri + 'RPT_OutstandingLiensAndDemands/:id?format=json&nopage=1', {
            id: '@id',
            'AgentId': ctrl.agent
        });
        let rpt_TitleIssuesInClosing = $resource(uri + 'RPT_TitleIssuesInClosing/:id?format=json&nopage=1', {
            id: '@id',
            'AgentId': ctrl.agent
        });
        let rpt_ClosingDeedsToBeExecuted = $resource(uri + 'RPT_ClosingDeedsToBeExecuted/:id?format=json&nopage=1', {
            id: '@id',
            'AgentId': ctrl.agent
        });
        // let rpt_HUDPendingRBIReviewApproval = $resource(uri+'RPT_HUDPendingRBIReviewApproval/:id?format=json&nopage=1', {id:'@id','AgentId':'253076991'});
        let rpt_PrelimHudRequiringApproval = $resource(uri + 'RPT_PrelimHudRequiringApproval/:id?format=json&nopage=1', {
            id: '@id',
            'AgentId': ctrl.agent
        });
        // todo: Offer Postponement
        // todo: Title Issues in Closing
        let rpt_PendingSeller = $resource(uri + 'RPT_PropertyIssues/:id?format=json&nopage=1', {
            id: '@id',
            'AgentId': ctrl.agent
        });
        // todo: HUD Pending ADC Review/Approval

        //Needed for total for Items Needed to close
        let rpt_2ndO = $resource(uri + 'RPT_2ndO/:id?format=json&nopage=1', {id: '@id', 'AgentId': ctrl.agent});
          rpt_2ndO.query().$promise.then(data => {
        ctrl._2ndO_total = _.reduce(data, (total, record) => {
            total += record.RecordCount;
            return total;
        }, 0);

        let total = Number(ctrl._2ndO_total);

        if(isNaN(total)){
            total = 0;
        }

        let idx = _.findIndex(ctrl._ItemsNeededToCloseData, function(o) { return o['name'] == 'Pending Seller Executed Contract'; });
        ctrl._ItemsNeededToCloseData[idx]['total'] = total;

        // let total = Number(ctrl._2ndO_total)
        //
        // if(isNaN(total)){
        //     total = 0;
        // }
        //
        // ctrl._ItemsNeededToCloseData.push({
        //     'name': 'Pending Seller Executed Contract',
        //     'total': total
        // });
        //
        // ctrl._ItemsNeededToClose_Control.reload();
    });

// Execute the queries
    // todo: SCTCDate Date
    // todo: Closing Deeds to be Executed
    // todo: Outstanding Liens and Demands
    // todo: Prelim HUD Requiring Approval
    rpt_2ndA.query().$promise.then(data => {
        Load_2ndA($scope, ctrl, data);
    });
    rpt_2ndS.query().$promise.then(data => {
        Load_2ndS($scope, ctrl, data);
    });
    // rpt_SCTCDate.query().$promise.then(data => {
    //     Load_SCTCDate($scope, ctrl, data);
    // });
    rpt_OfferPostponement.query().$promise.then(data => {
        Load_OfferPostponement($scope, ctrl, data);
    });
    rpt_OutstandingLiensAndDemands.query().$promise.then(data => {
        Load_OutstandingLiensAndDemands($scope, ctrl, data);
    });
    rpt_TitleIssuesInClosing.query().$promise.then(data => {
        Load_TitleIssuesInClosing($scope, ctrl, data);
    });
    rpt_OfferCanacellation.query().$promise.then(data => {  // Yes, canacellation
        Load_OfferCanacellation($scope, ctrl, data);
    });
    rpt_ClosingDeedsToBeExecuted.query().$promise.then(data => {
        Load_ClosingDeedsToBeExecuted($scope, ctrl, data);
    });
    // rpt_HUDPendingRBIReviewApproval.query().$promise.then(data => {
    //     Load_HUDPendingRBIReviewApproval($scope, ctrl, data);
    // });
    // todo: Offer Postponement
    // todo: Title Issues in Closing
    rpt_PendingSeller.query().$promise.then(data => {
        Load_PendingSeller($scope, ctrl, data);
    });
    rpt_PrelimHudRequiringApproval.query().$promise.then(data => {
        Load_PrelimHudRequiringApproval($scope, ctrl, data);
    });

    });
    ctrl._ItemsNeededToCloseData = [
        {
            'name': 'Outstanding Liens and Demands',
            'total': 0
        },
        {
            'name': 'Pending Seller Executed Contract',
            'total': 0
        },
        {
            'name': 'Title Issues In Closing',
            'total': 0
        },
        {
            'name': 'HUD Pending RBI Review Approval',
            'total': 0
        },
        {
            'name': 'Pending Seller Executed Deed',
            'total': 0
        },
    ];


    ctrl._2ndO_total = null;


    // todo: HUD Pending ADC Review/Approval
    // todo: Items Needed to Close

// Define the context variables
    // todo: SCTCDate Date
    // todo: Closing Deeds to be Executed
    // todo: Outstanding Liens and Demands
    // todo: Prelim HUD Requiring Approval

    $scope.uploadFile = function() {

               var file = $scope.myFile;
               console.log('file is ' );
               console.dir(file);
               var uploadUrl = "/fileUpload";
               fileUpload.uploadFileToUrl(file, uploadUrl);
            };
    ctrl._2ndA_cols = [
        {
            field: "ActualCloseDate",
            title: "ActualCloseDate",
            filter: {ActualCloseDate: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "DeedSent"
        },
        {
            field: "RecordCount",
            title: "Count",
            filter: {RecordCount: "text"}
        }
    ];
    ctrl._2ndACB = function(row){
        $state.go('ClosingPending', {'parameter':row['ActualCloseDate']});
    };



    ctrl._OfferPostponement_cols = [
        {
            field: "ClosingExtensionDate",
            title: "Closing Extension Date",
            filter: {ClosingExtensionDate: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "ClosingExtensionDate"
        },
        {
            field: "RecordCount",
            title: "Count",
            filter: {RecordCount: "text"}
        }
    ];
    ctrl._OfferPostponementCB = function(row){
        console.log(row);
        $state.go('OfferPostponement',{'parameter':row['ClosingExtensionDate']});
    };


    ctrl._2ndS_cols = [
        {
            field: "ActualClosingDate",
            title: "ActualClosingDate",
            filter: {ActualClosingDate: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "SellerWiredDate"
        },
        {
            field: "Count",
            title: "Count",
            filter: {Count: "text"}  // Can't make a js variable called 2ndSCount
        }
    ];
    ctrl._2ndSCB = function(row){
        $state.go('PendingFundingConfirmation', {'parameter':row['ActualClosingDate']});
    };


    ctrl._OutstandingLiensAndDemands_cols = [
        {
            field: "DemandReceivedDate",
            title: "Demand Received Date",
            filter: {DemandReceivedDate: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "DemandReceivedDate"
        },
        {
            field: "RecordCount",
            title: "Count",
            filter: {RecordCount: "text"}  // Can't make a js variable called 2ndSCount
        }
    ];
    ctrl._OutstandingLiensAndDemandsCB = function(row){
        console.log(row);
        $state.go('OLAD', {'parameter':row['DemandReceivedDate']});
    };


    // todo: Fill in proper field/filter/etc
    ctrl.offerCanacellation_cols = [  // Yes, canacellation
        {
            field: "CancellationDate",
            title: "Cancellation Request Sent",
            filter: {CancellationDate: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "CancellationDate"
        },
        {
            field: "theCount",
            title: "Count",
            filter: {theCount: "text"}
        }
    ];
    ctrl.offerCanacellationCB = function(row){
        console.log(row);
        $state.go('OfferCancellation', {'parameter':row['CancellationDate']});
    };


    // todo: Fill in proper field/filter/etc
    ctrl._TitleIssuesInClosing_cols = [  // Yes, canacellation
        {
            field: "DemandReceivedDate",
            title: "Issue Received Date",
            filter: {DemandReceivedDate: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "DemandReceivedDate"
        },
        {
            field: "RecordCount",
            title: "Count",
            filter: {RecordCount: "text"}
        }
    ];
    ctrl._TitleIssuesInClosingCB = function(row){
        console.log(row);
        $state.go('DrillThrough', {'report':'RPT_TitleIssuesInClosingDrillThrough','parameter':row['DemandReceivedDate']});
    };


    ctrl._ClosingDeedsToBeExecuted_cols = [
        {
            field: "ActualClosingDate",
            title: "ActualClosingDate",
            filter: {HUDReviewed: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "HUDReviewed"
        },
        {
            field: "RecordCount",
            title: "Count",
            filter: {RecordCount: "text"}
        }
    ];
    ctrl._ClosingDeedsToBeExecutedCB = function(row){
        console.log(row);
        $state.go('ClosingDeedsToBeExecuted', {'parameter':row['ActualClosingDate']});
    };


    ctrl._HUDPendingRBIReviewApproval_cols = [  // Yes, canacellation
        {
            field: "hud_received_from_title_company",
            title: "Hud Received From Title Company",
            filter: {hud_received_from_title_company: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "hud_received_from_title_company"
        },
        {
            field: "RecordCount",
            title: "Count",
            filter: {RecordCount: "text"}
        }
    ];
    ctrl._HUDPendingRBIReviewApprovalCB = function(row){
        console.log(row);
        $state.go('DrillThrough', {'report':'RPT_HUDPendingRBIReviewApproval','parameter':row['hud_received_from_title_company']});
    };


    // todo: Offer Postponement
    // todo: Title Issues in Closing
    // todo: Fill in proper field/filter/etc
    ctrl.pendingSeller_cols = [
        {
            field: "DueTime",
            title: "DueTime",
            filter: {DueTime: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "DueTime"
        },
        {
            field: "Count",
            title: "Count",
            filter: {theCount: "text"}
        }
    ];
    ctrl.pendingSellerCB = function(row){
        $state.go('DrillThrough', {'report':'RPT_PropertyIssuesDrillThrough', 'parameter':row['DueTime']});
    };

    ctrl.PrelimHudRequiringApproval_cols = [
        {
            field: "HUDReceivedDate",
            title: "HUDReceivedDate",
            filter: {HUDReceivedDate: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            momentParser: "YYYY-MM-DD",
            sortable: "HUDReceivedDate"
        },
        {
            field: "RecordCount",
            title: "Count",
            filter: {RecordCount: "text"}
        }
    ];
    ctrl.PrelimHudRequiringApprovalCB = function(row){
        $state.go('PrelimHudRequiringApproval', {'parameter':row['HUDReceivedDate']});
    };




    /*ctrl._ItemsNeededToClose_cols = [  // Yes, canacellation
        {
            field: "name",
            title: "Name",
            filter: {name: "text"},
            // getValue: 'interpolatedValue',
            // interpolateExpr: $interpolate("<span>{{ item.name}}</span>")
        },
        {
            field: "total",
            title: "Total",
            filter: {total: "text"}
        }
    ];*/
    // ctrl._ItemsNeededToCloseCB = function(row){
    //     console.log(row);
    //     if(row.name === "Pending Seller Executed Contract"){
    //         console.log("Pending Seller Executed Contract");
    //         $state.go('DrillThrough', {'report':'RPT_2ndO','parameter':'__all__'});
    //     }else if(row.name === "Pending Seller Executed Deed"){
    //         console.log("Pending Seller Executed Deed");
    //         $state.go('DrillThrough', {'report':'RPT_ClosingDeedsToBeExecuted','parameter':'__all__'});
    //     }else if(row.name === "Outstanding Liens and Demands"){
    //         console.log("Outstanding Liens and Demands");
    //         $state.go('DrillThrough', {'report':'RPT_OutstandingLiensAndDemands','parameter':'__all__'});
    //     }else if(row.name === "Title Issues In Closing"){
    //         console.log("Title Issues In Closing");
    //         $state.go('DrillThrough', {'report':'RPT_TitleIssuesInClosing','parameter':'__all__'});
    //     }else if(row.name === "HUD Pending RBI Review Approval"){
    //         console.log("HUD Pending RBI Review Approval");
    //         $state.go('DrillThrough', {'report':'RPT_HUDPendingRBIReviewApproval','parameter':'__all__'});
    //     }
    // };


    // todo: HUD Pending ADC Review/Approval
    // todo: Items Needed to Close
}

export default {
    name: 'ProgramManagersCtrl',
    fn: ProgramManagersCtrl
};

// Define Load Functions

// todo: SCTCDate Date

// todo: Closing Deeds to be Executed

// todo: Outstanding Liens and Demands

// todo: Prelim HUD Requiring Approval

//-------------------------------------
function Load_2ndA($scope, ctrl, data) {
    $scope.$watch('ctrl._2ndAData', function() {
        ctrl._2ndA_isLoaded = true;
        ctrl._2ndA_total = _.reduce(ctrl._2ndAData, (total, record) => {
            total += record.RecordCount;
            return total;
        }, 0);
    });

    ctrl._2ndAData = data;
}

//-------------------------------------
function Load_2ndS($scope, ctrl, data) {
    $scope.$watch('ctrl._2ndSData', function() {
        ctrl._2ndS_isLoaded = true;
        ctrl._2ndS_total = _.reduce(ctrl._2ndSData, (total, record) => {
            total += record["Count"];
            return total;
        }, 0);
    });

    ctrl._2ndSData = data;
}

//-------------------------------------
// function Load_SCTCDate($scope, ctrl, data) {
//     $scope.$watch('ctrl._SCTCDateData', function() {
//         ctrl._SCTCDate_isLoaded = true;
//         ctrl._SCTCDate_total = _.reduce(ctrl._SCTCDateData, (total, record) => {
//             total += record.RecordCount;
//             return total;
//         }, 0);
//     });
//
//     ctrl._SCTCDateData = data;
// }

//-------------------------------------
function Load_OfferPostponement($scope, ctrl, data) {
    $scope.$watch('ctrl._OfferPostponementData', function() {
        ctrl._OfferPostponement_isLoaded = true;
        ctrl._OfferPostponement_total = _.reduce(ctrl._OfferPostponementData, (total, record) => {
            total += record.RecordCount
            return total;
        }, 0);
    });

    ctrl._OfferPostponementData = data;
}

//-------------------------------------
function Load_OutstandingLiensAndDemands($scope, ctrl, data) {
    $scope.$watch('ctrl._OutstandingLiensAndDemands', function() {
        ctrl._OutstandingLiensAndDemands_isLoaded = true;
        ctrl._OutstandingLiensAndDemands_total = _.reduce(ctrl._OutstandingLiensAndDemandsData, (total, record) => {
            total += record.RecordCount;
            return total;
        }, 0);


        let total = Number(ctrl._OutstandingLiensAndDemands_total)

        if(isNaN(total)){
            total = 0;
        }

        let idx = _.findIndex(ctrl._ItemsNeededToCloseData, function(o) { return o['name'] == 'Outstanding Liens and Demands'; });
        ctrl._ItemsNeededToCloseData[idx]['total'] = total;

        ctrl._ItemsNeededToClose_Control.reload();
    });

    ctrl._OutstandingLiensAndDemandsData = data;
}

//-------------------------------------
function Load_TitleIssuesInClosing($scope, ctrl, data) {
    $scope.$watch('ctrl._TitleIssuesInClosing', function() {
        ctrl._TitleIssuesInClosing_isLoaded = true;
        ctrl._TitleIssuesInClosing_total = _.reduce(ctrl._TitleIssuesInClosingData, (total, record) => {
            total += record.RecordCount;
            return total;
        }, 0);

        let total = Number(ctrl._TitleIssuesInClosing_total)

        if(isNaN(total)){
            total = 0;
        }

        let idx = _.findIndex(ctrl._ItemsNeededToCloseData, function(o) { return o['name'] == 'Title Issues In Closing'; });
        ctrl._ItemsNeededToCloseData[idx]['total'] = total;

        ctrl._ItemsNeededToClose_Control.reload();
    });

    ctrl._TitleIssuesInClosingData = data;
}

//-------------------------------------
// function Load_HUDPendingRBIReviewApproval($scope, ctrl, data) {
//     $scope.$watch('ctrl._HUDPendingRBIReviewApproval', function() {
//         ctrl._HUDPendingRBIReviewApproval_isLoaded = true;
//         ctrl._HUDPendingRBIReviewApproval_total = _.reduce(ctrl._HUDPendingRBIReviewApprovalData, (total, record) => {
//             total += record.RecordCount;
//             return total;
//         }, 0);
//
//         let total = Number(ctrl._HUDPendingRBIReviewApproval_total)
//
//         if(isNaN(total)){
//             total = 0;
//         }
//
//         let idx = _.findIndex(ctrl._ItemsNeededToCloseData, function(o) { return o['name'] == 'HUD Pending RBI Review Approval'; });
//         ctrl._ItemsNeededToCloseData[idx]['total'] = total;
//
//         ctrl._ItemsNeededToClose_Control.reload();
//     });
//
//     ctrl._HUDPendingRBIReviewApprovalData = data;
// }

//-------------------------------------
function Load_ClosingDeedsToBeExecuted($scope, ctrl, data) {
    $scope.$watch('ctrl._ClosingDeedsToBeExecuted', function() {
        ctrl._ClosingDeedsToBeExecuted_isLoaded = true;
        ctrl._ClosingDeedsToBeExecuted_total = _.reduce(ctrl._ClosingDeedsToBeExecutedData, (total, record) => {
            total += record.RecordCount;
            return total;
        }, 0);

        let total = Number(ctrl._ClosingDeedsToBeExecuted_total)

        if(isNaN(total)){
            total = 0;
        }

        let idx = _.findIndex(ctrl._ItemsNeededToCloseData, function(o) { return o['name'] == 'Pending Seller Executed Deed'; });
        ctrl._ItemsNeededToCloseData[idx]['total'] = total;
    });

    ctrl._ClosingDeedsToBeExecutedData = data;
}

//-------------------------------------
// Yes, canacellation
function Load_OfferCanacellation($scope, ctrl, data) {
    $scope.$watch('ctrl.offerCanacellationData', function() {
        ctrl._OfferCanacellation_isLoaded = true;
        ctrl.offerCanacellation_total = _.reduce(ctrl.offerCanacellationData, (total, record) => {
            total += record.theCount;
            return total;
        }, 0);
    });

    ctrl.offerCanacellationData = data;
}

function Load_PrelimHudRequiringApproval($scope, ctrl, data) {
    $scope.$watch('ctrl.PrelimHudRequiringApproval_Data', function() {
        ctrl._PrelimHudRequiringApproval_isLoaded = true;
        ctrl.PrelimHudRequiringApproval_total = _.reduce(ctrl.PrelimHudRequiringApproval_Data, (total, record) => {
            total += record.RecordCount;
            return total;
        }, 0);
    });

    ctrl.PrelimHudRequiringApproval_Data = data;
}

// todo: Offer Postponement

// todo: Title Issues in Closing

//-------------------------------------
function Load_PendingSeller($scope, ctrl, data) {
    $scope.$watch('ctrl.pendingSellerData', function() {
        ctrl.pendingSeller_isLoaded = true;
        ctrl.pendingSeller_total = _.reduce(ctrl.pendingSellerData, (total, record) => {
            total += record.Count;
            return total;
        }, 0);
    });

/*
    let dout = [];

    angular.forEach(data,(row) =>{
        row['ActualClosingDate'] = row['ActualClosingDate'].split('/').join('-');
        dout.push(row);
    });
*/

    ctrl.pendingSellerData = data;
}

// todo: HUD Pending ADC Review/Approval

// todo: Items Needed to Close
