function PropertyDocumentsCtrl($scope, $interpolate, $resource, $state, $stateParams, $previousState) {
    'ngInject';

    const ctrl = this;
    const uri = '/clientportal/api/';

    let res = $resource(uri + 'ATDocumentsView/?AuctionID=:id&nopage=1&format=json', {id:'@id'});

    ctrl.doc_cols = [
        {
            field: "DocumentType",
            title: "Type",
            filter: {DocumentType: "text"},
            sortable: "DocumentType"
        },
        {
            field: "Text",
            title: "Title",
            filter: {Text: "text"},
            getValue: 'interpolatedValue',
            interpolateExpr: $interpolate("<span>{{ item.Text | limitTo : 60}}<span ng-if='item.Text > 60'>...</span></a>"),
            sortable: "Text"
        },
        {
            field: "ChangeDate",
            title: "Last Modified",
            filter: {ChangeDate: "text"},
            getValue: 'momentValue',
            momentFormat: "MM-DD-YYYY",
            sortable: "ChangeDate"
        },
        {
            field: "ChangeUser",
            title: "Created By",
            filter: {ChangeUser: "text"},
            sortable: "ChangeUser"
        }
    ];

    ctrl.back = function(){
        $previousState.go();
    };

    ctrl.init = function(){
        res.query({'id':ctrl.id}).$promise.then(data => {
            ctrl.data = data;
            ctrl.data_loaded = true;
        });
    };

    if(!('id' in $stateParams) || !$stateParams.id){
        $state.go('ManageProperties');
    }else{
        ctrl.id = $stateParams.id;
        ctrl.init();
    }
}

export default {
    name: 'PropertyDocumentsCtrl',
    fn: PropertyDocumentsCtrl
};
